import React, { useState, useEffect, useCallback, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import MapGL, { FlyToInterpolator, Marker } from 'react-map-gl'

import scss from './Map.scss'
import {
  setViewPort,
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  setOverPanel,
  setMapRef,
} from '../../actions/index'
import { signRequest } from '../../utilities/api'

import Print from '../Tools/Print/Print'
import Tools from '../Tools/Tools'

import LoadingDiv from '../../components/LoadingDiv/LoadingDiv'
import MapPopup from '../MapPopup/MapPopup'

import { getAllLayersFromDataConfig } from '../../utilities/dataConfig'

import './map.module.css'
import ClickedFeature from './ClickedFeature'
import LabelFeatures from './LabelFeatures'

import MapToolTip from './MapToolTip'
import Viewport from './Viewport/Viewport'
import InitMapStyle from './MapStyle/InitMapStyle'
import RightSideControls from './MapControls/RightSideControls'
import BottomControls from './MapControls/BottomControls'
import {
  updateCurrentLocation,
  terminateTracking,
} from '../../actions/actions_geolocate'

const Map = ({ dataConfig, history, location }) => {
  const dispatch = useDispatch()
  const mapToolTip = useSelector(state => state.mapToolTip)
  const viewport = useSelector(state => state.viewport)
  const mapStyle = useSelector(state => state.mapStyle)
  const doubleClickZoom = useSelector(state => state.doubleClickZoom)
  const dragPan = useSelector(state => state.setDragPan)
  const scrollZoom = useSelector(state => state.setScrollZoom)
  const overPanel = useSelector(state => state.setOverPanel)
  const mapCursor = useSelector(state => state.mapCursor)
  const mapMode = useSelector(state => state.mapMode)
  const drawMode = useSelector(state => state.drawMode)
  const disablePopup = useSelector(state => state.disablePopup)
  const onTransitionEnd = useSelector(state => state.onTransitionEnd)
  const isTrackingLocation = useSelector(
    state => state.geolocation.isTrackingLocation
  )
  const currentLocation = useSelector(
    state => state.geolocation.currentLocation
  )

  const user = useSelector(state => state.user)
  const selectFeatures = useSelector(state => state.selectFeatures)
  const mapRef = useSelector(state => state.setMapRef)

  const [mapLoaded, setMapLoaded] = useState(false)

  const [selectedFeatures, setSelectedFeatures] = useState([])
  const [popupInfo, setPopupInfo] = useState(null)
  const [clickedFeature, setClickedFeature] = useState(null)
  const [componentOnTop, setComponentOnTop] = useState(true)

  const buildPopupInfo = (features, lngLat) => {
    if (disablePopup) return null
    if (features.length) {
      if (features[0].properties.mamid) {
        return {
          features,
          lngLat,
          key: features[0].layer.id + features[0].properties.mamid,
        }
      }
      return {
        features,
        lngLat,
        key: features[0].layer.id,
      }
    }
    return null
  }

  const onMapClickNative = event => {
    if (overPanel) return
    if (drawMode === 'EDIT') return
    if (drawMode === 'NEW') return
    if (drawMode === 'DELETE') return
    if (event.features.length) {
      if (selectFeatures) {
        setSelectedFeatures(event.features)
      } else {
        const newPopupInfo = buildPopupInfo(event.features, event.lngLat)
        if (newPopupInfo) setPopupInfo(newPopupInfo)
        setClickedFeature(event.features[0])
      }
    }
  }

  const clearClickedFeature = () => setClickedFeature(null)

  const transformRequest = request => {
    // Only sign the request without cache busting
    return signRequest(request)
  }

  const disableMap = useCallback(() => {
    dispatch(setOverPanel(true))
    dispatch(setScrollZoom(false))
    dispatch(setDragPan(false))
    dispatch(setDoubleClickZoom(false))
  }, [])

  const enableMap = useCallback(() => {
    dispatch(setOverPanel(false))
    dispatch(setScrollZoom(true))
    dispatch(setDragPan(true))
    dispatch(setDoubleClickZoom(true))
  }, [])

  const getCursor = ({ isHovering, isDragging }) => {
    if (isDragging) {
      return 'grabbing'
    }
    if (mapCursor === 'wait') {
      return mapCursor
    }
    if (mapCursor === 'crosshair') {
      return mapCursor
    }
    return isHovering ? 'pointer' : mapCursor
  }

  const onWheel = useCallback(
    event => {
      if (overPanel || !scrollZoom) return

      // Use native scrollZoom configuration instead of custom handling
      dispatch(
        setViewPort({
          ...viewport,
          zoom: viewport.zoom + event.delta * 0.01,
          transitionDuration: 0, // Remove transition for immediate response
        })
      )
    },
    [viewport, overPanel, scrollZoom, dispatch]
  )

  const closePopup = () => setPopupInfo(null)

  const getInteractiveIds = dConfig => {
    const layers = getAllLayersFromDataConfig(dConfig.tocLayers)
    const auxLayers = getAllLayersFromDataConfig(dConfig.auxiliaryLayers)
    const interactiveLayers = layers
      .filter(layer => layer.interactive)
      .map(layer => layer.id)
    const interactivceAuxLayers = auxLayers
      .filter(layer => layer.interactive)
      .map(layer => layer.id)
    return [...interactiveLayers, ...interactivceAuxLayers]
  }

  useEffect(() => {
    dispatch(setScrollZoom(true))
  }, [])

  useEffect(() => {
    if (!isTrackingLocation) return

    let watchId = null
    if (isTrackingLocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const { latitude, longitude } = position.coords
        const newViewport = {
          ...viewport,
          latitude,
          longitude,
          zoom: 15,
          transitionDuration: 500,
          transitionInterpolator: new FlyToInterpolator(),
        }
        dispatch(setViewPort(newViewport))
      })

      watchId = navigator.geolocation.watchPosition(position => {
        const { latitude, longitude } = position.coords
        dispatch(updateCurrentLocation({ latitude, longitude }))
      })
    }

    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId)
        dispatch(terminateTracking())
      }
    }
  }, [isTrackingLocation])

  useEffect(() => {
    dispatch(setScrollZoom(true))
  }, [dispatch]) // Add dispatch as dependency

  // Memoize the onViewportChange callback
  const onViewportChange = useCallback(
    viewport => {
      if (viewport.width === 0) return
      dispatch(setViewPort(viewport))
    },
    [dispatch]
  )

  // Memoize the setMapLoaded callback
  const handleMapLoaded = useCallback(() => {
    setMapLoaded(true)
  }, [])

  let interactiveLayerIds = []
  if (dataConfig) {
    interactiveLayerIds = getInteractiveIds(dataConfig)
  } else {
    return <LoadingDiv />
  }

  return (
    <>
      {mapToolTip.show && <MapToolTip tipHtml={mapToolTip.tipHtml} />}
      {mapMode === 'MAP' && (
        <>
          <Viewport history={history} />
          <InitMapStyle setMapLoaded={handleMapLoaded} location={location} />
          <div id='mapContainer' className={scss.mapContainer}>
            <div className={scss.mainMap}>
              {mapMode === 'MAP' && mapLoaded && viewport.latitude && (
                <MapGL
                  key={'map_' + user.accountID + '_' + user.mapID}
                  {...viewport}
                  mapStyle={mapStyle}
                  onViewportChange={onViewportChange}
                  onNativeClick={onMapClickNative}
                  dragPan={dragPan}
                  doubleClickZoom={doubleClickZoom}
                  scrollZoom={{
                    speed: 0.01,
                    smooth: false,
                  }}
                  ref={map => dispatch(setMapRef(map))}
                  transformRequest={transformRequest}
                  interactiveLayerIds={interactiveLayerIds}
                  attributionControl={false}
                  getCursor={getCursor}
                  keyboard={false}
                  onWheel={onWheel}
                >
                  <RightSideControls
                    onViewportChange={onViewportChange}
                    setClickedFeature={setClickedFeature}
                    closePopup={closePopup}
                    selectedFeatures={selectedFeatures}
                    setSelectedFeatures={setSelectedFeatures}
                  />
                  <BottomControls />
                  <div />
                  {popupInfo && (
                    <MapPopup
                      key={popupInfo.key}
                      mapStyle={mapStyle}
                      dataConfig={dataConfig}
                      popupInfo={popupInfo}
                      viewport={viewport}
                      setClickedFeature={setClickedFeature}
                      closePopup={closePopup}
                      disableMap={disableMap}
                      enableMap={enableMap}
                      componentOnTop={componentOnTop}
                      setComponentOnTop={setComponentOnTop}
                    />
                  )}
                  {clickedFeature && (
                    <ClickedFeature
                      key={JSON.stringify(clickedFeature.properties)}
                      feature={clickedFeature}
                      clearClickedFeature={clearClickedFeature}
                    />
                  )}
                  {mapLoaded && <LabelFeatures />}
                  <Tools
                    dataConfig={dataConfig}
                    disableMap={disableMap}
                    enableMap={enableMap}
                    componentOnTop={componentOnTop}
                    setComponentOnTop={setComponentOnTop}
                    popupInfo={popupInfo}
                  />
                  {isTrackingLocation &&
                    currentLocation?.latitude &&
                    currentLocation?.longitude && (
                      <Marker
                        latitude={currentLocation.latitude}
                        longitude={currentLocation.longitude}
                      >
                        <div
                          style={{
                            backgroundColor: 'blue',
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            border: '2px solid white',
                          }}
                        ></div>
                      </Marker>
                    )}
                </MapGL>
              )}
            </div>
          </div>
        </>
      )}
      {mapMode === 'PRINT' && (
        <div id='mapContainer' className={scss.mapContainer}>
          <Print
            viewport={viewport}
            mapStyle={mapStyle}
            dataConfig={dataConfig}
            onViewportChange={onViewportChange}
            onNativeClick={onMapClickNative}
            dragPan={dragPan}
            doubleClickZoom={doubleClickZoom}
            scrollZoom={scrollZoom}
            map={mapRef}
            transformRequest={transformRequest}
            onTransitionEnd={onTransitionEnd}
            interactiveLayerIds={interactiveLayerIds}
            attributionControl={false}
            keyboard={false}
            getCursor={getCursor}
          />
        </div>
      )}
    </>
  )
}

export default Map
