import React, {useState,useEffect} from "react"
import { useSelector } from 'react-redux'
import TargetLayerSelect from './TargetLayerSelect/TargetLayerSelect'
import GeometryIndicator from "./GeometryIndicator/GeometryIndicator"
import DrawMode from "./DrawMode/DrawMode"
import Icon from "../../../components/Icon/Icon"
import scss from "./Draw.scss"


const Draw = React.memo(({mapRef}) => {
    
    const [targetLayer, setTargetLayer] = useState(null)
    const [saveCount, setSaveCount] = useState(1)

    return (
        <div className={scss.container}>
            <div className={scss.wrapper}> 
                <div className={scss.icon}>
                    <Icon icon='pen-nib' />
                </div>
            </div>
            
                <TargetLayerSelect 
                    setTargetLayer={setTargetLayer} 
                    targetLayer={targetLayer}
                    saveCount={saveCount}
                />
                <GeometryIndicator 
                    geometry={targetLayer ? targetLayer.type : null} 
                />
                <div className={scss.flexGrid}>
                <DrawMode 
                    targetLayer={targetLayer} 
                    geometry={targetLayer ? targetLayer.type : null} 
                    mapRef={mapRef}
                    saveCount={saveCount}
                    setSaveCount={setSaveCount}
                />
                </div> 
        </div>
    )
})

export default Draw