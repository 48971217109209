/* eslint-disable react/jsx-key */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect, useMemo } from 'react'
import Icon from '../../../../../components/Icon/Icon'
import * as DocumentBrowserUtil from '../../../../DocumentBrowserV2/DocumentBrowserV2Util'
import scss from './DocumentGroupTable.scss'
import DocumentRow from '../DocumentRow/DocumentRow'

const DocumentGroupTable = ({
  headerGroups,
  getTableProps,
  getTableBodyProps,
  getIconBasedOnSortType,
  handleHeaderClick,
  useBigTimeGrouping,
  prepareRow,
  rows,
  setDisplayEditDocumentTab,
  setTargetProperty,
  setDisplayDeleteDocumentPopup,
  setDisplayManageDocuments,
}) => {
  const [expandFeatureDocuments, setExpandFeatureDocuments] = useState(true)
  const [expandLayerDocuments, setExpandLayerDocuments] = useState(true)

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()} colSpan='3'>
            {headerGroup.headers.map(column => (
              // Add the sorting props to control sorting. For this example
              // we can add them into the header props
              <th
                key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                onClick={() => {
                  handleHeaderClick(column.id)
                }}
                style={{ cursor: 'pointer' }}
              >
                {column.render('Header')}
                {/* Add a sort direction indicator */}
                <span>
                  {getIconBasedOnSortType(column, useBigTimeGrouping)}
                </span>
              </th>
            ))}
            <th> </th>
          </tr>
        ))}

        <tr
          style={{
            borderBottom: '0.5px solid #858f98',
            borderTop: '0.5px solid #858f98',
          }}
          onClick={() => {
            setExpandFeatureDocuments(!expandFeatureDocuments)
          }}
          className={scss.subComponent}
        >
          <th
            key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
            className={scss.subComponent}
            colSpan='4'
          >
            Feature Level Document Attributes{' '}
            {expandFeatureDocuments ? (
              <Icon icon='angle-down' />
            ) : (
              <Icon icon='angle-left' />
            )}
          </th>
        </tr>
        {expandFeatureDocuments
          ? rows.map((row, i) => {
              if (!['Feature'].includes(row.original.type))
                return null
              prepareRow(row)
              return (
                <DocumentRow
                  key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                  row={row}
                  setDisplayEditDocumentTab={setDisplayEditDocumentTab}
                  setTargetProperty={setTargetProperty}
                  setDisplayDeleteDocumentPopup={setDisplayDeleteDocumentPopup}
                  setDisplayManageDocuments={setDisplayManageDocuments}
                />
              )
            })
          : null}
        <tr
          style={{ borderBottom: '0.5px solid #858f98' }}
          onClick={() => {
            setExpandLayerDocuments(!expandLayerDocuments)
          }}
          className={scss.subComponent}
        >
          <th
            key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
            className={scss.subComponent}
            colSpan='4'
          >
            Layer Level Document Attributes {' '}
            {expandLayerDocuments ? (
              <Icon icon='angle-down' />
            ) : (
              <Icon icon='angle-left' />
            )}{' '}
          </th>
        </tr>
        {expandLayerDocuments
          ? rows.map((row, i) => {
              if (!['Layer'].includes(row.original.type)) return null
              prepareRow(row)
              return (
                <DocumentRow
                  key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                  row={row}
                  setDisplayEditDocumentTab={setDisplayEditDocumentTab}
                  setTargetProperty={setTargetProperty}
                  setDisplayDeleteDocumentPopup={setDisplayDeleteDocumentPopup}
                  setDisplayManageDocuments={setDisplayManageDocuments}
                />
              )
            })
          : null}
      </thead>
      <tbody {...getTableBodyProps()}></tbody>
    </table>
  )
}

export default DocumentGroupTable
