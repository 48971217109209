import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Storage } from 'aws-amplify'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input'

// components
import DocumentPropertyValue from './DocumentPropertyValue'
import ProfileDate, {
  processDateFormat,
  profileDateFormat,
} from '../Profile/ProfileDate'

// utilities
import * as utils from '../../utilities/util'

// scss files
import scss from './MapPopup.scss'

const getFile = (path, file) => {
  Storage.get(`${path}${file}`, { expires: 60 })
    .then(result => {
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.download = file
      a.href = result
      a.target = '_blank'
      a.click()
      document.body.removeChild(a)
    })
    .catch(err => console.log(err))
}

const handleDocumentClick = (path, file) => {
  if (!file) return
  if (file.trim() === '') return

  getFile(path, file)
}

export const getAttribute = ({
  lowerCaseObj,
  property,
  propertyConfigName,
  featureProperties,
  edit,
  popupInfo,
  index,
  layerProperties,
  handleInputChange,
  handleSelectChange,
  handleDateChange,
  handleCurrencyInputChange,
  user,
}) => {
  if (['document (Feature)', 'document (Layer)'].includes(property.type))
    return null

  const { value, valueDisplay, isLink } = processValue({
    lowerCaseObj,
    property,
    featureProperties,
  })
  let dateDisplay
  if (property.type === 'date')
    dateDisplay = <ProfileDate date={valueDisplay} />

  return (
    <div
      key={property.key}
      className={[
        scss['attribute-grid-row'],
        scss[property.type === 'longtext' ? 'attribute-grid-row-textarea' : ''],
      ].join(' ')}
    >
      <div className={scss['attribute-grid-row-label']}>
        {propertyConfigName ? propertyConfigName : property.name}
      </div>
      <div className={scss['attribute-grid-row-value']}>
        {edit ? (
          getEditableElement({
            key: property.key,
            type: property.type,
            currentValue: value,
            currentValueDisplay: valueDisplay,
            popupInfo,
            index,
            layerProperties,
            handleInputChange,
            handleSelectChange,
            handleDateChange,
            handleCurrencyInputChange,
            user,
          })
        ) : isLink ? (
          <a
            className={scss['property-link']}
            target='_blank'
            href={valueDisplay}
          >
            {valueDisplay}
          </a>
        ) : (
          <span title={valueDisplay}>
            {dateDisplay ? dateDisplay : valueDisplay}
          </span>
        )}
      </div>
    </div>
  )
}

export const processDefaultValue = (defaultValue, propertyType) => {
  let defaultValueJSX = <span>{defaultValue}</span>

  if (!propertyType) return defaultValueJSX

  if (defaultValue && defaultValue !== '') {
    switch (propertyType) {
      case 'date':
        defaultValueJSX = <ProfileDate date={defaultValue} />
        break
      case 'currency':
        defaultValueJSX = '$' + utils.numberFormat(defaultValue, 2)
        break
    }
  }

  return defaultValueJSX
}

export const getDocument = ({
  lowerCaseObj,
  property,
  propertyConfigName,
  featureProperties,
  setDocumentInfo,
  handleDocumentChange,
  canEdit,
}) => {
  if (!['document (Feature)'].includes(property.type)) return null

  const { value, documentPath } = processValue({
    lowerCaseObj,
    property,
    featureProperties,
  })

  return (
    <div
      key={property.key}
      className={scss['document-grid-row']}
      style={{ display: 'flex' }}
    >
      <div
        className={scss['document-grid-row-label']}
        style={{ cursor: 'auto', width: 'auto' }}
      >
        {propertyConfigName ? propertyConfigName : property.name}
      </div>
      {property.type === 'document (Feature)' &&
      property &&
      property.value &&
      property.value[0] ? (
        property.value[0]
      ) : (
        <span>(Layer)</span>
      )}
      <div
        className={scss['document-grid-row-value']}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '25%' }}
      >
        <DocumentPropertyValue
          path={documentPath}
          file={value}
          setDocumentInfo={setDocumentInfo}
          handleDocumentChange={handleDocumentChange}
          documentKey={property.key}
          canEdit={canEdit}
        />
      </div>
    </div>
  )
}

export const getEditableElement = ({
  key,
  type,
  currentValue,
  currentValueDisplay,
  popupInfo,
  index,
  layerProperties,
  handleInputChange,
  handleSelectChange,
  handleDateChange,
  handleCurrencyInputChange,
  user,
}) => {
  const layerId = popupInfo.features[index].layer.id
  const property = layerProperties
    ? layerProperties[layerId].filter(property => property.key === key)
    : null

  if (type === 'text')
    return (
      <input
        name={key}
        onChange={handleInputChange}
        placeholder={currentValueDisplay ? currentValueDisplay : ''}
        value={currentValue ? currentValue : ''}
      />
    )
  if (type === 'longtext')
    return (
      <textarea
        name={key}
        className={scss['attribute-textarea']}
        onChange={handleInputChange}
        placeholder={currentValueDisplay ? currentValueDisplay : ''}
        rows={5}
        value={currentValue ? currentValue : ''}
      />
    )
  if (type === 'number')
    return (
      <input
        name={key}
        onChange={handleInputChange}
        placeholder={currentValueDisplay ? currentValueDisplay : ''}
        value={currentValue ? currentValue : ''}
        type='number'
      ></input>
    )
  if (type === 'dropdown') {
    const optionList = property[0].value ? property[0].value : []

    return (
      <select
        value={currentValue || ''}
        style={{ border: '1px solid rgba(98, 105, 113, 0.5)' }}
        onChange={e => handleSelectChange(e, key)}
      >
        <option value={null}>&nbsp;</option>
        {optionList.map(value => (
          <option key={'attDropdown_' + value} value={value}>
            {value}
          </option>
        ))}
      </select>
    )
  }

  if (type === 'date') {
    let displayDate = ''
    if (currentValue && currentValue !== '') {
      let testDate = new Date(currentValue)
      if (testDate instanceof Date && !isNaN(testDate)) displayDate = testDate //If invalid date, keep it as null
    }
    const datePickerFormat = processDateFormat(user.profile.dateFormat)
    const datePickerPlaceholder = profileDateFormat({
      date: currentValueDisplay,
      dateFormat: datePickerFormat,
    })

    return (
      <DatePicker
        name={key}
        selected={displayDate}
        placeholderText={datePickerPlaceholder || ''}
        onChange={date => handleDateChange(date, key)}
        dateFormat={datePickerFormat}
        isClearable
      />
    )
  }

  if (type === 'currency') {
    return (
      <CurrencyInput
        prefix='$'
        name={key}
        placeholder={currentValueDisplay ? currentValueDisplay : ''}
        value={currentValue ? currentValue : null}
        onChangeEvent={handleCurrencyInputChange}
      />
    )
  }
}

export const processDate = savedDate => {
  if (!savedDate) return null

  let savedDateArr = savedDate.split('T')
  if (savedDateArr.length === 1) savedDateArr = savedDate.split(' ')

  let date = savedDateArr[0].split('-')
  return `${date[1]}/${date[2]}/${date[0]}`
}

export const processValue = ({ lowerCaseObj, property, featureProperties }) => {
  let value = null
  let valueDisplay = ''
  let documentPath = ''
  let isLink = false

  const urlRegex =
    /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g

  if (lowerCaseObj[property.key.toLowerCase()]) {
    //Value Exists in the properties
    value = featureProperties[property.key.toLowerCase()]
    if (
      property.type === 'date' &&
      featureProperties[property.key.toLowerCase()] !== ''
    ) {
      value = processDate(value)
    } else if (property.type === 'document') {
      const documentStr = lowerCaseObj[property.key.toLowerCase()]
      if (documentStr) {
        const documentArray = documentStr.split('|')
        documentPath = documentArray[0]
        value = documentArray[1]
      }
    } else if (property.type === 'currency') {
      // currency values
      value = '$' + utils.numberFormat(value, 2)
    } else if (property.type === 'text') {
      // text values with links
      if (urlRegex.test(value)) isLink = true
    }

    valueDisplay = value
  } else {
    valueDisplay = property.default
    if (property.type === 'date' && valueDisplay !== '') {
      valueDisplay = processDate(valueDisplay)
    } else if (property.type === 'currency') {
      // currency values
      if (valueDisplay !== null)
        valueDisplay = '$' + utils.numberFormat(valueDisplay, 2)
    } else if (property.type === 'text') {
      // text values with links
      if (urlRegex.test(valueDisplay)) isLink = true
    }
  }

  return { value, valueDisplay, documentPath, isLink }
}
