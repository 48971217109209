/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'
import Icon from '../../components/Icon/Icon'
import AWS from 'aws-sdk'
import scss from './DocumentPropertyValue.scss'
import authConfig from '../../config/authConfig'

function DocumentPropertyValue({
  path,
  file,
  setDocumentInfo,
  handleDocumentChange,
  documentKey,
  canEdit,
}) {
  const isPreview = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(file)
  const [documentFullPath, setDocumentFullPath] = useState('')
  const [currentPath, setCurrentPath] = useState(null)
  const [myFileURL, setMyFileURL] = useState(file)
  const handleDocumentChangeV2 = async (documentKey, filePath, fileName) => {
    if (!documentKey) return

    let newValue
    if (filePath && fileName) {
      filePath = filePath.substr(0, filePath.length - fileName.length)

      newValue = `${filePath}${fileName}`
    } else newValue = ''

    const s3 = new AWS.S3()

    const params = {
      Bucket: authConfig.s3Config.bucketName, // your bucket name,
      Key: 'public/' + newValue, // path to the object you're looking for
    }

    const url = await new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', params, (err, url) => {
        err ? reject(err) : resolve(url)
      })
    })
    return url
  }

  const previewImage = (path, file) => {
    Storage.get(`${path}${file}`, { expires: 60 })
      .then(result => {
        setDocumentFullPath(result)
      })
      .catch(err => console.log(err))
  }

  useEffect(() => {
    previewImage(path, file)
  }, [])

  const imgStyle = {
    height: '16px',
    float: 'right',
  }

  const openDocuments = () => {
    setDocumentInfo(documentKey)
  }

  const clearDocument = () => {
    handleDocumentChange(documentKey, '', '')
  }

  const getPathFromRelative = (relativePath, sidebarFolder = null) => {
    if (!currentPath) return

    let returnPath = currentPath

    if (!sidebarFolder) sidebarFolder = currentFolder
    if (sidebarFolder) returnPath += sidebarFolder + '/'

    returnPath += relativePath

    return returnPath
  }

  useEffect(() => {
    async function fetchMyAPI() {
      if (file) {
        file = file.replace('|', '')
        file = file.replace('|', '')

        const urlPromise = await handleDocumentChangeV2(
          'document (Feature)',
          file,
          file
        )
        setMyFileURL(urlPromise)
      }
    }
    fetchMyAPI()
  }, [file])

  return (
    <>
      {/* {isPreview ? (
        <>
          <span className={scss.documentPropertyLink}>{file}</span>
          <img className={scss.documentPropertyIcon} src={documentFullPath} style={imgStyle} />
        </>
      ) : ( */}
      <>
        {file ? (
          <>
            <a href={myFileURL} style={{ color: 'white' }} target='_blank'>
              {fileName}
            </a>
            {canEdit && (
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  clearDocument()
                }}
                className={scss.documentPropertyIcon}
                icon='times'
              />
            )}
          </>
        ) : (
          <>
            <span
              className={scss.documentPropertyNoLink}
              style={{ cursor: 'auto' }}
            ></span>
            {canEdit && (
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  openDocuments()
                }}
                className={scss.documentPropertyIcon}
                icon='unlink'
              />
            )}
          </>
        )}
      </>
      {/* )} */}
    </>
  )
}

export default DocumentPropertyValue
