import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as utils from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'
import { useTranslation } from 'react-i18next'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'
import { MuiButton } from '../../../components/MaterialUi/Dialog'

import scss from '../Users.scss'
import checkboxScss from '../../CSSModules/Checkbox.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import Icon from '../../../components/Icon/Icon'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ doUpdate, accounts, checkboxList, visible }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [userIDState, setUserIDs] = useState([])
  const [checkedAccounts, setCheckedAccounts] = useState([])
  const [userCheckedAccounts, setUserCheckedAccounts] = useState([])
  const [userRoleState, setUserRole] = useState('full')
  const [showRoleDropdown, setRoleDropdown] = useState(false)

  //Role Fetching
  const [roleLoading, setRoleLoading] = useState(false)
  const [roleFetchObjects, setRoleFetchObjects] = useState(null)
  const [roles, setRoles] = useState([])

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/user/add'
    let body = {
      userID: userIDState,
      accountID: userCheckedAccounts,
      role: userRoleState,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = () => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    doUpdate()
  }

  //ROLE FETCH
  const updateRoles = () => {
    const method = 'GET'
    let url = apis.apiDatabase.uri + 'roles'
    let body = {}

    setRoleFetchObjects([{ url, method, body }])
    setRoleLoading(true)
  }

  const roleFetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        setRoles(utils.resultReturn(result))

        setRoleLoading(false)
      }

      setRoleFetchObjects(null)
    })
  }

  const getRoleName = () => {
    let role = roles.find(role => role.slug === userRoleState)
    return role ? role.name : '-'
  }

  const handleSave = () => {
    buildFetchParams()
  }

  // ===================================
  // = PROCESS THE DATA INTO VARIABLES =
  // ===================================
  const processUserIDs = () => {
    var userIDs = []
    for (const key in checkboxList) {
      var check = checkboxList[key]

      var userID = check.user.id

      userIDs = utils.addVal(userIDs, userID)
    }
    setUserIDs(userIDs)

    //processAccounts
    processAccounts(userIDs)
  }

  const processAccounts = checkedUserIDs => {
    var accountIDsByUser = {}

    for (const key in accounts) {
      var account = accounts[key]
      var users = account.users

      users.forEach(function (user) {
        if (checkedUserIDs.includes(user.id)) {
          if (accountIDsByUser[user.id] == null) accountIDsByUser[user.id] = []
          accountIDsByUser[user.id] = utils.addVal(
            accountIDsByUser[user.id],
            account.id
          )
        }
      })
    }
    accountIDsByUser = Object.values(accountIDsByUser)
    // console.log(accountIDsByUser)
    if (Array.isArray(accountIDsByUser)) {
      var cAccounts = accountIDsByUser.shift().filter(function (v) {
        return accountIDsByUser.every(function (a) {
          return a.indexOf(v) !== -1
        })
      })
      // console.log(cAccounts);
      setCheckedAccounts(cAccounts)
    }
  }

  useEffect(() => {
    processUserIDs()
    updateRoles()
  }, [])

  const handleCheckboxClick = accountID => {
    setUserCheckedAccounts(utils.toggleVal(userCheckedAccounts, accountID))
  }

  const handleRole = role => {
    setUserRole(role)
    setRoleDropdown(false)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  // const getUser = (u) => {
  const getAccount = account => {
    // var checked = userList.includes(u.id)

    if (checkedAccounts.includes(account.id)) return null

    return (
      <div
        key={'account_' + account.id}
        className={modalScss.selectMultipleRow}
      >
        <div>
          <div>
            {/* <input
              type="checkbox"
              // defaultChecked={checked}
              // value={u.id}
              // onChange={handleCheck}
            /> */}

            {/* ALT CUSTOM CHECKBOX BELOW */}
            <label
              className={[
                checkboxScss.checkboxContainer,
                checkboxScss.altCheckboxContainer,
              ].join(' ')}
            >
              <input
                className={checkboxScss.checkbox}
                type='checkbox'
                checked={userCheckedAccounts.includes(account.id)}
                onClick={() => handleCheckboxClick(account.id)}
              />
              <span
                className={[
                  checkboxScss.checkboxMark,
                  checkboxScss.altCheckboxMark,
                ].join(' ')}
              ></span>
            </label>
          </div>
          <div className={modalScss.selectRowContainer}>
            <span className={modalScss.selectRowSm}>{account.name}</span>
          </div>
        </div>
        <div>
          <span className={modalScss.selectRowTitle}>
            {account.users.length || '-'} / {account.userCount || '-'} Users
          </span>
        </div>
      </div>
    )
  }

  const accountsArea = !accounts.length ? (
    <p>No users found for this account</p>
  ) : (
    accounts.map(a => getAccount(a))
  )

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {roleFetchObjects && (
        <AsyncFetch
          fetchObjects={roleFetchObjects}
          fetchFinished={roleFetchFinished}
        />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='assign-account-dialog'>
          {t('profile.account.assignAccounts')}
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent
          className={[modalScss.MuiDialogBoxes, appScss.flexColumn].join(' ')}
        >
          {/* <input style={{maxWidth: "236px", margin: "16px 0", height: "36px", width: "50%"}} className={appScss.altInput} type="search" placeholder="Filter Search" /> */}

          <div className={modalScss.selectMultipleBox}>{accountsArea}</div>

          {roles && (
            <>
              <span className={scss.selectRoleLabel}>
                {t('profile.account.selectRole')}
              </span>
              <button
                type='button'
                className={scss.selectRoleDropdown}
                onClick={() => setRoleDropdown(!showRoleDropdown)}
              >
                {getRoleName()}
                <Icon
                  icon={showRoleDropdown ? 'chevron-up' : 'chevron-down'}
                  size='1x'
                  pull='right'
                />
              </button>
              {showRoleDropdown && (
                <>
                  <div
                    className={scss.selectRoleDropdownCover}
                    onClick={() => setRoleDropdown(!showRoleDropdown)}
                  />
                  <div
                    className={[
                      scss.dropdownContent,
                      scss.selectRoleDropdownContent,
                      scss.assignRoleDropdown,
                    ].join(' ')}
                  >
                    {roles.map(role => (
                      <div
                        key={'role_' + role.id}
                        className={scss.dropdownItem}
                        onClick={() => handleRole(role.slug)}
                      >
                        <div className={scss.dropdownText}>
                          <h4>{role.name}</h4>
                          <p>{role.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </DialogContent>

        <DialogActions>
          <button
            type='button'
            className={modalScss.modalAltButton}
            onClick={handleOnClose}
          >
            {t('profile.account.cancel')}
          </button>

          <button
            type='button'
            className={modalScss.modalMainButton}
            // disabled={!enableSave}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : (
              t('profile.account.save')
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function AssignAccountModal(props) {
  return <SimpleDialog {...props} />
}
