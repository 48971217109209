import React from 'react'
import Icon from '@/components/Icon/Icon'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
  mapControlWrapper,
} from '@/styles/tailwind-utilities'

const FeedbackButton = ({ toggleFeedbackVisible, feedbackVisible }) => {
  return (
    <div className={mapControlWrapper}>
      <button
        onClick={toggleFeedbackVisible}
        className={`${buttonBase} ${mapControlButton} ${
          feedbackVisible
            ? mapControlButtonColors.active
            : mapControlButtonColors.default
        }`}
      >
        <Icon icon='question-circle' size='xl' />
      </button>
    </div>
  )
}

export default FeedbackButton
