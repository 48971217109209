import React, { useState, useEffect } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'
import Icon from '../../../components/Icon/Icon'
import { MuiFormControlText } from '../../../components/MaterialUi/Tool'

import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ mapViews, onSave, onClose }) {
  // * State
  const [name, setName] = useState('')
  const [fetching, setFetching] = useState(false)
  const [open, setOpen] = useState(true)
  const [selectedGroup, setSelectedGroup] = useState('')
  const [newGroup, setNewGroup] = useState(null)
  const [groupInput, setGroupInput] = useState('')

  let viewGroup

  const handleSave = () => {
    if (groupInput !== '' && selectedGroup === '') {
      viewGroup = groupInput
    }

    setFetching(true)
    onSave(name, viewGroup)

    setTimeout(() => {
      // 500 ms timeout to close window after save
      handleOnClose()
    }, 500)
  }

  const handleOnClose = () => {
    // cleanup
    setOpen(false)
    setFetching(false)
    onClose()
  }

  const handleChange = e => {
    setSelectedGroup(e.target.value)
  }

  const handleNameChange = e => {
    setName(e.target.value)
  }

  useEffect(() => {
    viewGroup = selectedGroup
  }, [selectedGroup])

  const MapViewSelect = () => {
    var mapViewGroups
    if (typeof mapViews !== 'object' || !Array.isArray(mapViews)) {
      mapViewGroups = []
    } else mapViewGroups = mapViews.filter(group => group.groupId)

    mapViewGroups.unshift('')

    return (
      <>
        <div className={appScss['select-chevron']}>
          <select
            id='group-select'
            value={selectedGroup}
            defaultValue='none'
            onChange={handleChange}
          >
            <option disabled value='none'>
              Select Group
            </option>
            {mapViewGroups.map(group => (
              <option value={group.groupId} key={group.id}>
                {group.label}
              </option>
            ))}
          </select>
          <Icon icon={('fal', 'chevron-down')} />
        </div>
        <span style={{ margin: 'auto 18px' }}> Or </span>
      </>
    )
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-mapview-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='add-mapview-title'>
          Save as new map view
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <label
            htmlFor='new-map-view-name'
            className={appScss['input-notched']}
            style={{ marginBottom: '16px' }}
          >
            <span>Name</span>
            <input
              id='new-map-view-name'
              onChange={handleNameChange}
              onSubmit={fetching ? null : handleSave}
              value={name}
              placeholder='Input text'
            />
          </label>
          {/* <MuiFormControlText
            label="Name"
            onChange={handleNameChange}
            onSubmit={fetching ? null : handleSave}
            value={name}
          /> */}

          <div>
            <InputLabel id='group-select-label'>Assign to Group</InputLabel>

            <div style={{ width: '475px' }} className={appScss.fullWidthRow}>
              {<MapViewSelect />}
              <button
                className={appScss.altBlueButton}
                style={{ minWidth: '119px' }}
                onClick={() => setNewGroup(true)}
              >
                New
              </button>
            </div>
          </div>

          {newGroup && (
            <>
              {/* <TextField
                variant='outlined'
                label='Group Name'
                value={groupInput}
                onChange={e => setGroupInput(e.target.value)}
              />
              <MuiFormControlText
                label='Group Name'
                onChange={e => setGroupInput(e.target.value)}
                onSubmit={fetching ? null : handleSave}
                value={groupInput}
              /> */}

              <label
                htmlFor='new-map-view-group-name'
                className={appScss['input-notched']}
                style={{ marginTop: '16px' }}
              >
                <span>Group Name</span>
                <input
                  id='new-map-view-group-name'
                  onChange={e => setGroupInput(e.target.value)}
                  onSubmit={fetching ? null : handleSave}
                  value={groupInput}
                  placeholder='Input text'
                />
              </label>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleOnClose}>
            Cancel
          </button>

          <button
            className={appScss.blueButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <Icon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function AddMapView(props) {
  return <SimpleDialog {...props} />
}
