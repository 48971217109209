import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setTocLayersFilter } from '../../../actions/index'
import scss from '../TocControls/MapSelector.scss'
import Icon from '../../../components/Icon/Icon'

const LayerSearch = React.memo(() => {
  const dispatch = useDispatch()
  const [searchValue, setSeachValue] = useState('')

  const onChange = e => {
    setSeachValue(e.target.value.replace(/[^a-zA-Z0-9_ ]/g, ''))
  }

  useEffect(() => {
    dispatch(setTocLayersFilter(searchValue))
  }, [searchValue])

  return (
    <div className={scss.layerSearch}>
      <form onSubmit={e => e.preventDefault()} noValidate autoComplete='off'>
        <Icon
          icon={['far', 'search']}
          size='1x'
          pull='left'
          className={scss.layerSearchIcon}
        />
        <input
          onChange={onChange}
          value={searchValue}
          className={scss.layerSearchInput}
          placeholder='Search Layers'
          style={{ textAlign: 'left' }}
        />
      </form>
    </div>
  )
})

export default LayerSearch
