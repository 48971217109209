import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { getMapHomeLocation, refreshDetails } from '../../../utilities/user'
import { apis } from '../../../config/apiConfig'
import ZoomToPoint from '../../../components/ZoomToPoint/ZoomToPoint'
import { Menu, Item, MenuProvider } from 'react-contexify'
import 'react-contexify/dist/ReactContexify.min.css'
import Icon from '../../../components/Icon/Icon'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
} from '@/styles/tailwind-utilities'
import mapScss from '../../Map/Map.scss'
import contextScss from '../../CSSModules/ContextMenu.scss'

const Home = React.memo(({ mapID, enableMap }) => {
  const user = useSelector(state => state.user)
  const viewport = useSelector(state => state.viewport)
  const [homeCoordinates, setHomeCoordinates] = useState(null)
  const [homeZoom, setHomeZoom] = useState(null)
  const [zoomToPointKey, setZoomToPointKey] = useState(null)

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const dispatch = useDispatch()

  const goHome = () => {
    let newKey = zoomToPointKey
    if (!newKey) newKey = 1
    newKey++
    setZoomToPointKey(newKey)
  }

  const getHomeLocation = () => {
    const location = getMapHomeLocation(user)
    const coordsArray = [location.y, location.x]
    const zoom = parseFloat(location.z)
    setHomeCoordinates(coordsArray)
    setHomeZoom(zoom)
  }

  const setHomeLocation = () => {
    let newLocationY = viewport.latitude
    let newLocationX = viewport.longitude
    let newLocationZ = viewport.zoom

    // Sets the new home coordinates
    const coordsArray = [newLocationX, newLocationY]
    setHomeCoordinates(coordsArray)
    setHomeZoom(newLocationZ)

    buildFetchParams(user, newLocationY, newLocationX, newLocationZ)
  }

  const buildFetchParams = (user, newLocationY, newLocationX, newLocationZ) => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'map/update'
    const body = {
      mapID: mapID,
      lat: newLocationY,
      lon: newLocationX,
      zoom: newLocationZ,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
    })

    setFetchObjects(null)
    setFetching(false)
    enableMap()
  }

  useEffect(() => {
    getHomeLocation()
  }, [])

  return (
    <>
      {zoomToPointKey && (
        <ZoomToPoint
          key={zoomToPointKey}
          viewport={viewport}
          coordinates={homeCoordinates}
          zoom={homeZoom}
        />
      )}
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <MenuProvider id='home-change'>
        <button
          className={`${buttonBase} ${mapControlButton} ${mapControlButtonColors.default} rounded-t-xl rounded-b-none border-b-[1px] border-primary-hover`}
          onClick={goHome}
        >
          <Icon icon='home' size='xl' />
        </button>
      </MenuProvider>
      <Menu
        style={{ zIndex: 2 }}
        className={contextScss.contextMenu}
        id='home-change'
      >
        <Item onClick={fetching ? '' : setHomeLocation}>
          {fetching ? (
            <Icon icon='spinner' size='1x' fixedWidth spin />
          ) : (
            'Set New Home'
          )}
        </Item>
      </Menu>
    </>
  )
})

export default Home
