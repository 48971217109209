import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import Icon from '../../../components/Icon/Icon'
import SaveLayout from './Modals/SaveLayout/SaveLayout'
import ConfirmDelete from './Modals/ConfirmDelete/ConfirmDelete'

import { selectPrintStyle } from '../../../utilities/componentConstants/selectComponentStyle'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'

import scss from './Print.scss'
import appScss from '../../App/App.scss'
import dropdownScss from '../../CSSModules/Dropdown.scss'

const DEFAULT_SELECT_OPTION = { label: 'Default Layout', value: 'default' }

const PrintLayouts = ({
  toggleFileDropdown,
  showFileDropdown,
  setInitialMap,
  setPrintState,
  dataToSave,
  toggleMode,
}) => {
  const [fetching, setFetching] = useState(true)
  const [selectedLayout, setSelectedLayout] = useState(DEFAULT_SELECT_OPTION)
  const [newLayoutId, setNewLayoutId] = useState(null)
  const [layouts, setLayouts] = useState([])
  const [fetchObjects, setFetchObjects] = useState(null)
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [fetchType, setFetchType] = useState('layouts')

  const getLayouts = () => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'user/preferences/get'
    const body = {
      key: 'tool.print.layout',
    }
    return { url, method, body }
  }

  const deleteLayout = () => {
    if (selectedLayout.value === 'default') {
      setOpenDeleteModal(false)
    } else {
      setOpenDeleteModal(true)
    }
  }

  const updateExistingLayout = prefId => {
    const updatedLayout = {
      mode: dataToSave.mode,
      orientPortrait: dataToSave.orientPortrait,
      elements: dataToSave.elements,
      dpi: dataToSave.dpi,
      editMode: dataToSave.editMode,
      activeElement: dataToSave.activeElement,
      editToolPosition: dataToSave.editToolPosition,
      width: dataToSave.width,
      height: dataToSave.height,
      pageSize: dataToSave.pageSize,
      printSettings: {
        dpi: dataToSave.dpi,
        pageSize: dataToSave.pageSize,
        orientPortrait: dataToSave.orientPortrait,
      },
    }

    const method = 'POST'
    const url = apis.apiDatabase.uri + 'user/preferences/update'
    const body = {
      key: 'tool.print.layout',
      prefID: prefId,
      name: selectedLayout.label,
      value: updatedLayout,
    }
    setFetching(true)
    setFetchType('update')
    setFetchObjects([{ url, method, body }])
  }

  const saveLayout = () => {
    if (selectedLayout.value === 'default') {
      // save new layout
      setOpenSaveModal(true)
    } else {
      // update existing
      updateExistingLayout(selectedLayout.value)
    }
  }

  const saveNewLayout = layoutName => {
    const newLayout = {
      mode: dataToSave.mode,
      orientPortrait: dataToSave.orientPortrait,
      elements: dataToSave.elements,
      dpi: dataToSave.dpi,
      editMode: dataToSave.editMode,
      activeElement: dataToSave.activeElement,
      editToolPosition: dataToSave.editToolPosition,
      width: dataToSave.width,
      height: dataToSave.height,
      pageSize: dataToSave.pageSize,
      printSettings: {
        dpi: dataToSave.dpi,
        pageSize: dataToSave.pageSize,
        orientPortrait: dataToSave.orientPortrait,
      },
    }
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'user/preferences/add'
    const body = {
      key: 'tool.print.layout',
      name: layoutName,
      value: newLayout,
    }
    setFetching(true)
    setFetchType('save')
    setFetchObjects([{ url, method, body }])
  }

  const doDeleteLayout = () => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'user/preferences/delete'
    const body = {
      key: 'tool.print.layout',
      prefID: selectedLayout.value,
    }
    setFetching(true)
    setFetchType('delete')
    setFetchObjects([{ url, method, body }])
  }

  const handleSelectChange = selected => {
    setSelectedLayout(selected)
    if (selected.value === 'default') {
      setInitialMap()
    } else {
      const layoutState = selected.elements
      if (layoutState) {
        const printSettings = layoutState.printSettings || {
          dpi: layoutState.dpi || 240,
          pageSize: layoutState.pageSize || 'LETTER',
          orientPortrait: layoutState.orientPortrait || false,
        }

        setPrintState({
          mode: layoutState.mode || 'LAYOUT',
          orientPortrait: printSettings.orientPortrait,
          elements: layoutState.elements || [],
          dpi: printSettings.dpi,
          editMode: layoutState.editMode || false,
          activeElement: layoutState.activeElement || null,
          activeElementWindow: layoutState.activeElementWindow || null,
          textAreaElements: layoutState.textAreaElements || false,
          dateStampElements: layoutState.dateStampElements || false,
          legendElement: layoutState.legendElement || false,
          northArrowElements: layoutState.northArrowElements || false,
          scaleBarElement: layoutState.scaleBarElement || true,
          layoutName: layoutState.layoutName || '',
          layoutNameError: layoutState.layoutNameError || '',
          selectedLayout: layoutState.selectedLayout || DEFAULT_SELECT_OPTION,
          savedLayouts: layoutState.savedLayouts || [],
          layoutOption: layoutState.layoutOption || [],
          editToolPosition: layoutState.editToolPosition || { x: 0, y: 0 },
          lockMap: layoutState.lockMap || false,
          excludedLayers: layoutState.excludedLayers || [],
          pageSize: printSettings.pageSize,
          width: layoutState.width,
          height: layoutState.height,
          history: {
            past: [],
            present: layoutState.elements || [],
            future: [],
          },
        })
      }
    }
  }

  const fetchFinished = result => {
    setFetchObjects(null)
    setOpenSaveModal(false)
    setOpenDeleteModal(false)
    if (fetchType === 'save') {
      const fetchParams = getLayouts()
      setNewLayoutId(result[0].data)
      setFetchType('layouts')
      setFetchObjects([fetchParams])
    }
    if (fetchType === 'delete') {
      const fetchParams = getLayouts()
      setInitialMap()
      setNewLayoutId('default')
      setFetchType('layouts')
      setFetchObjects([fetchParams])
    }

    if (fetchType === 'update') {
      const fetchParams = getLayouts()
      setNewLayoutId(null)
      setFetchType('layouts')
      setFetchObjects([fetchParams])
    }

    if (fetchType === 'layouts') {
      const returnedLayouts = result[0]
      if (returnedLayouts.success) {
        const updatedLayouts = returnedLayouts.data.map(layout => ({
          label: layout.name,
          value: layout.id,
          elements: layout.value,
        }))
        updatedLayouts.unshift(DEFAULT_SELECT_OPTION)
        setLayouts(updatedLayouts)
        setFetching(false)
        if (newLayoutId) {
          if (newLayoutId === 'default') {
            setSelectedLayout(DEFAULT_SELECT_OPTION)
          } else {
            const selected = returnedLayouts.data
              .filter(layout => layout.id === Number(newLayoutId))
              .map(layout => ({
                label: layout.name,
                value: layout.id,
                elements: layout.value,
              }))
            if (selected.length > 0) {
              setSelectedLayout(selected[0])
            }
          }
          setNewLayoutId(null)
        }
      }
    }
  }

  useEffect(() => {
    const fetchParams = getLayouts()
    setFetchObjects([fetchParams])
    return () => {}
  }, [])

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {openSaveModal && (
        <SaveLayout
          modalOpen={openSaveModal}
          closeModal={() => setOpenSaveModal(false)}
          message={<p>Enter a name for the layout you wish to save.</p>}
          doSaveLayout={saveNewLayout}
        />
      )}
      {openDeleteModal && (
        <ConfirmDelete
          modalOpen={openDeleteModal}
          closeModal={() => setOpenDeleteModal(false)}
          targetLabel={selectedLayout.label}
          message={
            <p>
              You have selected to delete the layout:{' '}
              <b>{selectedLayout.label}</b>.
            </p>
          }
          title='Delete Layout'
          doDeleteLayout={doDeleteLayout}
        />
      )}

      {layouts.length && (
        <div className={scss.layoutSelect}>
          {fetching ? (
            <Icon icon='spinner' spin fixedWidth />
          ) : (
            <Select
              className={scss.printLayoutSelect}
              type='select'
              styles={selectPrintStyle}
              value={selectedLayout}
              onChange={handleSelectChange}
              options={layouts}
            />
          )}
        </div>
      )}

      <div // FILE DROPDOWN
        className={[
          scss.fileBtn,
          scss.printDropdown,
          dropdownScss.dropdown,
        ].join(' ')}
        onClick={toggleFileDropdown}
      >
        File
        <Icon
          icon='chevron-down'
          size='xs'
          pull='right'
          color='white'
          className={scss.printArrowIcon}
        />
        {showFileDropdown && (
          <div
            className={[
              scss.printDropdownContent,
              dropdownScss.dropdownContent,
            ].join(' ')}
          >
            <div
              className={[scss.printDropdownItem].join(' ')}
              onClick={saveLayout}
            >
              Save Layout
            </div>

            {selectedLayout.value === 'default' ? (
              <div
                className={[scss.printDropdownItem].join(' ')}
                onClick={null}
              >
                Delete Layout
              </div>
            ) : (
              <div
                className={[scss.printDropdownItem].join(' ')}
                onClick={deleteLayout}
              >
                Delete Layout
              </div>
            )}
            <div
              className={[scss.printDropdownItem, scss.viewPdf].join(' ')}
              onClick={toggleMode}
            >
              View PDF
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default PrintLayouts
