import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/Icon/Icon'

import scss from '../ProfileStyling/ProfileModal.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import * as utils from '../../../utilities/util'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ customerID, onClose, type, updateProfile }) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  // input fields state
  const [nameValue, setNameValue] = useState('')
  const [addressLine1Value, setAddressLine1Value] = useState('')
  const [addressLine2Value, setAddressLine2Value] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')

  const handleNameChange = e => setNameValue(e.target.value)
  const handleAddressLine1Change = e => setAddressLine1Value(e.target.value)
  const handleAddressLine2Change = e => setAddressLine2Value(e.target.value)
  const handleCityChange = e => setCity(e.target.value)
  const handleStateChange = e => setState(e.target.value)
  const handleZipChange = e => setZip(e.target.value)

  const handleOnClose = () => {
    setOpen(false)
    onClose()
  }

  const formIsVerified = () => {
    if (addressLine1Value !== '' && city !== '' && state !== '' && zip !== '')
      return true

    return false
  }

  const handleSave = () => {
    const url = apis.apiDatabase.uri + 'stripe/customer/update'
    const method = 'POST'
    let body = { customerID }

    let targetAddress = {
      line1: addressLine1Value,
      line2: addressLine2Value ? addressLine2Value : null,
      city,
      state,
      postal_code: zip,
    }

    if (type === 'shipping') {
      body.shipping = {}
      body.shipping.name = nameValue
      body.shipping.address = targetAddress
    } else if (type === 'billing') {
      body.address = targetAddress
    }

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        updateProfile(res.data)
      }
    })

    setFetchObjects(null)
    setFetching(false)
    handleOnClose()
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='export-to-csv-dialog'
        open={open}
        className={modalScss.lightModal}
        maxWidth='lg'
      >
        <DialogTitle>
          Update {type === 'shipping' ? `Shipping` : `Billing`} Address
          Information{' '}
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent className={modalScss.MuiDialogBoxes}>
          {type === 'shipping' ? (
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ marginBottom: '32px' }}
            >
              <label htmlFor='addressLine1'>Name</label>
              <input
                type='text'
                id='name'
                placeholder='John Doe'
                value={nameValue}
                onChange={handleNameChange}
                style={{ textAlign: 'left' }}
              />
            </div>
          ) : null}
          <div className={[scss.flexColumn, scss.paymentInput].join(' ')}>
            <label htmlFor='addressLine1'>Address Line 1</label>
            <input
              type='text'
              id='addressLine1'
              placeholder='555 Berry Lane'
              value={addressLine1Value}
              onChange={handleAddressLine1Change}                style={{ textAlign: 'left' }}
            />
          </div>
          <div
            className={[scss.flexColumn, scss.paymentInput].join(' ')}
            style={{ margin: '32px 0' }}
          >
            <label htmlFor='addressLine2'>Address Line 2</label>
            <input
              type='text'
              id='addressLine2'
              placeholder='Suite 250'
              value={addressLine2Value}
              onChange={handleAddressLine2Change}                style={{ textAlign: 'left' }}
            />
          </div>
          <div
            className={[scss.flexRow, scss.flexCenter, scss.flexJustify].join(
              ' '
            )}
          >
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '40%' }}
            >
              <label htmlFor='city'>City</label>
              <input
                type='text'
                id='city'
                placeholder='Dallas'
                value={city}
                onChange={handleCityChange}                style={{ textAlign: 'left' }}
              />
            </div>
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '10%' }}
            >
              <label htmlFor='state'>State</label>
              <input
                max={2}
                type='text'
                id='state'
                placeholder='TX'
                value={state}
                onChange={handleStateChange}                style={{ textAlign: 'left' }}
              />
            </div>
            <div
              className={[scss.flexColumn, scss.paymentInput].join(' ')}
              style={{ width: '15%' }}
            >
              <label htmlFor='zip'>Zip Code</label>
              <input
                type='text'
                id='zip'
                placeholder='91002'
                value={zip}
                onChange={handleZipChange}                style={{ textAlign: 'left' }}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleOnClose}>
            Cancel
          </button>
          <button
            className={appScss.blueButton}
            disabled={!formIsVerified()}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <Icon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ProfileAddress(props) {
  return <SimpleDialog {...props} />
}
