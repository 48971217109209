import React from 'react'
import Icon from '../../../components/Icon/Icon'
import scss from './BaseStylePickerBtn.scss'
import { MAP_STYLE_ARRAY } from '../../../mapStyles/style_config'

function BaseStylePicker({
  toggleMapStyle,
  BaseStylePickerVisible,
  currentStyle,
}) {
  const currentStyleIcon = MAP_STYLE_ARRAY.filter(
    style => style.id === currentStyle
  ).map(style => style.icon)

  return (
    <button
      className={[
        scss.mapStyleBtn,
        BaseStylePickerVisible ? scss.mapStyleBtnActive : '',
      ].join(' ')}
      onClick={toggleMapStyle}
      data-tooltip-content='Map Style'
      data-tooltip-id='map-style'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
    >
      <span>
        <img src={currentStyleIcon} className={scss.mapIcon} />
        {currentStyle}
      </span>
      <Icon
        icon={['fa', BaseStylePickerVisible ? 'chevron-up' : 'chevron-down']}
        size='1x'
      />
    </button>
  )
}

export default BaseStylePicker
