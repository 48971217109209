import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import AsyncFetch from '../../utilities/AsyncFetch'
import { refreshDetails } from '../../utilities/user'

import { apis } from '../../config/apiConfig'

import dropdownScss from '../CSSModules/Dropdown.scss'
import sidebarScss from '../CSSModules/GlobalSidebar.scss'
import tocScss from '../CSSModules/GlobalToc.scss'
import appScss from '../App/App.scss'
import scss from './Profile.scss'
import { getMapHomeLocation } from '../../utilities/user'
import ZoomToPoint from '../../components/ZoomToPoint/ZoomToPoint'

import Icon from '../../components/Icon/Icon'

import * as utils from '../../utilities/util'

import {
  switchPage,
  Login as _Login,
  Logout as _Logout,
  setAccountKey,
  setToc,
} from '../../actions'

import { NavLink } from 'react-router-dom'
import MakeDataConfigComponent from '../../utilities/dataConfig'

export default function Sidebar({
  setSidebarOpen,
  setSidebarTransitions,
  logout,
}) {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const viewport = useSelector(state => state.viewport)
  const dispatch = useDispatch()

  const [showOrgSwitcher, setOrgSwitcher] = useState(false)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [fetchingAccount, setFetchingAccount] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [homeLocation, setHomeLocation] = useState(null)
  const [homezoom, setHomezoom] = useState(null)
  const [zoomToPointKey, setZoomToPointKey] = useState(1)

  const [profileImgURL, setProfileImgURL] = useState(
    'https://my-asset-map-data.s3.amazonaws.com/public/client_data/static/images/user.png'
  )
  const [accountImageURLs, setAccountImageURLs] = useState([])

  const toggleOrgSwitcher = () => {
    if (user.accounts.length > 1) {
      if (showOrgSwitcher) {
        setOrgSwitcher(false)
      } else setOrgSwitcher(true)
    }
  }

  const buildFetchParams = accountID => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'user/update'
    let body = {
      accountID: accountID,
    }

    setFetchObjects([{ url, method, body }])
    console.log('fetchObjects', fetchObjects)
    setFetchingAccount(true)
  }

  const fetchFinished = () => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setMountMakeDataConfig(true)
      getHomeLocation(newUser)
    })

    setFetchObjects(null)
  }

  const getHomeLocation = user => {
    const location = getMapHomeLocation(user)
    const coordsArray = [location.y, location.x]
    const zoom = parseFloat(location.z)

    setHomeLocation(coordsArray)
    setHomezoom(zoom)
  }

  const goHome = () => {
    let newKey = zoomToPointKey
    if (!newKey) newKey = 1
    newKey++
    setZoomToPointKey(newKey)
  }

  const updateAccount = accountID => {
    buildFetchParams(accountID)
  }

  const makeDataConfigFinished = () => {
    const accountKey = 'map_' + user.accountID + '_' + user.mapID
    if (homeLocation) goHome()
    setMountMakeDataConfig(false)
    setFetchingAccount(false)
  }

  const backToMap = () => {
    if (setSidebarOpen != null) {
      setSidebarOpen(false)
      dispatch(setToc(false))
    }
  }

  const btnClick = () => {
    if (setSidebarTransitions != null) setSidebarTransitions(false)
    if (setSidebarOpen != null) setSidebarOpen(false)
    dispatch(setToc(false))

    setTimeout(function () {
      if (setSidebarTransitions != null) setSidebarTransitions(true)
    }, 500)
  }

  let account = utils.objectKeyVal(user.accountID, user.accounts, 'id')

  //Profile Images
  useEffect(() => {
    async function getProfileImage() {
      const imgURL = await utils.getImage(user.profile.profileImg, 'user.png')

      setProfileImgURL(imgURL)
    }
    getProfileImage()
  }, [user])

  //Account Images
  const getAccountImages = async () => {
    return Promise.all(
      user.accounts.map(async account => {
        const imgURL = await utils.getImage(account.profileImg, 'account.png')

        return {
          id: account.id,
          name: account.name,
          image: imgURL,
        }
      })
    )
  }

  useEffect(() => {
    async function getAccountURLS() {
      if (Array.isArray(user.accounts)) {
        getAccountImages().then(accountURLs => {
          setAccountImageURLs(accountURLs)
        })
      }
    }
    getAccountURLS()
  }, [user])

  //Sort Accounts Alphabetically
  user.accounts.sort(utils.objectSort('name'))

  return (
    <nav className={tocScss.tocProfile} onBlur={backToMap}>
      {mountMakeDataConfig && (
        <MakeDataConfigComponent
          onFinish={makeDataConfigFinished}
          updateStyle={false}
          resetStyle={true}
        />
      )}
      {zoomToPointKey && (
        <ZoomToPoint
          key={zoomToPointKey}
          viewport={viewport}
          coordinates={homeLocation}
          zoom={homezoom}
        />
      )}
      <div id={tocScss.userProfileSidebar}>
        <NavLink to='/'>
          <div onClick={backToMap} className={sidebarScss.sidebarHeader}>
            <Icon icon={['far', 'angle-double-left']} size='1x' />
          </div>
        </NavLink>

        <div className={appScss['marY-md']}></div>

        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}

        <div className={sidebarScss.sidebarProfile}>
          <img src={profileImgURL} className={scss.profilePic}></img>
          <div className={sidebarScss.headingOrg}>
            <div
              className={[dropdownScss.dropdown].join(' ')}
              onClick={toggleOrgSwitcher}
            >
              {fetchingAccount ? (
                <div>{t('sidebar.account.loading')}</div>
              ) : (
                <div>{account.name}</div>
              )}

              {!fetchingAccount && user.accounts.length > 1 && (
                <>
                  &nbsp;&nbsp;
                  <Icon
                    icon={[
                      'fa',
                      'chevron-' + (showOrgSwitcher ? 'up' : 'down'),
                    ]}
                    size='1x'
                  />
                </>
              )}
              {/* ----------------------- START OF ORG DROPDOWN ITEMS ---------------------- */}
              {showOrgSwitcher && (
                <>
                  <div
                    style={{ maxWidth: '100%' }}
                    className={dropdownScss.dropdownCover}
                    onClick={toggleOrgSwitcher}
                  />
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      dropdownScss.orgDropdownContent,
                    ].join(' ')}
                  >
                    {user.accounts.map(account => {
                      var profileImgObj = utils.objectKeyVal(
                        account.id,
                        accountImageURLs,
                        'id'
                      )

                      return (
                        <div
                          key={account.id}
                          className={[
                            dropdownScss.dropdownItem,
                            dropdownScss.orgDropdownItem,
                          ].join(' ')}
                          onClick={() => updateAccount(account.id)}
                        >
                          <img
                            src={profileImgObj ? profileImgObj.image : ''}
                            className={[dropdownScss.orgDropdownPic].join(' ')}
                          ></img>
                          <div className={dropdownScss.orgDropdownText}>
                            <h4>{account.name}</h4>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </>
              )}
              {/* -------------------------------------------------------------------------- */}
            </div>
          </div>
          <NavLink to='/profile'>
            {user.profile.displayName}{' '}
            {user.isAdmin ? t('sidebar.account.adminLabel') : ''}
          </NavLink>
        </div>

        <div className={sidebarScss.sidebarMain}>
          {fetchingAccount ? (
            <Icon icon='spinner' size='1x' fixedWidth spin />
          ) : (
            <>
              <NavLink to='/'>
                <div onClick={backToMap} className={sidebarScss.sidebarButtons}>
                  {t('sidebar.navigation.backToMap')}
                </div>
              </NavLink>
              <NavLink
                activeClassName={sidebarScss.selectedSidebarLink}
                to='/profile'
              >
                <div onClick={btnClick} className={sidebarScss.sidebarButtons}>
                  {t('sidebar.navigation.profileAndAccount')}
                </div>
              </NavLink>
              <NavLink
                activeClassName={sidebarScss.selectedSidebarLink}
                to='/layers'
              >
                <div onClick={btnClick} className={sidebarScss.sidebarButtons}>
                  {t('sidebar.navigation.data')}
                </div>
              </NavLink>
              {user.adminAccounts.length !== 0 && (
                <>
                  <NavLink
                    activeClassName={sidebarScss.selectedSidebarLink}
                    to='/maps'
                  >
                    <div
                      onClick={btnClick}
                      className={sidebarScss.sidebarButtons}
                    >
                      {t('sidebar.navigation.maps')}
                    </div>
                  </NavLink>
                  <NavLink
                    activeClassName={sidebarScss.selectedSidebarLink}
                    to='/users'
                  >
                    <div
                      onClick={btnClick}
                      className={sidebarScss.sidebarButtons}
                    >
                      {t('sidebar.navigation.users')}
                    </div>
                  </NavLink>

                  {utils.isSuperAdmin(user) && ( //checks to see if user is in adminAccountList array
                    <NavLink
                      activeClassName={sidebarScss.selectedSidebarLink}
                      to='/accounts'
                    >
                      <div
                        onClick={btnClick}
                        className={sidebarScss.sidebarButtons}
                      >
                        {t('sidebar.navigation.accounts')}
                      </div>
                    </NavLink>
                  )}
                </>
              )}

              <NavLink to='/' onClick={logout}>
                <div className={sidebarScss.sidebarLogout}>
                  {t('sidebar.navigation.signOut')}
                </div>
              </NavLink>
            </>
          )}
        </div>

        <div className={sidebarScss.sidebarFooter}>
          <NavLink to='/'>
            <span className={sidebarScss.sidebarLogo}>
              {t('sidebar.brand.name')}
            </span>
          </NavLink>
        </div>
      </div>
    </nav>
  )
}
