import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import ImageLayer from './ImageLayer'
import VectorLayer from './VectorLayer'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import appScss from '../../../../App/App.scss'
import scss from '../HeaderButton.scss'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../../../components/Icon/Icon'

const NewLayer = () => {
  const user = useSelector(state => state.user)

  const [newLayerOpen, setNewLayerOpen] = useState(false)
  const layerTypes = [
    { label: 'Point', value: 'circle' },
    { label: 'Line', value: 'line' },
    { label: 'Polygon', value: 'fill' },
    { label: 'Image', value: 'image' },
  ]

  const [layerName, setLayerName] = useState('')
  const [layerDescription, setLayerDescription] = useState('')
  const [layerType, setLayerType] = useState('')
  const [urlObject, setUrlObject] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)

  const onInputChange = e => {
    setLayerName(e.target.value)
  }
  const onDescChange = e => setLayerDescription(e.target.value)

  const onSelectChange = e => {
    const selected = layerTypes.filter(type => type.value === e.target.value)
    setLayerType(selected[0])
  }

  const closeTool = () => {
    if (layerType.value === '') {
      setSaveAttempted(true)
    } else {
      setLayerName('')
      setNewLayerOpen(false)
    }
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      setUrlObject(null)
    },
    [urlObject]
  )

  return (
    <>
      {newLayerOpen && (
        <ThemeProvider theme={mainModalTheme}>
          <Dialog
            onClose={closeTool}
            aria-labelledby='new-layer-dialog'
            open={true}
          >
            <DialogTitle id='new-layer-title'>
              New Layer
              <Icon
                onClick={closeTool}
                icon='times'
                size='sm'
                pull='right'
                style={{ cursor: 'pointer' }}
              />
            </DialogTitle>

            <DialogContent className={scss['new-layer-body']}>
              <label
                htmlFor='new-layer-name'
                className={appScss['input-notched']}
              >
                <span>Layer Name</span>
                <input
                  id='new-layer-name'
                  onChange={onInputChange}
                  value={layerName}
                  placeholder='Layer Name'
                  style={{ textAlign: 'left' }}
                />
              </label>

              <label
                htmlFor='new-layer-description'
                className={appScss['input-notched']}
                style={{ marginTop: '16px' }}
              >
                <span>Layer Description</span>
                <textarea
                  id='new-layer-description'
                  onChange={onDescChange}
                  value={layerDescription}
                  placeholder='Layer Description'
                />
              </label>

              <label
                htmlFor='new-layer-type'
                className={appScss['input-notched']}
                style={{ marginTop: '16px' }}
              >
                <div className={appScss['select-chevron']}>
                  <span>Layer Type</span>
                  <select defaultValue='none' onChange={onSelectChange}>
                    <option disabled value='none'>
                      Layer Type
                    </option>
                    {layerTypes.map(type => {
                      return (
                        <option key={type.value} value={type.value}>
                          {type.label}
                        </option>
                      )
                    })}
                  </select>
                  <Icon icon={('fal', 'chevron-down')} />
                </div>
              </label>
            </DialogContent>
            {layerType && layerType.value === 'image' ? (
              <ImageLayer
                layerName={layerName}
                layerType={layerType}
                closeTool={closeTool}
              />
            ) : (
              <VectorLayer
                layerName={layerName}
                layerDescription={layerDescription}
                layerType={layerType}
                closeTool={closeTool}
              />
            )}
          </Dialog>
        </ThemeProvider>
      )}
      {user.mapID && user.permissions.layerAdd ? (
        <button
          onClick={() => setNewLayerOpen(!newLayerOpen)}
          className={scss['toc-header-button']}
        >
          New Layer
          <Icon className={scss['plus-icon']} icon='plus' />
        </button>
      ) : null}
    </>
  )
}

export default NewLayer
