import React, { useState } from 'react'
import Icon from '../../../components/Icon/Icon'
import { ThemeProvider } from '@material-ui/core/styles'

// components
import Button from '../../../components/Button/Button'
import ReactSelect from '../../../components/ReactSelect/ReactSelect'
import mainPanelTheme from '../../../utilities/componentConstants/mainPanelTheme'
import CloseTool from '../CloseTool'
import DataUpload from './DataUpload'

// scss files
import scss from './DataImport.scss'
import appScss from '../../App/App.scss'

export default function DataImport({ config, onCollapse }) {
  const [collapsed, setCollapsed] = useState(config.collapsed)
  const [closeToolKey, setCloseToolKey] = useState(null)
  const [fileUploadVisible, setFileUploadVisible] = useState(false)

  const fileTypes = [
    { label: 'Shape File (.zip)', value: 'shp', extension: '.zip,.shp' },
    { label: 'Comma Separated Values (.csv)', value: 'csv', extension: '.csv' },
    {
      label: 'GeoJSON (.geojson)',
      value: 'geojson',
      extension: '.geojson,json',
    },
    { label: 'Google Earth (.kml)', value: 'kml', extension: '.kml' },
    { label: 'GPS Exchange Format (.gpx)', value: 'gpx', extension: '.gpx' },
  ]
  const [fileType, setFileType] = useState(fileTypes[0])
  const [initialFileType, setInitialFileType] = useState(fileType)

  const openFileUpload = fileUploadVisible =>
    setFileUploadVisible(fileUploadVisible)

  const setToolCollapsed = collapsed => {
    setCollapsed(collapsed)
    onCollapse(config.name, collapsed)
  }
  const closeTool = () => setCloseToolKey('closeToolKey')

  const onSelectChange = selected => {
    const newFileType = fileTypes.filter(
      type => type.value === selected.target.value
    )
    setFileType(newFileType[0])
  }

  const getCollapsedUI = () => {
    let ui = (
      <div className={scss.toolQuickActionButtonWrapper}>
        <Button
          onClick={() => setToolCollapsed(false)}
          leadingIcon='expand-alt'
          leadingIconSize='lg'
          buttonType='toolHeaderButton'
          title='Expand'
        />
      </div>
    )
    return (
      <div className={[scss.toolQuickActionHeader].join(' ')}>
        <div className={[scss.handleContainer, 'handle'].join(' ')}>
          <Icon icon={['fas', 'upload']} size='1x' />
        </div>
        <div className={scss.toolHeaderButtonWrapper}>{ui}</div>
      </div>
    )
  }

  const getFullUI = () => {
    let collapseIcon = 'minus'
    const label = 'Data Import'
    const ui = (
      <div className={scss.toolBody}>
        <div
          className={[scss.container, scss['data-import-tool-container']].join(
            ' '
          )}
        >
          {fileUploadVisible && (
            <DataUpload
              onClose={() => openFileUpload(false)}
              uploadFinished={closeTool}
              fileType={fileType.extension}
            />
          )}
          <label
            className={appScss['primary-label']}
            style={{ marginBottom: '12px' }}
          >
            File Type
            <ReactSelect
              options={fileTypes}
              onChange={(values, obj) => setFileType(obj)}
              defaultValue={initialFileType}
              isFixed
            />
          </label>
          {fileType.value === 'shp' ? (
            <div className={scss.note}>
              ESRI Shape Files must be compressed as .zip file
            </div>
          ) : null}
          <button
            className={scss['data-import-tool-upload']}
            onClick={() => openFileUpload(true)}
          >
            Upload File
          </button>
        </div>
      </div>
    )

    return (
      <>
        <ThemeProvider theme={mainPanelTheme}>
          {closeToolKey && (
            <CloseTool key={closeToolKey} toolName='DataImport' />
          )}

          <div className={scss.toolExpanded}>
            <div className={[scss.toolHeader, 'handle'].join(' ')}>
              <div className={scss.toolHeaderRow}>
                <Icon
                  icon={['fas', 'upload']}
                  size='1x'
                  fixedWidth
                />
                <div className={scss.toolTitle}>{config.title}</div>
              </div>
              <div className={scss.toolHeaderButtonWrapper}>
                <Button
                  onClick={() => setToolCollapsed(true)}
                  leadingIcon={collapseIcon}
                  leadingIconSize='lg'
                  buttonType='toolHeaderButton'
                  title='Minimize'
                />
                <Button
                  onClick={closeTool}
                  leadingIcon='times'
                  leadingIconSize='lg'
                  buttonType='toolHeaderButton'
                  title='Close'
                />
              </div>
            </div>
            {ui}
          </div>
        </ThemeProvider>
      </>
    )
  }

  return collapsed ? getCollapsedUI() : getFullUI()
}
