import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '../../../components/Icon/Icon'

import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ emailAddress, visible }) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setfetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [emailAddressState, setEmailAddress] = useState(emailAddress)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'user/update'
    let body = {
      emailAddress: emailAddressState,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const onEmailAddressChange = e => {
    setEmailAddress(e.target.value)
    setEnableSave(true)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='export-to-csv-dialog'
        open={open}
      >
        <DialogTitle id='export-to-csv'>
          Update Email{' '}
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent className={modalScss.MuiDialogBoxes}>
          <MuiFormControlText
            disabled={false}
            label='Email Address'
            placeholder='Enter New Email'
            onChange={onEmailAddressChange}
            onSubmit={fetching ? null : handleSave}
            value={emailAddressState}
          />
          {/* <MuiFormControlText
            disabled={false}
            label="Confirm Email Address"
            placeholder="Confirm Email"
            onChange={onEmailAddressChange}
            onSubmit={fetching ? null : handleSave}
            value={emailAddressState}
          /> */}
        </DialogContent>

        <DialogActions>
          <MuiButton
            color='default'
            variant='outlined'
            onClick={handleCancel}
            label='Cancel'
            size='large'
          />
          <MuiButton
            color='primary'
            disabled={!enableSave}
            variant='outlined'
            onClick={fetching ? null : handleSave}
            label={fetching ? <CircularProgress size={10} /> : 'Update Email'}
            size='large'
          />
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ProfileEmail(props) {
  return <SimpleDialog {...props} />
}
