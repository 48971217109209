import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import scss from '../ProfileStyling/ProfileModal.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '../../../components/Icon/Icon'
import * as utils from '../../../utilities/util'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'
import { apis } from '../../../config/apiConfig'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ addons, visible }) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [products, setProducts] = useState(null)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [addonsState, setAddons] = useState(addons)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        console.log(res)
        setProducts(res.data)
      }
    })

    setFetchObjects(null)
    setFetching(false)
  }

  const onAddonsChange = e => {
    setAddons(e.target.value)
    setEnableSave(true)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  useEffect(() => {
    const url = apis['apiDatabase'].uri + 'stripe/addons'
    const method = 'POST'
    const body = {}

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }, [])

  const itemData = { products }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='profile-addons-dialog'
        open={open}
        className={modalScss.lightModal}
        maxWidth='lg'
      >
        <DialogTitle id='profile-addons'>
          Switch to monthly billing
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent
          className={[modalScss.MuiDialogBoxes, scss.periodBody].join(' ')}
        >
          {/* YEARLY PLAN OPTION */}
          <label for='yearlyPlan' className={scss.addonsOptionLabel}>
            <div
              className={[
                scss.addonsOption,
                scss.planRow,
                scss.planJustify,
                scss.planCenter,
              ].join(' ')}
              htmlFor='planOption'
            >
              <div className={[scss.planRow, scss.planCenter].join(' ')}>
                <input
                  name='planPeriodOption'
                  id='yearlyPlan'
                  type='radio'
                ></input>
                <div className={scss.planColumn}>
                  <span className={scss.addonsHeading}>
                    Billed annually (recommended)
                  </span>
                  <span
                    className={[
                      scss.addonsSubHeading,
                      scss.addonsTextLeft,
                    ].join(' ')}
                  >
                    My Asset Map 30 User plan saves 15% over monthly billing.
                  </span>
                </div>
              </div>
              <div className={scss.planColumn}>
                <span className={scss.addonsHeading}>$5999.88</span>
                <span className={scss.addonsSubHeading}>per year</span>
              </div>
            </div>
          </label>

          {/* MONTHLY PLAN OPTION */}
          <label for='monthlyPlan' className={scss.addonsOptionLabel}>
            <div
              className={[
                scss.addonsOption,
                scss.planRow,
                scss.planJustify,
                scss.planCenter,
              ].join(' ')}
              htmlFor='planOption'
            >
              <div className={[scss.planRow, scss.planCenter].join(' ')}>
                <input
                  name='planPeriodOption'
                  id='monthlyPlan'
                  type='radio'
                ></input>
                <div className={scss.planColumn}>
                  <span className={scss.addonsHeading}>Billed monthly</span>
                  <span
                    className={[
                      scss.addonsSubHeading,
                      scss.addonsTextLeft,
                    ].join(' ')}
                  >
                    My Asset Map 30 User plan is $574.98 per month. You will pay
                    $6,899.98 per year.
                  </span>
                </div>
              </div>
              <div className={scss.planColumn}>
                <span className={scss.addonsHeading}>$574.88</span>
                <span className={scss.addonsSubHeading}>per month</span>
              </div>
            </div>
          </label>
        </DialogContent>

        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>
          <button
            className={appScss.blueButton}
            // onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <Icon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ProfileAddons(props) {
  return <SimpleDialog {...props} />
}
