import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Storage } from 'aws-amplify'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import { MuiButton } from '../../../../components/MaterialUi/Dialog'
import * as utils from '../../../../utilities/util'

import modalScss from '../../../CSSModules/Modal.scss'
import appScss from '../../../App/App.scss'
import Icon from '../../../../components/Icon/Icon'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ onClose, layerLabel, layerId }) {
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [open, setOpen] = useState(true)
  const [exportAs, setExportAs] = useState('.shp')

  // Download S3
  const handleDownload = sourcePath => {
    sourcePath = sourcePath.replace('public/', '')
    Storage.get(`${sourcePath}`, { expires: 60 })
      .then(result => {
        console.log('Download File results:', result)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.style = 'display: none'
        a.download = true
        a.href = result
        a.target = '_blank'
        a.click()
        document.body.removeChild(a)
      })
      .catch(err => console.log('Download File error:', err))
  }

  const fetchFinished = results => {
    handleDownload(results[0].data)
    setFetchObjects(null)
    handleOnClose()
    setFetching(false)
  }

  const handleExport = () => {
    const url = apis['apiDatabase'].uri + 'layer/export'
    const method = 'POST'
    const body = {
      layerID: layerId,
      layerName: layerLabel,
      exportAs
    }

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }

  const handleExportChange = e => {
    setExportAs(e.target.value)
  }

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='export-to-csv-dialog'
        open={open}
      >
        <DialogTitle id='export-to-csv'>
          Export Data Layer
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent className={modalScss.MuiDialogBoxes}>
          <p style={{ marginBottom: '16px' }}>
            You have chosen to export the layer <i>{layerLabel}</i>.
          </p>

          <Select onChange={handleExportChange} style={{ width: '100%' }}>
            <MenuItem value={'.shp'}>Shape File (.zip)</MenuItem>
            <MenuItem value={'.csv'}>Comma Separated Values (.csv)</MenuItem>
            <MenuItem value={'.geojson'}>GeoJSON (.geojson)</MenuItem>
            <MenuItem value={'.kml'}>Google Earth (.kml)</MenuItem>
          </Select>
        </DialogContent>

        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>

          <button
            className={appScss.blueButton}
            onClick={fetching ? null : handleExport}
          >
            {fetching ? (
              <Icon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Export'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ExportToCsv(props) {
  return <SimpleDialog {...props} />
}
