import React from 'react'
import { Tooltip } from 'react-tooltip'
import appScss from '../../App/App.scss'
import Icon from '../../../components/Icon/Icon'

const ClearSelect = ({ clearSelectedFeatures, disabled = false }) => {
  return (
    <>
      <button
        onClick={disabled ? null : clearSelectedFeatures}
        className={disabled ? appScss['disabled-button'] : null}
        data-tooltip-content='Clear Selected'
        data-tooltip-id='clear-selected'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='top'
      >
        <Icon icon={['fal', 'sync']} size='1x' />
      </button>
      <div className={appScss.reactToolTip}>
        <Tooltip id={'clear-selected'} getContent={dataTip => `${dataTip}`} />
      </div>
    </>
  )
}

export default ClearSelect
