import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as utils from '../../../utilities/util'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'

import scss from '../Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'
import ReactSelect from '../../../components/ReactSelect/ReactSelect'

import { MuiButton } from '../../../components/MaterialUi/Dialog'
import Icon from '../../../components/Icon/Icon'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ userID, userRole, visible, updateUsers }) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)
  const { accountID } = user

  //Fields and their States
  const [emailAddressArray, setEmailAddressArray] = useState([])
  // const [role, setRole] = useState(options[0])
  const [selectedMaps, setSelectedMaps] = useState([])
  const [selectedMapValue, setSelectedMapValue] = useState([])
  const [mapOptions, setMapOptions] = useState(null)

  //Role Fetching
  const [showRoleDropdown, setRoleDropdown] = useState(false)
  const [roleLoading, setRoleLoading] = useState(false)
  const [roleFetchObjects, setRoleFetchObjects] = useState(null)
  const [roles, setRoles] = useState([])
  const [userRoleState, setRole] = useState(
    userRole == null ? 'full' : userRole
  )

  const handleSave = () => {
    const url = apis['apiDatabase'].uri + 'account/user/add'
    const method = 'POST'
    const body = {
      emailArray: emailAddressArray,
      mapArray: selectedMapValue,
      accountID,
      role: userRoleState,
    }

    setFetching('save')
    setFetchObjects([{ url, method, body }])
  }

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        if (fetching === 'save') {
          updateUsers()
          handleOnClose()
        }
        if (fetching === 'maps') {
          const mapArray = res.data.map(item => ({
            label: item.name,
            value: item.id,
          }))

          setMapOptions(mapArray)
        }
      }
    })

    setFetchObjects(null)
    setFetching(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  // ROLE FETCH
  const updateRoles = () => {
    const method = 'GET'
    let url = apis.apiDatabase.uri + 'roles'
    let body = {}

    setRoleFetchObjects([{ url, method, body }])
    setRoleLoading(true)
  }

  // ROLE FETCH FINISH
  const roleFetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        setRoles(utils.resultReturn(result))

        setRoleLoading(false)
      }

      setRoleFetchObjects(null)
    })
  }

  // FIND ROLE NAMES CALL
  const getRoleName = () => {
    let role = roles.find(role => role.slug === userRoleState)
    return role ? role.name : '-'
  }

  // CHANGE ROLE DROPDOWN SELECTION
  const handleRole = role => {
    setRole(role)
    setRoleDropdown(false)
  }

  const handleEmail = e => {
    let emails = e.target.value
    emails = emails.replace(/\n/g, ',').split(',')
    const procArray = emails.map(email => {
      return email.toLowerCase().trim()
    })

    setEmailAddressArray(procArray)
  }

  useEffect(() => {
    updateRoles()
  }, [])

  useEffect(() => {
    if (mapOptions && mapOptions.length) setSelectedMaps([mapOptions[0]])
  }, [mapOptions])

  useEffect(() => {
    const url = apis['apiDatabase'].uri + 'account/maps'
    const method = 'POST'
    const body = { accountID }

    setFetching('maps')
    setFetchObjects([{ url, method, body }])
  }, [])

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {roleFetchObjects && (
        <AsyncFetch
          fetchObjects={roleFetchObjects}
          fetchFinished={roleFetchFinished}
        />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='add-new-user-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='add-new-user-dialog'>
          Add New User
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent
          className={[
            modalScss.MuiDialogBoxes,
            appScss.flexColumn,
            modalScss.fieldWidths,
            modalScss.addUserModal,
          ].join(' ')}
        >
          <label htmlFor='users-new-user' className={appScss['input-notched']}>
            <span>New User</span>
            <textarea
              id='users-new-user'
              onChange={handleEmail}
              placeholder='user1@company.com, user2@company.com'
            />
          </label>

          {roles && !userID && (
            <>
              <span
                className={modalScss['modal-primary-label']}
                style={{ marginTop: '12px' }}
              >
                Role
              </span>
              <button
                className={scss.selectRoleDropdown}
                onClick={() => setRoleDropdown(!showRoleDropdown)}
              >
                {getRoleName()}
                <Icon
                  icon={showRoleDropdown ? 'chevron-up' : 'chevron-down'}
                  size='1x'
                  pull='right'
                />
              </button>
              {showRoleDropdown && (
                <>
                  <div
                    className={scss.selectRoleDropdownCover}
                    onClick={() => setRoleDropdown(!showRoleDropdown)}
                  />
                  <div
                    className={[
                      scss.dropdownContent,
                      scss.selectRoleDropdownContent,
                    ].join(' ')}
                  >
                    {roles.map(role => (
                      <div
                        key={'role_' + role.id}
                        className={scss.dropdownItem}
                        onClick={() => handleRole(role.slug)}
                      >
                        <div className={scss.dropdownText}>
                          <h4>{role.name}</h4>
                          <p>{role.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
          {/* <span className={scss.selectRoleLabel}>Select Role</span>
          <select
            title='Select a role'
            className={[scss.selectRole, scss.selectField].join(' ')}
            onChange={handleRole}
            value={role}
          >
            {options.map((o) => (
              <option value={o.value}>{o.role}</option>
            ))}
          </select> */}

          <label
            className={modalScss['modal-primary-label']}
            style={{ marginTop: '12px' }}
            htmlFor='users-new-user-map'
          >
            {/* <Select
              labelId="multiple-map-label"
              id="multiple-map"
              multiple
              value={selectedMaps}
              onChange={handleSelectedMaps}
              input={<Input />}
              IconComponent={KeyboardArrowDownIcon}
            >
              {mapOptions && mapOptions.map(map => (
                <MenuItem key={map.value} value={map.value}>
                  {map.label}
                </MenuItem>
              ))}
            </Select> */}
            Map
            <ReactSelect
              options={mapOptions}
              onChange={setSelectedMapValue}
              defaultValue={selectedMaps}
              multiple
              isFixed
            />
          </label>
        </DialogContent>
        <DialogActions>
          <button
            className={modalScss.modalMainButton}
            style={{ width: '100%', placeContent: 'center' }}
            onClick={fetching ? null : handleSave}
            disabled={!selectedMaps.length}
          >
            {fetching ? <CircularProgress size={10} /> : 'Send Invite'}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function UsersAddUserModal(props) {
  return <SimpleDialog {...props} />
}
