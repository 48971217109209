/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Icon from '../../../../../components/Icon/Icon'
import AsyncFetch from '../../../../../utilities/AsyncFetch'
import { apis } from '../../../../../config/apiConfig'
import appScss from '../../../../App/App.scss'
import { Alert } from '../../../../../actions'

const SaveLabels = ({ config, layerId, layer, setDisplaySaveButtonOnMenu}) => {
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)
  const dispatch = useDispatch()

  const handleClick = () => {
    setFetching(true)
    const { layout, paint } = layer
    // set visibility to none, otherwise the label will be saved as visible
    // and will be visible when map reloads.
    layout.visibility = 'none'
    const url = apis.apiDatabase.uri + 'layer/update/sublayer'
    const method = 'POST'
    const body = {
      layerID: layerId,
      paint,
      layout,
      key: 'symbol',
    }
    setFetchObjects([{ url, method, body }])
  }

  const canEdit =
    config.layer.toc.type === 'mam' ? false : config.layer.toc.canEdit
  const isCommon = config.layer.toc.type === 'mam' ? true : false

  const fetchFinished = () => {
    setFetching(false)
    setFetchObjects(null)
    setDisplaySaveButtonOnMenu(false)
    dispatch(Alert({ success: 'Layer Label Style saved.' }))
  }

  if (isCommon) return null
  if (!canEdit) return null

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <button
        type='button'
        className={[appScss.fullWidthButton].join(' ')}
        onClick={handleClick}
      >
        {fetching ? <Icon icon='spinner' size='1x' spin /> : 'Save'}
      </button>
    </>
  )
}

export default SaveLabels
