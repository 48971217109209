import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAppMeasureMode,
  setAppDrawMode,
  disablePopup,
} from '@/actions/index'
import { useFloating, offset, flip, shift } from '@floating-ui/react'
import MeasureLine from './MeasureLine'
import MeasureArea from './MeasureArea'
import MeasurePoint from './MeasurePoint'
import MeasureClear from './MeasureClear'
import Coordinates from './Coordinates'
import { Tooltip } from 'react-tooltip'
import Icon from '@/components/Icon/Icon'

const MeasureMode = React.memo(
  ({ disableMap, enableMap, isVisible, onClose, referenceElement }) => {
    const dispatch = useDispatch()
    const drawMode = useSelector(state => state.drawMode)
    const menuRef = useRef(null)

    const [measureMode, setMeasureMode] = useState(null)
    const [doClear, setDoClear] = useState(false)
    const [showCoordinates, setShowCoordinates] = useState(false)

    const { x, y, strategy, refs, update } = useFloating({
      placement: 'right',
      middleware: [offset(12), flip(), shift()],
    })

    useEffect(() => {
      if (referenceElement) {
        refs.setReference(referenceElement)
      }
    }, [referenceElement])

    useEffect(() => {
      if (isVisible) {
        update()
      }
    }, [isVisible])

    const handleButtonClick = mode => {
      if (mode === measureMode) {
        setMeasureMode(null)
      } else {
        setMeasureMode(mode)
      }
    }

    useEffect(() => {
      const handleClickOutside = event => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          onClose()
        }
      }

      if (isVisible) {
        document.addEventListener('mousedown', handleClickOutside)
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        dispatch(disablePopup(false))
      }
    }, [isVisible])

    useEffect(() => {
      dispatch(setAppMeasureMode(measureMode))
      if (measureMode) {
        dispatch(setAppDrawMode(null))
        dispatch(disablePopup(true))
      } else {
        dispatch(disablePopup(false))
      }
    }, [measureMode])

    useEffect(() => {
      if (drawMode) {
        setMeasureMode(null)
      }
    }, [drawMode])

    useEffect(() => {
      if (doClear) {
        setDoClear(false)
      }
    }, [doClear])

    if (!isVisible) return null

    return (
      <>
        <div
          ref={refs.setFloating}
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            width: 'max-content',
          }}
          className='flex flex-col bg-primary border border-primary-border rounded-lg p-1.5 min-w-[40px] z-[5001]'
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
        >
          <div className='flex flex-row gap-0.5'>
            <button
              className={`w-[40px] h-[40px] border-none rounded flex items-center justify-center cursor-pointer ${
                measureMode === 'MEASURE_LINE'
                  ? 'bg-primary-border text-accent'
                  : 'bg-primary text-white'
              } hover:bg-primary-border`}
              onClick={() => handleButtonClick('MEASURE_LINE')}
              data-tooltip-id='measure-tooltip'
              data-tooltip-content='Measure Line'
              data-tooltip-place='bottom'
            >
              <Icon icon={['fa', 'ruler-vertical']} className='w-6 h-6' />
            </button>
            <button
              className={`w-[40px] h-[40px] border-none rounded flex items-center justify-center cursor-pointer ${
                measureMode === 'MEASURE_AREA'
                  ? 'bg-primary-border text-accent'
                  : 'bg-primary text-white'
              } hover:bg-primary-border`}
              onClick={() => handleButtonClick('MEASURE_AREA')}
              data-tooltip-id='measure-tooltip'
              data-tooltip-content='Measure Area'
              data-tooltip-place='bottom'
            >
              <Icon icon={['fa', 'ruler-combined']} className='w-6 h-6' />
            </button>
            <button
              className={`w-[40px] h-[40px] border-none rounded flex items-center justify-center cursor-pointer ${
                measureMode === 'MEASURE_POINT'
                  ? 'bg-primary-border text-accent'
                  : 'bg-primary text-white'
              } hover:bg-primary-border`}
              onClick={() => handleButtonClick('MEASURE_POINT')}
              data-tooltip-id='measure-tooltip'
              data-tooltip-content='Measure Point'
              data-tooltip-place='bottom'
            >
              <Icon icon={['fa', 'globe-americas']} className='w-6 h-6' />
            </button>
            <button
              className='w-[40px] h-[40px] border-none rounded flex items-center justify-center cursor-pointer bg-primary text-white hover:bg-primary-border'
              onClick={() => setDoClear(true)}
              data-tooltip-id='measure-tooltip'
              data-tooltip-content='Clear Measurements'
              data-tooltip-place='bottom'
            >
              <Icon icon={['fal', 'sync']} className='w-6 h-6' />
            </button>
            <button
              className='w-[40px] h-[40px] border-none rounded flex items-center justify-center cursor-pointer bg-primary text-white hover:bg-primary-border'
              onClick={() => setShowCoordinates(!showCoordinates)}
              data-tooltip-id='measure-tooltip'
              data-tooltip-content='Show Coordinates'
              data-tooltip-place='bottom'
            >
              <Icon icon={['fal', 'cog']} className='w-6 h-6' />
            </button>
          </div>
          {showCoordinates && <Coordinates />}
        </div>
        <Tooltip
          id='measure-tooltip'
          className='z-[9999] !text-base !text-white !px-4 !py-2 !text-[1.1rem] !bg-primary border border-primary-border'
        />
        {measureMode === 'MEASURE_LINE' ? <MeasureLine /> : null}
        {measureMode === 'MEASURE_AREA' ? <MeasureArea /> : null}
        {measureMode === 'MEASURE_POINT' ? <MeasurePoint /> : null}
        {doClear ? <MeasureClear /> : null}
      </>
    )
  }
)

export default MeasureMode
