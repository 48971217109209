import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import DropZone from '../../../../components/DropZone/DropZone'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import { makeStyles } from '@material-ui/core/styles'
import { updateDataConfig, updateMapStyle } from '../../../../actions'
import scss from './TableJoin.scss'
import { fromJS } from 'immutable'
import * as utils from '../../../../utilities/util'
import MakeDataConfigComponent from '../../../../utilities/dataConfig'
import modalScss from '../../../CSSModules/Modal.scss'
import appScss from '../../../App/App.scss'
import Icon from '../../../../components/Icon/Icon'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import ReactSelect from '../../../../components/ReactSelect/ReactSelect'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      minWidth: 350,
    },
  },
}))

const dropDownMenuItems = [
  {
    value: 'csv-edit-import',
    label: 'CSV Export-Edit-Import', 
    acceptableFileTypes: '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    message: 'Please export your data as a CSV file, edit it in Excel, and then import it back.',
  },
  { 
    value: 'shp',
    label: 'Shape File',
    acceptableFileTypes: '.zip',
  },
  { 
    value: 'csv',
    label: 'CSV', 
    acceptableFileTypes: '.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    message: 'Accepts CSV files, Excel spreadsheets, Google Sheets, and other compatible formats'
  },
  { 
    value: 'geojson',
    label: 'GeoJSON', 
    acceptableFileTypes: '.geojson, .json',
  },
  { 
    value: 'kml',
    label: 'Google Earth KML', 
    acceptableFileTypes: '.kml',
  },
]
const defaultMenuItem = dropDownMenuItems[0]

function SimpleDialog({ onClose, layerLabel, layerId }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const user = useSelector(state => state.user)
  const [fetching, setFetching] = useState(false)
  const [message, setMessage] = useState(false)
  const [open, setOpen] = useState(true)
  const [fetchParams, setFetchParams] = useState(null)
  
  const client = user.accounts[0].id
  const uuid = utils.generateUUID()
  const dirName = `client_data/${client}/s32shp2pgsql/${uuid}`
  
  const [selectedMenuItem, setSelectedMenuItem] = useState(dropDownMenuItems[0])
  const handleJoin = (data) => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + (selectedMenuItem.value === 'csv-edit-import' ? 'layer/csv-export-edit-import' : 'layer/import')
    const file = `public/${data.key}`
    const parts = file.split('/')
    const fileName = parts[parts.length - 1]
    const filePath = parts.slice(0, parts.length - 2).join('/')
    const body = {
      file,
      filePath,
      taskID: uuid,
      fileName,
      layerID: utils.extractLayerInt(layerId).toString(),
      append: true,
    }
    setFetching(true)
    setFetchParams([{ url, method, body }])
  }

  const handleImport = data => {
      handleJoin(data)
  }

  const fetchFinished = results => {
    setFetching(false)
    setFetchParams(null)
    const result = results[0]
    if (result && result.success) {
      setMessage(
        result.message +
          (result && result.data && result.data.total_changes
            ? ' Total Changes: ' + result.data.total_changes
            : '')
      )
      let style = mapStyle.toJS()
      let sourceId = style.layers
        .filter(layer => layer.id === layerId)
        .map(layer => layer.source)
      style.sources[sourceId[0]].tiles = style.sources[sourceId[0]].tiles.map(
        tileUrl => {
          let url = tileUrl.replace(/&dt.*$/, '')
          return url + `&dt=${Date.now()}`
        }
      )
      dispatch(updateMapStyle(fromJS(style)))
    }
  }

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }
  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {fetchParams && (
          <AsyncFetch
            fetchObjects={fetchParams}
            fetchFinished={fetchFinished}
          />
        )}
        <Dialog
          className={classes.root}
          onClose={handleOnClose}
          aria-labelledby='delete-layer-dialog'
          open={open}
          className={modalScss.MuiDialogBoxes}
        >
          <DialogTitle id='delete-layer-title'>
            Import {layerLabel}
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent className={modalScss.MuiDialogBoxes}>
            {fetching ? (
              <CircularProgress size={10} />
            ) : (
              <>
                <span style={{ display: 'block', marginBottom: '16px' }}>
                  <ReactSelect
                    options={dropDownMenuItems}
                    onChange={(trash, item) => setSelectedMenuItem(item)}
                    defaultValue={defaultMenuItem}
                    isFixed
                  />
                </span>
                
                <DropZone
                  uuid={false}
                  maxSize={100000000}
                  maxFiles={1}
                  dirName={dirName}
                  handleUploadResponse={handleImport}
                  fileType={selectedMenuItem.acceptableFileTypes}
                  message={selectedMenuItem.message ? selectedMenuItem.message : null}
                  successButtonLabel='Import'
                />
              </>
            )}
            {message && (
              <div className={scss.message}>
                <div>{message}</div>
              </div>
            )}
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function TableJoin(props) {
  return <SimpleDialog {...props} />
}
