/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import Icon from '../../../components/Icon/Icon'
import scss from './SearchBarStyle.scss'

const SearchBar = ({
  accountID,
  rawData,
  setS3Contents,
  setShowSearchResults,
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        setShowSearchResults(true)
        // cleans the path

        rawData = rawData
          .map(item => {
            item.key = item.key.replace(
              'client_data/' + accountID + '/documents/',
              ''
            )
            return item
          })
          .filter(item => item.key !== '')

        // Obtains the last element from the file/folder path
        rawData = rawData.map(item => {
          const name = item.key.split('/').filter(itemName => itemName !== '')
          item.keyVal = name[name.length - 1]
          return item
        })

        // Performs search on the array
        rawData = rawData
          .map(item => {
            if (item.keyVal.toLowerCase().includes(searchTerm.toLowerCase())) {
              item.awsPath =
                'client_data/' + accountID + '/documents/' + item.key
              return item
            }

            return null
          })
          .filter(item => item !== null)

        console.log(rawData)
        setS3Contents(rawData)
      }
    }, 1000)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  return (
    <span className={scss.searchBarContainer}>
      <Icon
        className={scss.search}
        icon='search'
        color='#404040'
        style={{
          marginLeft: '3%',
        }}
      />
      <input
        className={scss.searchBar}
        type='text'
        placeholder='Search in documents..'
        onChange={e => {
          setSearchTerm(e.target.value)
        }}
        style={{ border: 'none' }}
      />
    </span>
  )
}

export default SearchBar
