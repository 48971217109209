import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FixedSizeList as List } from 'react-window'
import { apis } from '../../../../../config/apiConfig'
import { clone } from '../../../../../utilities/geospatial'
import { getDefaultLevelPaintObj } from '../LayerStyle.jsx'
import AsyncFetch from '../../../../../utilities/AsyncFetch'
import scss from './DataLevels.scss'
import AttributeSelector from './AttributeSelector'
import Row from './Row'
import { Alert } from '../../../../../actions'
import Icon from '../../../../../components/Icon/Icon'
const StyleByAttribute = ({
  layerId,
  layerType,
  defObj,
  updateLayer,
  chainedUpdate,
  layerPaintProps,
  outLinePaintProps,
  defaultPaint,
}) => {
  const dispatch = useDispatch()
  const [keysArray, setKeysArray] = useState(null)
  const [filterValue, setFilterValue] = useState('')
  const [selectedAttribute, setSelectedAttribute] = useState(null)
  const [attributeChanged, setAttributeChanged] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(true)
  const [finalStateArray, setFinalStateArray] = useState(null)

  const handleFilterChange = e => {
    setFilterValue(e.target.value)
  }

  const updateSelectedAttribute = value => setSelectedAttribute(value)

  const fetchFinished = results => {
    let initialArray = []
    let finalOptionsArray = []

    if (selectedAttribute.value && selectedAttribute.value.length) {
      selectedAttribute.value.forEach(val => initialArray.push(val))
    }

    results.forEach(res => {
      if (res && res.data && res.data.length) {
        res.data.forEach(valObj => {
          finalOptionsArray.push(valObj)
        })
      }
    })

    initialArray.forEach(val => {
      finalOptionsArray = utils.addVal(finalOptionsArray, val)
    })

    finalOptionsArray = finalOptionsArray.filter(option => option !== null)

    setKeysArray(finalOptionsArray)
    setFinalStateArray(finalOptionsArray)
    setFetchObjects(null)
    setFetching(false)
  }

  const checkIfExpression = paintProps => {
    if (Array.isArray(paintProps)) return true
    return false
  }

  const checkIfNewExpression = paintProps => {
    const propArray = paintProps[1]
    if (propArray[1][1] === selectedAttribute) return false
    return true
  }

  const buildNewExpression = (
    attributeValue,
    paintPropertyValue,
    expressionFallbackValue
  ) => {
    const propExpression = ['==', ['get', selectedAttribute], attributeValue]
    const paintExpression = [
      'case',
      propExpression,
      paintPropertyValue,
      expressionFallbackValue,
    ]
    return paintExpression
  }

  const editExistingExpression = (
    paintExpression,
    attributeValue,
    paintPropertyValue
  ) => {
    let expressionUpdated = false
    for (let i = 0; i < paintExpression.length; i++) {
      if (
        Array.isArray(paintExpression[i]) &&
        paintExpression[i][2] === attributeValue
      ) {
        paintExpression[i + 1] = paintPropertyValue
        expressionUpdated = true
      }
    }

    if (!expressionUpdated) {
      // current case does NOT exist in expression, create new case
      const propExpression = ['==', ['get', selectedAttribute], attributeValue]
      paintExpression.splice(paintExpression.length - 1, 0, propExpression)
      paintExpression.splice(paintExpression.length - 1, 0, paintPropertyValue)
    }

    return paintExpression
  }

  const handleUpdate = (
    layerId,
    targetPaintProp,
    paintPropertyValue,
    attributeValue,
    id
  ) => {
    if (attributeValue === 'default') {
      handleUpdateDefaults(
        targetPaintProp,
        paintPropertyValue,
        id,
        attributeValue
      )
    } else {
      const paintProps = clone(layerPaintProps)
      const outlineProps = clone(outLinePaintProps)
      const defaultObject = clone(
        getDefaultLevelPaintObj(layerPaintProps, outLinePaintProps)
      )
      const paint = {
        ...paintProps,
        ...outlineProps,
      }

      const expressionFallbackValue = defaultPaint[targetPaintProp]
      const isExpression = checkIfExpression(paint[targetPaintProp])
      let paintExpression = null

      if (isExpression) {
        const isNewExpression = checkIfNewExpression(paint[targetPaintProp])
        
        if (isNewExpression) {
          paintExpression = buildNewExpression(
            attributeValue,
            paintPropertyValue,
            expressionFallbackValue
          )
        } else {
          paintExpression = editExistingExpression(
            paint[targetPaintProp],
            attributeValue,
            paintPropertyValue,
            expressionFallbackValue
          )
        }
      } else {
        paintExpression = buildNewExpression(
          attributeValue,
          paintPropertyValue,
          expressionFallbackValue
        )
      }

      updateLayer(layerId, targetPaintProp, paintExpression, null, null)
      return
    }
  }

  const handleCleanUp = targetCase => {
    let paintProps = clone(layerPaintProps)
    let outlineProps = clone(outLinePaintProps)
    let defaultProps = clone(
      getDefaultLevelPaintObj(layerPaintProps, outLinePaintProps)
    )

    if (targetCase) {
      for (let [key, val] of Object.entries(paintProps)) {
        if (Array.isArray(val)) {
          for (let i = 1; i < val.length; i += 2) {
            if (val[i][2] === targetCase) {
              paintProps[key].splice(i, 2)
            }
          }
        }
      }

      for (let [key, val] of Object.entries(paintProps)) {
        if (Array.isArray(val) && val.length < 4) {
          paintProps[key] = defaultProps.paint[key]
        }
      }

      if (outlineProps) {
        for (let [key, val] of Object.entries(outlineProps)) {
          if (Array.isArray(val)) {
            for (let i = 1; i < val.length; i += 2) {
              if (val[i][2] === targetCase) {
                outlineProps[key].splice(i, 2)
              }
            }
          }
        }

        for (let [key, val] of Object.entries(outlineProps)) {
          if (Array.isArray(val) && val.length < 4) {
            outlineProps[key] = defaultProps.outlinePaint[key]
          }
        }
      }

      // chained update
      chainedUpdate(layerId, paintProps, outlineProps)
    } else dispatch(Alert({ error: 'ERR:::No case has been specified' }))
  }

  useEffect(() => {
    if (selectedAttribute) {
      let key

      selectedAttribute.key
        ? (key = selectedAttribute.key)
        : (key = selectedAttribute)

      const url = apis['apiDatabase'].uri + 'layer/properties/unique'
      const method = 'POST'
      const body = {
        layerID: layerId,
        key,
      }

      setFetching(true)
      setFetchObjects([{ url, method, body, paginate: 100000 }])
    }
    setAttributeChanged(true)
  }, [selectedAttribute])

  useEffect(() => {
    let passFilter = []
    const search = filterValue.toLowerCase()

    finalStateArray &&
      finalStateArray.forEach(key => {
        let k = key.toLowerCase()
        if (k.indexOf(search) !== -1) passFilter.push(key)
      })

    setKeysArray(passFilter)

    if (filterValue === '') {
      setKeysArray(finalStateArray)
    }
  }, [filterValue])

  useEffect(() => {
    let targetAttribute
    // * check for case expressions
    for (const [key, val] of Object.entries(layerPaintProps)) {
      if (Array.isArray(val) && val[0] === 'case') {
        targetAttribute = val[1][1][1]
      }
    }

    if (outLinePaintProps) {
      for (const [key, val] of Object.entries(outLinePaintProps)) {
        if (Array.isArray(val) && val[0] === 'case') {
          targetAttribute = val[1][1][1]
        }
      }
    }
    if (targetAttribute) setSelectedAttribute(targetAttribute)
  }, [])

  if (selectedAttribute) {
    if (Array.isArray(selectedAttribute.value)) {
      selectedAttribute.value.forEach(val => attributeArray.push(val))
    }
  }

  const maxListSize = 2000
  const listItems = keysArray
    ? keysArray.slice(0, maxListSize).map(i => i)
    : null
  const itemData = {
    layerId,
    layerPaintProps,
    layerType,
    handleUpdate,
    outLinePaintProps,
    listItems,
    defObj,
    handleCleanUp,
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}

  

      <div className={scss.attributeSelector}>
        {' '}
        <label>Attribute</label>
        <div className={scss.attributeSelectorSelect}>
          <AttributeSelector
            layerId={layerId}
            updateSelectedAttribute={updateSelectedAttribute}
            selectedAttribute={selectedAttribute}
          />

          <Icon
            icon='chevron-down'
            size='1x'
            pull='right'
            className={scss.attributeSelectorIcon}
          />
        </div>
      </div>

      <div className={scss.attributeContainer} key={'data-driven_'}>
        <div className={scss.attributeSearch}>
          {/* Attribute search bar */}
          <Icon
            icon={['far', 'search']}
            size='1x'
            pull='left'
            className={scss.attributeSearchIcon}
          />
          <input
            placeholder='Search attribute values'
            value={filterValue}
            onChange={handleFilterChange}
          />
        </div>

        {listItems && !fetching ? (
          listItems.length ? (
            <List
              height={150}
              itemCount={listItems.length}
              itemSize={75}
              className={scss.attributeContainerRow}
              style={''}
              itemData={{ itemData }}
            >
              {Row}
            </List>
          ) : (
            ''
          )
        ) : (
          <Icon icon='spinner' size='1x' fixedWidth spin />
        )}
      </div>
    </>
  )
}

export default StyleByAttribute
