import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ParentGroupSelect from '../../ParentGroupSelect/ParentGroupSelect'
import Icon from '../../../../components/Icon/Icon'

import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'

import MakeDataConfigComponent from '../../../../utilities/dataConfig'
import * as utils from '../../../../utilities/util'
import ShareModal from '../../ShareModal/ShareModal'

import scss from './GroupInfo.scss'

import appScss from '../../../App/App.scss'

import { getLayerConfigUsingTocId } from '../../../../utilities/dataConfig'

import organizationLayerGroup from '../../../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../../../assets/icons/userLayerGroup.svg'
import logoSimplified from '../../../../assets/logo/logo-simplified.svg'

import GroupDelete from './GroupDelete'

import mainPanelTheme from '../../../../utilities/componentConstants/mainPanelTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { mountLayerSettings, setToolConfig } from '../../../../actions'

export default function GroupInfo({ closeTool, config, mountKey, dataConfig }) {
  const toolConfig = useSelector(state => state.toolConfig)
  const dispatch = useDispatch()

  const groupId = config.group.id
  var owner = config.group.owner
  var role = config.group.role
  var defaultRole = 'Viewer'

  const sharedType = config.group.type
  const canEdit = config.group.canEdit

  const [parentGroupSelectKey, setParentGroupSelectKey] = useState(1)

  const label = config.group.label || 'No Label Available'
  const id = config.group.groupId

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [groupDeleteVisible, setGroupDeleteVisible] = useState(false)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [groupLabel, setGroupLabel] = useState(config.group.label)
  const [groupDescription, setGroupDescription] = useState(
    config.group.description
  )
  const [shareGroupVisible, setShareGroup] = useState(false)

  useEffect(() => {
    // group has changed, update parentGroupSelectKey to force remount
    setParentGroupSelectKey(parentGroupSelectKey + 1)
  }, [config.group.groupId])

  const onLabelChange = e => {
    setGroupLabel(e.target.value)
  }

  const onDescriptionChange = e => {
    setGroupDescription(e.target.value)
  }

  const openShareGroup = shareGroupVisible => setShareGroup(shareGroupVisible)

  const buildFetchParams = id => {
    const groupId = config.group.id
    let method
    let url
    let body = {}

    if (id === 'archive') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'layers/user/delete'
      body = {
        layerID: groupId,
      }
    } else if (id === 'save') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'group/update'
      body = {
        groupID: groupId,
        label: groupLabel,
        description: groupDescription,
      }
    }
    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    // archive response
    setMountMakeDataConfig(true)
    closeTool(config.name)

    setFetchObjects(null)
  }

  const doArchive = () => {
    buildFetchParams('archive')
  }

  const doSave = () => {
    buildFetchParams('save')
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  const handleOnFinish = dataConfig => {
    setFetching(false)
    updateToolConfig(dataConfig)
  }

  let groupStyle = { width: '20px' }
  let groupShare = ''

  if (sharedType === 'user') {
    groupShare = userLayerGroup
    groupStyle = { width: '20px', marginLeft: '-6px' }
  } else if (sharedType === 'shared') {
    groupShare = sharedLayerGroup
  } else if (sharedType === 'org') {
    groupShare = organizationLayerGroup
    groupStyle = { width: '20px', marginLeft: '-3px' }
  } else if (sharedType === 'mam') {
    owner = 'MyAssetMap'
    groupShare = logoSimplified
    groupStyle = { width: '20px', marginBottom: '5px' }
  } else {
    groupShare = logoSimplified
    groupStyle = { width: '20px', marginBottom: '5px' }
  }

  let groupIdForDisplay = groupId.split('_')
  groupIdForDisplay = groupIdForDisplay[groupIdForDisplay.length - 1]

  const updateToolConfig = dataConfig => {
    // the data for the layer has changed (a new description or layer name)
    // the purpose of this function is to update app state data config with the new layer information
    // and remount the component to preserve the changes
    const updatedLayer =
      config && getLayerConfigUsingTocId(dataConfig, config.group.id)

    // add the newly updated layer to the toolConfig
    if (updatedLayer) {
      toolConfig.forEach(config => {
        if (config.name === 'LayerSettings') {
          config.layer = updatedLayer
        }
      })
      dispatch(setToolConfig(toolConfig))
      // force the component to remount by updating the mount key
      mountKey++
      dispatch(mountLayerSettings(mountKey))
    }
  }

  const getGroupInfo = () => {
    const parentGroupSelect = getParentGroupSelect()

    return (
      <>
        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}

        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={handleOnFinish} />
        )}
        <div
          className={[
            scss.container,
            scss['panel-column'],
            scss['panel-justify-space-between'],
            scss['padding-zero'],
            scss['full-height'],
          ].join(' ')}
        >
          <div
            className={[scss['panel-column'], scss['panel-share']].join(' ')}
          >
            <span className={scss['panel-colon-label']}>
              Shared by:{' '}
              <span className={scss['panel-colon-text']}>{owner}</span>
            </span>
            <span className={scss['panel-colon-label']}>
              Your Permissions:{' '}
              <span className={scss['panel-colon-text']}>
                {utils.getRole(role, defaultRole)}
              </span>
            </span>
          </div>

          {groupDeleteVisible && (
            <GroupDelete
              onClose={() => setGroupDeleteVisible(false)}
              groupLabel={label}
              groupId={id}
              groupConfig={config}
            />
          )}
          {shareGroupVisible && (
            <ShareModal
              onClose={() => openShareGroup(false)}
              itemId={id}
              dataType={'group'}
            />
          )}
          {!canEdit && (
            <div
              className={[scss['panel-column'], scss['panel-description']].join(
                ' '
              )}
            >
              <span className={scss['panel-colon-label']}>
                Name:{' '}
                <span className={scss['panel-colon-text']}>{groupLabel}</span>
              </span>
              {owner === 'MyAssetMap' ? null : (
                <span className={scss['panel-colon-label']}>
                  Parent:{' '}
                  <span className={scss['panel-colon-text']}>
                    {parentGroupSelect}
                  </span>
                </span>
              )}
              <span className={scss['panel-colon-label']}>
                Description:{' '}
                <span className={scss['panel-colon-text']}>
                  {groupDescription}
                </span>
              </span>
            </div>
          )}
          <div>
            {canEdit && (
              <>
                <div
                  className={[
                    scss['panel-column'],
                    scss['panel-edit-description'],
                  ].join(' ')}
                >
                  <span className={scss['panel-edit-description-title']}>
                    Group Info
                  </span>

                  <label
                    htmlFor='group-settings-group-name'
                    className={appScss['input-notched']}
                  >
                    <span>Group Name</span>
                    <input
                      id='group-settings-group-name'
                      onChange={onLabelChange}
                      value={groupLabel}
                      placeholder='Input text'
                    />
                  </label>

                  <label
                    htmlFor='group-settings-group-description'
                    className={appScss['input-notched']}
                  >
                    <span>Description</span>
                    <textarea
                      id='group-settings-group-description'
                      onChange={onDescriptionChange}
                      value={groupDescription}
                      placeholder='Input text'
                    />
                  </label>
                </div>
                <span className={scss['parent-group-description-title']}>
                  Parent group
                </span>
              </>
            )}
            {owner === 'MyAssetMap' ? null : (
              <div className={[scss['panel-group-select']].join(' ')}>
                <ParentGroupSelect
                  key={mountKey + '_pgs'}
                  config={config}
                  dataConfig={dataConfig}
                />
              </div>
            )}
          </div>

          <div className={scss.groupInfoSave}>
            <button
              className={[
                appScss.fullWidthButton,
                scss.groupInfoSave,
                scss[!canEdit ? 'groupInfoSaveInactive' : ''],
              ].join(' ')}
              onClick={() => !fetching && doSave()}
            >
              {fetching ? (
                <Icon icon={'spinner'} size='1x' spin />
              ) : (
                'Save'
              )}
            </button>
          </div>
        </div>
      </>
    )
  }

  const getParentGroupSelect = () => {
    return (
      <ParentGroupSelect
        key={parentGroupSelectKey}
        config={config}
        dataConfig={dataConfig}
      />
    )
  }

  let ui = getGroupInfo()
  return <ThemeProvider theme={mainPanelTheme}>{ui}</ThemeProvider>
}
