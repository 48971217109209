// ======================
// Button Utilities
// ======================
export const buttonBase =
  'flex items-center justify-center cursor-pointer transition-all duration-300'

// Map Control Buttons
export const mapControlButton =
  'w-[44px] h-[44px] rounded-[12px] border border-[#314d68] shadow-[4px_4px_12px_0_rgba(0,0,0,0.28)]'
export const mapControlButtonColors = {
  default: 'bg-[#1a2937] text-white hover:bg-[#314d68]',
  active: 'bg-[#314d68] text-accent',
}
export const mapControlWrapper = 'bg-transparent'
export const mapControlContainer = 'flex flex-col gap-4'

// Navigation Control Wrapper
export const mapNavigationWrapper = 'relative mb-8' // Added margin bottom to prevent overlap

// Other Button Variants
export const primaryButton =
  'bg-[#337ab7] text-white border-[#2e6da4] font-normal text-sm'
export const dangerButton =
  'bg-[#d9534f] text-white border-[#d43f3a] font-normal text-sm'
export const toolHeaderButton = 'bg-white flex justify-center w-[34px] h-[34px]'

// Button States
export const disabledButton = 'bg-disabled'
export const activeButton = 'bg-lightBlue transition-colors duration-500'

// Button Groups
export const buttonGroup = 'flex'
export const buttonGroupFirst = 'rounded-l'
export const buttonGroupLast = 'rounded-r border-l-0'

// ======================
// Layout Utilities
// ======================

// Map Controls Layout
export const mapControlsContainer =
  'absolute top-5 right-7 overflow-x-hidden overflow-y-auto z-[5000]'
export const mapControlsWrapper = 'flex flex-col gap-y-4' // Base spacing between all control groups

// Control Groups
export const mapButtonDouble = 'flex flex-col gap-0.5' // For paired buttons with specific small gap
export const mapNavButtons = 'mt-4' // Spacing for navigation button group
export const mapZoomControls = 'mt-4' // Spacing for zoom/compass controls
export const mapClearButtons = 'mt-4' // Spacing for clear buttons group
export const mapTopRightTools = 'flex flex-col gap-4' // Feature select, draw, measure tools
export const mapFeedbackSection = 'mt-4' // Spacing for feedback button

// ======================
// Icon Utilities
// ======================
export const mapControlIcon = 'w-6 h-6' // 24x24px icons

/** Removes the default tooltips from the navigation controls, so we can use our own tooltips */
export const mapNavigationControls = `
  [&_.mapboxgl-ctrl-zoom-in]:before:!content-none 
  [&_.mapboxgl-ctrl-zoom-out]:before:!content-none 
  [&_.mapboxgl-ctrl-zoom-in]:after:!content-none 
  [&_.mapboxgl-ctrl-zoom-out]:after:!content-none 
  [&_.mapboxgl-ctrl-compass]:before:!content-none 
  [&_.mapboxgl-ctrl-compass]:after:!content-none 
  [&_.mapboxgl-ctrl-group]:!tooltip-none
`
