import React from 'react'
import Icon from '@/components/Icon/Icon'
import { setToolConfig } from '@/actions/index'
import { clone } from '@/utilities/geospatial'
import { useDispatch, useSelector } from 'react-redux'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
  mapControlWrapper,
} from '@/styles/tailwind-utilities'

const DrawBtn = () => {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)

  const handleDraw = () => {
    toggleTool('Draw')
  }

  const toggleTool = name => {
    let config = clone(toolConfig)

    config.forEach(tool => {
      if (tool.name === name) {
        tool.visible = !tool.visible
      }
    })
    dispatch(setToolConfig(config))
  }

  return (
    <div className={mapControlWrapper}>
      <button
        className={`${buttonBase} ${mapControlButton} ${
          toolConfig.find(tool => tool.name === 'Draw')?.visible
            ? mapControlButtonColors.active
            : mapControlButtonColors.default
        }`}
        onClick={handleDraw}
      >
        <Icon icon={['fa', 'pen-nib']} size='xl' />
      </button>
    </div>
  )
}

export default DrawBtn
