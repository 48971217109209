import React from 'react'
import Icon from '../Icon/Icon'
import scss from './LoadingDiv.scss'

export default function LoadingDiv({}) {
  return (
    <div className={scss.container}>
      <Icon icon='spinner' size='lg' fixedWidth spin />
    </div>
  )
}
