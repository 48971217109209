/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import Icon from '../../../../../components/Icon/Icon'
import * as DocumentBrowserUtil from '../../../../DocumentBrowserV2/DocumentBrowserV2Util'
import scss from './AttributeRow.scss'

const AttributeRow = ({
  row,
  setDisplayEditAttributeTab,
  setTargetProperty,
  setDisplayDeleteAttributePopup,
  setDisplayManageAttributes,
}) => {
  const [displayOptions, setDisplayOptions] = useState('none')

  return (
    <tr
      key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
      {...row.getRowProps()}
      onMouseEnter={() => {
        setDisplayOptions('block')
      }}
      onMouseLeave={() => {
        setDisplayOptions('none')
      }}
    >
      {row.cells.map(cell => {
        return (
          <td
            key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(99)}
            {...cell.getCellProps()}
          >
            {cell.render('Cell')}
          </td>
        )
      })}
      <td
        style={{
          borderTop: '0.5px solid #858f98',
          width: displayOptions ? '25px' : '',
        }}
      >
        <Icon
          icon='trash-alt'
          title='Delete Feature Attribute'
          className={scss.editPencilIcon}
          style={{
            display: displayOptions || '',
          }}
          onClick={() => {
            setTargetProperty({
              name: row.original.name || '',
              attrKey: row.original.attrKey || '',
              type: row.original.type || '',
              value: row.original.value || '',
              default: row.original.default || '',
            })
            setDisplayDeleteAttributePopup(true)
          }}
        />
      </td>
      <td
        style={{
          borderTop: '0.5px solid #858f98',
          width: displayOptions ? '34px' : '',
        }}
      >
        <Icon
          icon='pencil'
          title='Edit Feature Attribute'
          className={scss.editPencilIcon}
          style={{
            display: displayOptions || '',
          }}
          onClick={() => {
            setDisplayManageAttributes(false)
            setTargetProperty({
              name: row.original.name || '',
              attrKey: row.original.attrKey || '',
              type: row.original.type || '',
              value: row.original.value || '',
              default: row.original.default || '',
            })
            setDisplayEditAttributeTab(true)
          }}
        />
      </td>
    </tr>
  )
}

export default AttributeRow
