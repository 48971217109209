import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'
import { MuiButton } from '../../../components/MaterialUi/Dialog'
import { mountMapViews } from '../../../actions'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Icon from '../../../components/Icon/Icon'

import modalScss from '../../CSSModules/Modal.scss'
import editModalScss from '../../CSSModules/EditMapViewModal.scss'
import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ onClose, onFinish, mapViewId, mapViews, mountKey }) {
  const dispatch = useDispatch()
  const targetMapView = mapViews.filter(mapView => mapView.id == mapViewId)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [newKey, setNewKey] = useState(null)
  const [open, setOpen] = useState(true)
  const [text, setText] =
    targetMapView[0].notes && targetMapView[0].draftJS
      ? useState(
          EditorState.createWithContent(convertFromRaw(targetMapView[0].notes))
        )
      : useState(EditorState.createEmpty())

  const buildFetchParams = () => {
    // NOTE:  We trasnsitioned from Quill.js to Draft.js for Notes Editor,
    // Draft.js is built with immutable and will not work by using dangerouslySetInnerHTML
    // Existing Notes that were created with Quill are not compatible in the Draft.js Editor (used to render the notes)
    // To allow for previous notes saved with Quill to continue to work we have added a flag called draftJS that is set to true on save
    // Any notes without this flag will render using dangerouslySetInnerHTML
    const contentState = text.getCurrentContent()
    const newNotes = convertToRaw(contentState)
    setNewKey(mountKey)
    setNewKey(newKey + 1)

    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'mapview/update'
    let body = {
      mapViewID: mapViewId,
      draftJS: true,
      notes: newNotes,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    setFetching(false)
    dispatch(mountMapViews(newKey + 1))
    setFetchObjects(null)
    console.log('Finished.')
    onFinish()
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const handleChange = editorState => {
    setText(editorState)
  }
  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }
  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'link'], // toggled buttons

      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      [{ link: [] }],
      [{ image: [] }]['clean'],
    ],
  }

  const pEditTitle = {
    color: '#a0a5ac',
    fontSize: '12px',
    fontWeight: 600,
  }

  const styles = theme => ({ root: { overflow: 'visible' } })

  return (
    // <ThemeProvider theme={notePanelTheme}>
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='delete-mapview-dialog'
        open={open}
        classes={{ root: { overflow: 'visible' } }}
        maxWidth='md'
      >
        <div className={modalScss.modalHeader}>
          <DialogTitle id='delete-mapview-title'>
            Edit Notes: {targetMapView[0].label}
          </DialogTitle>
          <div>
            <button onClick={handleOnClose} className={modalScss.modalX}>
              <Icon icon='times' size='1x' />
            </button>
          </div>
        </div>
        <DialogContent className={styles.root}>
          <p style={pEditTitle}>TEXT</p>
          <Editor
            editorState={text}
            wrapperClassName={editModalScss.editorWrapper}
            editorClassName={editModalScss.editorContent}
            toolbarClassName={editModalScss.editorToolbar}
            toolbar={{
              options: [
                'fontFamily',
                'fontSize',
                'colorPicker',
                'inline',
                'textAlign',
                'list',
                'image',
                'link',
              ],
              fontFamily: {
                options: [
                  'Arial',
                  'Georgia',
                  'Impact',
                  'Tahoma',
                  'Times New Roman',
                  'Verdana',
                ],
                className: appScss.mapViewFontFamily,
                component: undefined,
                dropdownClassName: undefined,
              },
              fontSize: {
                options: [
                  8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
                ],
                className: appScss.mapViewFontSize,
                component: undefined,
                dropdownClassName: undefined,
              },
              inline: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['bold', 'underline', 'italic'],
                bold: { className: 'who' },
                italic: { className: undefined },
                underline: { className: undefined },
                strikethrough: { className: undefined },
                monospace: { className: undefined },
                superscript: { className: undefined },
                subscript: { className: undefined },
              },
              list: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['ordered', 'unordered'],
                unordered: { className: undefined },
                ordered: { className: undefined },
                indent: { className: undefined },
                outdent: { className: undefined },
              },
              textAlign: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                dropdownClassName: undefined,
                options: ['left', 'center', 'right'],
                left: { className: undefined },
                center: { className: undefined },
                right: { className: undefined },
                justify: { className: undefined },
              },
              colorPicker: {
                className: undefined,
                component: undefined,
                popupClassName: editModalScss.mapViewPopup,
                colors: [
                  'rgb(97,189,109)',
                  'rgb(26,188,156)',
                  'rgb(84,172,210)',
                  'rgb(44,130,201)',
                  'rgb(147,101,184)',
                  'rgb(71,85,119)',
                  'rgb(204,204,204)',
                  'rgb(65,168,95)',
                  'rgb(0,168,133)',
                  'rgb(61,142,185)',
                  'rgb(41,105,176)',
                  'rgb(85,57,130)',
                  'rgb(40,50,78)',
                  'rgb(0,0,0)',
                  'rgb(247,218,100)',
                  'rgb(251,160,38)',
                  'rgb(235,107,86)',
                  'rgb(226,80,65)',
                  'rgb(163,143,132)',
                  'rgb(239,239,239)',
                  'rgb(255,255,255)',
                  'rgb(250,197,28)',
                  'rgb(243,121,52)',
                  'rgb(209,72,65)',
                  'rgb(184,49,47)',
                  'rgb(124,112,107)',
                  'rgb(209,213,216)',
                ],
              },
              link: {
                inDropdown: false,
                className: undefined,
                component: undefined,
                popupClassName: editModalScss.mapViewPopup,
                dropdownClassName: undefined,
                showOpenOptionOnHover: true,
                defaultTargetOption: '_self',
                options: ['link', 'unlink'],
                link: { className: undefined },
                unlink: { className: undefined },
                linkCallback: undefined,
              },
              image: {
                className: undefined,
                component: undefined,
                popupClassName: editModalScss.mapViewPopup,
                urlEnabled: true,
                uploadEnabled: true,
                alignmentEnabled: true,
                uploadCallback: undefined,
                previewImage: false,
                inputAccept:
                  'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                alt: { present: false, mandatory: false },
                defaultSize: {
                  height: 'auto',
                  width: 'auto',
                },
              },
              remove: { className: undefined, component: undefined },
            }}
            // toolbar={{
            //   bold: { inDropdown: true },
            //   italic: { inDropdown: false },
            //   textAlign: { inDropdown: true },
            //   link: { inDropdown: true },
            //   history: { inDropdown: false },
            // }}
            // wrapperClassName="demo-wrapper"
            // editorClassName="editer-content"
            onEditorStateChange={handleChange}
          />
        </DialogContent>

        <DialogActions>
          {/* <MuiButton
            color="primary"
            variant="outlined"
            onClick={fetching ? null : handleSave}
            label={fetching ? <CircularProgress size={10} /> : "Save"}
            size="large"
          />
          <MuiButton
            color="primary"
            variant="outlined"
            onClick={handleCancel}
            label="Cancel"
            size="large"
          /> */}

          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>
          <button
            className={appScss.blueButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? <CircularProgress size={10} /> : 'Save'}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function EditMapView(props) {
  return <SimpleDialog {...props} />
}
