import React, { useState, useEffect } from 'react'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'
import { useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import Grid from '@material-ui/core/Grid'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import {
  MuiButton,
  MuiFormControlText,
} from '../../../components/MaterialUi/Dialog'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import Icon from '../../../components/Icon/Icon'

import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      width: 350,
    },
  },
}))

const validateLayerName = layerName => {
  if (layerName === '') return false
  return true
}

function SimpleDialog({ onClose, mountKey }) {
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [newKey, setNewKey] = useState(0)
  const [open, setOpen] = useState(true)
  const [description, setDescription] = useState('')

  const onNameChange = e => {
    setGroupName(e.target.value)
  }

  const onDescChange = e => {
    setDescription(e.target.value)
  }

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'mapview/group/add'
    let body = {
      label: groupName,
      description: description,
    }

    setFetchObjects([{ url, method, body }])
    // console.log('fetchObjects', fetchObjects)
    setFetching(true)
    setNewKey(mountKey + 1)
  }

  const handleClick = () => {
    buildFetchParams()
  }

  const fetchFinished = results => {
    results.forEach(result => {
      // console.log('Fetch Successful - data: ', result.data)
    })

    setTimeout(() => {
      handleOnClose()
      // calls handleOnClose after 500ms timeout
      // to allow time to refresh mapview list
    }, 500)
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  const handleCancel = () => {
    setOpen(false)
    onClose()
  }

  const handleOnClose = () => {
    setFetchObjects(null)
    setFetching(null)

    setOpen(false)
    onClose(newKey)
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}

      <ThemeProvider theme={mainModalTheme}>
        {/* {mountMakeDataConfig && <MakeDataConfigComponent onFinish={handleOnFinish}/>} */}
        <div className={classes.root}>
          <Dialog className={classes.root} onClose={handleOnClose} open={open}>
            <DialogTitle>
              New map view group
              <Icon
                onClick={handleOnClose}
                icon='times'
                size='sm'
                pull='right'
              />
            </DialogTitle>

            <DialogContent className={modalScss.MuiDialogBoxes}>
              <label
                htmlFor='new-map-view-group-name'
                className={appScss['input-notched']}
              >
                <span>Group Name</span>
                <input
                  id='new-map-view-group-name'
                  onChange={onNameChange}
                  onSubmit={fetching ? null : handleClick}
                  value={groupName}
                  placeholder='Input text'
                />
              </label>

              <label
                htmlFor='new-map-view-group-description'
                className={appScss['input-notched']}
                style={{ marginTop: '16px' }}
              >
                <span>Description</span>
                <textarea
                  id='new-map-view-group-description'
                  onChange={onDescChange}
                  onSubmit={fetching ? null : handleClick}
                  value={description}
                  placeholder='Input text'
                />
              </label>
            </DialogContent>

            <DialogActions>
              <button className={appScss.altBlueButton} onClick={handleCancel}>
                Cancel
              </button>

              <button
                className={appScss.blueButton}
                onClick={fetching ? null : handleClick}
              >
                {fetching ? (
                  <Icon icon='spinner' size='1x' fixedWidth spin />
                ) : (
                  'Save'
                )}
              </button>
            </DialogActions>
          </Dialog>
        </div>
      </ThemeProvider>
    </>
  )
}

export default function NewGroup(props) {
  return <SimpleDialog {...props} />
}
