import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Icon = ({ icon, size = '1x', ...props }) => (
  <FontAwesomeIcon icon={icon} size={size} {...props} />
)

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  spin: PropTypes.bool,
  pulse: PropTypes.bool,
  rotation: PropTypes.oneOf([0, 90, 180, 270]),
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
}

export default Icon
