import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useTranslation } from 'react-i18next'

import mainModalTheme from '../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import appScss from '../App/App.scss'
import AsyncFetch from '../../utilities/AsyncFetch'
import { apis } from '../../config/apiConfig'
import * as utils from '../../utilities/util'
import Icon from '../../components/Icon/Icon'

function SimpleDialog({ user, respond }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  const userState = useSelector(state => state.user)
  const accountID = userState.accountID

  useEffect(() => {
    setOpen(true)
  }, [user])

  const handleOnClose = () => {
    respond()
    setOpen(false)
  }

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        respond('reload')
      } else respond()
    })
    setFetchObjects(null)
    setOpen(false)
    setFetching(false)
  }

  const handleDelete = () => {
    const url = apis['apiDatabase'].uri + 'account/user/delete'
    const method = 'POST'
    const body = {
      userID: user.id,
      accountID: accountID,
    }

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <ThemeProvider theme={mainModalTheme}>
        <Dialog
          onClose={handleOnClose}
          open={open}
          aria-labelledby='delete-user-dialog'
          maxWidth='sm'
        >
          <DialogTitle id='delete-user-title'>
            {t('userDeleteModal.title')}
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent>
            <p>
              {t('userDeleteModal.message.removing')}{' '}
              <i>
                <b>
                  {user.displayName
                    ? user.displayName
                    : t('userDeleteModal.message.defaultName')}
                </b>
              </i>{' '}
              {t('userDeleteModal.message.fromAccount')}
            </p>
          </DialogContent>
          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleOnClose}>
              {t('userDeleteModal.buttons.cancel')}
            </button>
            <button
              className={appScss.blueButton}
              onClick={fetching ? null : handleDelete}
            >
              {fetching ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                t('userDeleteModal.buttons.remove')
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function UserDeleteModal(props) {
  return <SimpleDialog {...props} />
}
