import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as utils from '../../../utilities/util'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'
import { useTranslation } from 'react-i18next'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Skeleton from 'react-loading-skeleton'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'
import { MuiButton } from '../../../components/MaterialUi/Dialog'

import scss from '../Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import Icon from '../../../components/Icon/Icon'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ doUpdate, accounts, checkboxList, visible }) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  const [userIDState, setUserIDs] = useState([])
  const [accountIDState, setAccountIDs] = useState([])

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/user/activate'
    let body = {
      userID: userIDState,
      accountID: accountIDState,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = () => {
    setFetching(false)
    setFetchObjects(null)
    handleOnClose()

    doUpdate()
  }

  const handleSave = () => {
    buildFetchParams()
  }

  // ===================================
  // = PROCESS THE DATA INTO VARIABLES =
  // ===================================
  const processUserIDs = () => {
    var userIDs = []
    var accountIDs = []
    for (const key in checkboxList) {
      var check = checkboxList[key]

      let userID = check.user.id
      let accountID = check.account.id

      userIDs = utils.addVal(userIDs, userID)
      accountIDs = utils.addVal(accountIDs, accountID)
    }
    setUserIDs(userIDs)
    setAccountIDs(accountIDs)
  }

  useEffect(() => {
    processUserIDs()
  }, [])

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  // const getUser = (u) => {
  const getDisplay = (account, user) => {
    return (
      <li>
        {user.displayName} ({account.name})
      </li>
    )
  }

  var itemList = Object.values(checkboxList)

  var userAccountList
  if (!itemList.length) {
    userAccountList = <></>
  } else {
    userAccountList = itemList.map(a => getDisplay(a.account, a.user))
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-account-dialog'
        open={open}
        maxWidth='md'
      >
        <DialogTitle id='assign-account-dialog'>
          {t('profile.modals.activate.title')}
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.overflowY}>
          <p>{t('profile.modals.activate.confirm')}</p>
          <div style={{ padding: '18px' }}>
            <ul>{userAccountList}</ul>
          </div>
        </DialogContent>

        <DialogActions>
          <button className={modalScss.modalAltButton} onClick={handleOnClose}>
            {t('profile.modals.common.cancel')}
          </button>

          <button
            className={modalScss.modalMainButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? (
              <CircularProgress size={10} />
            ) : (
              t('profile.modals.activate.button')
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ActivateUser(props) {
  return <SimpleDialog {...props} />
}
