import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../components/Icon/Icon'
import { defaultToolConfig } from '../../config/toolConfig'
import SideNav from './Sidebar'
import HeaderButtons from './TocControls/HeaderButtons/HeaderButtons'
import LayerList from './LayerList/LayerList'
import MapSelector from './TocControls/MapSelector'
import LayerSearch from './LayerSearch/LayerSearch'
import MapViews from '../Tools/MapViews/MapViews'
import tocScss from '../CSSModules/GlobalToc.scss'
import appScss from '../App/App.scss'
import scss from './Toc.scss'
import { setMapMode, setToolConfig } from '../../actions/index'
import { clone } from '../../utilities/geospatial'

const Toc = React.memo(({ setSidebarOpen, mapMode, dataConfig }) => {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)
  const tocCollapsed = useSelector(state => state.tocCollapsed)
  const [currentPanel, setCurrentPanel] = useState('data')
  const [addMapViewLayer, setAddMapViewLayer] = useState(false)
  const [addMapViewGroup, setAddMapViewGroup] = useState(false)
  const [mapViewKey, setMapViewKey] = useState(1)

  const toggleMapMode = () => {
    const newMode = mapMode === 'MAP' ? 'PRINT' : 'MAP'
    dispatch(setMapMode(newMode))
    dispatch(setMapMode(newMode !== 'MAP'))
  }

  const toggleTool = name => {
    const config = clone(toolConfig)
    config.forEach(tool => {
      if (tool.name === name) {
        tool.visible = !tool.visible
      }
    })
    dispatch(setToolConfig(config))
  }

  useEffect(() => {
    if (dataConfig) {
      dispatch(setToolConfig(defaultToolConfig))
    }
  }, [dataConfig])

  return (
    <>
      <SideNav
        setSidebarOpen={setSidebarOpen}
        setActivePanel={setCurrentPanel}
        currentPanel={currentPanel}
        toolConfig={toolConfig}
        printAction={toggleMapMode}
        toggleTool={toggleTool}
      />
      {!tocCollapsed && (
        <nav className={tocScss.toc}>
          {currentPanel === 'data' && (
            <div
              className={[scss['toc-container'], scss['data-layers']].join(' ')}
            >
              <div
                className={[
                  scss['toc-header'],
                  scss['data-layers-header'],
                ].join(' ')}
              >
                <MapSelector />
                <HeaderButtons />
                <LayerSearch />
              </div>
              <div className={scss['data-layer-list']}>
                {toolConfig && <LayerList />}
              </div>
            </div>
          )}
          {currentPanel === 'mapviews' && (
            <div
              className={[tocScss['toc-container'], scss['map-views']].join(
                ' '
              )}
            >
              <div
                className={[scss['toc-header'], scss['map-views-header']].join(
                  ' '
                )}
              >
                <div
                  className={[
                    appScss['flex-row'],
                    appScss['flex-align-center'],
                    appScss['flex-justify-space-between'],
                  ].join(' ')}
                >
                  <button
                    type='button'
                    onClick={() => setAddMapViewLayer(true)}
                    className={scss['toc-header-button']}
                  >
                    New View
                    <Icon
                      className={scss['plus-icon']}
                      icon='plus'
                    />
                  </button>
                  <button
                    type='button'
                    onClick={() => setAddMapViewGroup(true)}
                    className={scss['toc-header-button']}
                  >
                    New Group
                    <Icon
                      className={scss['plus-icon']}
                      icon='plus'
                    />
                  </button>
                </div>
              </div>

              <div className={scss['toc-list']}>
                <MapViews
                  key={mapViewKey}
                  // mapZoom={viewport.zoom}
                  // mapStyle={mapStyle}
                  dataConfig={dataConfig}
                  // viewport={viewport}
                  addVisible={addMapViewLayer}
                  addGroup={addMapViewGroup}
                  onModalClose={newKey => {
                    setAddMapViewGroup(false)
                    setAddMapViewLayer(false)
                    setMapViewKey(newKey)
                  }}
                />
              </div>
            </div>
          )}
        </nav>
      )}
    </>
  )
})

Toc.propTypes = {
  setSidebarOpen: PropTypes.func.isRequired,
  mapMode: PropTypes.string,
  dataConfig: PropTypes.shape({}).isRequired,
}

export default Toc
