import React, { useState } from 'react'
import { connect } from 'react-redux'
import Icon from '../../../components/Icon/Icon'
import GeoCode from './GeoCode/GeoCode'
import Coordinate from './Coordinate/Coordinate'
import { Tooltip } from 'react-tooltip'

import appScss from '../../App/App.scss'
import scss from './Search.scss'

const Search = ({ mapStyle, viewport, disableMap, enableMap }) => {
  const [mode, setMode] = useState('GEOCODE')

  const toggleSearchMode = newMode => {
    setMode(newMode)
  }

  const getSearchUi = () => {
    const geoCodeActive = mode === 'GEOCODE' ? 'active' : ''
    const cordinateActive = mode === 'COORDINATE' ? 'active' : ''

    return (
      <div>
        <div
          className={[scss.activeSearchButtonsContainer, scss.addOnGroup].join(
            ' '
          )}
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
        >
          <button
            name='GEOCODE'
            onClick={() => toggleSearchMode('GEOCODE')}
            className={[scss.btn, scss[geoCodeActive]].join(' ')}
            data-tooltip-content='Address'
            data-tooltip-id='geocode'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fas', 'map-marked-alt']} size='sm' />
          </button>
          <div className={appScss.reactToolTip}>
            <Tooltip id='geocode' getContent={dataTip => `${dataTip}`} />
          </div>
          <button
            name='COORDINATE'
            onClick={() => toggleSearchMode('COORDINATE')}
            className={[scss.btn, scss[cordinateActive]].join(' ')}
            data-tooltip-content='Latitude and Longitude'
            data-tooltip-id='coordinate'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fal', 'globe']} size='sm' />
          </button>
          <div className={appScss.reactToolTip}>
            <Tooltip id='coordinate' getContent={dataTip => `${dataTip}`} />
          </div>
        </div>
        <div
          className={scss.mapTopControlGroup}
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
        >
          <div className={scss.activeSearchContainer}>
            {mode === 'GEOCODE' && (
              <GeoCode
                mapStyle={mapStyle}
                viewport={viewport}
                enableMap={enableMap}
                disableMap={disableMap}
              />
            )}
            {mode === 'COORDINATE' && (
              <Coordinate
                mapStyle={mapStyle}
                viewport={viewport}
                enableMap={enableMap}
                disableMap={disableMap}
              />
            )}
          </div>
        </div>
      </div>
    )
  }

  return getSearchUi()
}

function mapStateToProps(state) {
  return {
    mapStyle: state.mapStyle,
    viewport: state.viewport,
  }
}

export default connect(mapStateToProps)(Search)
