import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import * as utils from '../../../utilities/util'

import scss from '../Users.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import Icon from '../../../components/Icon/Icon'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  mapViewLabel,
  mapViewID,
  visible,
  setVisible,
  mapViewList,
  setMapViewList,
}) {
  const [open, setOpen] = useState(true)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [urlObjects, setUrlObjects] = useState(null)

  const handleDelete = () => {
    const isGroup = typeof mapViewID === 'string' && mapViewID.startsWith('group_')
    const method = 'POST'
    const url = apis['apiDatabase'].uri + (isGroup ? 'mapview/group/delete' : 'mapview/delete')
    const body = {}
    if (isGroup) {
      body.groupID = mapViewID
    } else {
      body.mapViewID = mapViewID
    }
    setFetching(true)
    setUrlObjects([{ url, body, method }])
  }
  const fetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        console.log(' availableLayersAPI result ', result)
        setMapViewList(
          mapViewList.filter(mapView => mapView && mapView.id !== mapViewID)
        )
        setMountMakeDataConfig(true)
      }
    })
  }

  const handleOnFinish = () => {
    setFetching(false)
    setFetchObjects(null)
    handleOnClose()
  }

  const handleCancel = () => {
    setOpen(false)
    setVisible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    setVisible(false)
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      setUrlObjects(null)
    },
    [urlObjects]
  )

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {mountMakeDataConfig && (
          <MakeDataConfigComponent
            onFinish={handleOnFinish}
            mapViewDeletedId={mapViewID}
          />
        )}
        {urlObjects && (
          <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
        )}
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='delete-mapview-dialog'
          open={open}
          maxWidth='sm'
        >
          <DialogTitle id='delete-mapview-title'>
            Delete Mapview
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent>
            <p>
              You are deleting{' '}
              <i>
                <b>{mapViewLabel}</b>
              </i>{' '}
              mapview. This action cannot be undone. Proceed?
            </p>
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
            <button
              className={appScss.blueButton}
              onClick={fetching ? null : handleDelete}
            >
              {fetching ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Delete'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function DeleteLayer(props) {
  return <SimpleDialog {...props} />
}
