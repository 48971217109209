/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../components/Icon/Icon'
import * as utils from '../../../utilities/util'

import { Alert } from '../../../actions'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'

import appScss from '../../App/App.scss'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

function SimpleDialog({ visible, mapLabel, mapID }) {
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [open, setOpen] = useState(true)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  // const handleSuccess = msg => dispatch(Alert({ success: msg }))
  // const handleError = error => dispatch(Alert({ error }))

  const buildFetchParams = () => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'map/delete'
    const body = {
      mapID,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = results => {
    return results.map(result => {
      if (utils.verifyResult(result)) {
        refreshDetails(user, newUser => {
          dispatch({ type: 'REFRESH', payload: newUser })
          setFetching(false)
          handleOnClose()
        })
      } else {
        setFetching(false)
      }

      setFetchObjects(null)
    })
  }

  const handleDelete = () => {
    buildFetchParams()
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='delete-layer-dialog'
          open={open}
        >
          <DialogTitle id='delete-layer-title'>
            Delete Map
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
              style={{ cursor: 'pointer' }}
            />
          </DialogTitle>
          <DialogContent>
            <p>
              Are you sure you want to permanently delete{' '}
              <i>
                <b>{mapLabel}</b>
              </i>
              ?
            </p>
          </DialogContent>

          <DialogActions>
            <button
              type='button'
              className={appScss.altBlueButton}
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              type='button'
              className={appScss.blueButton}
              onClick={fetching ? null : handleDelete}
            >
              {fetching ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Delete'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function DeleteMap(props) {
  return <SimpleDialog {...props} />
}
