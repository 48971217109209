import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { fromJS } from 'immutable'
import Icon from '../../../../components/Icon/Icon'
import { clone } from '../../../../utilities/geospatial'
import { apis } from '../../../../config/apiConfig'

import DataLevels from './DataLevels/DataLevels.jsx'
import { getLayerStyleLayerObject } from '../../../../utilities/mapStyle'
import { Alert, updateMapStyle } from '../../../../actions'
import AsyncFetch from '../../../../utilities/AsyncFetch'

import scss from './LayerStyle.scss'

const WIDTH_STYLE_DEFAULTS = {
  simpleStyle: 1,
  styleBasedOnZoom: ['interpolate', ['linear'], ['zoom'], 1, 0.5],
  styleBasedOnData: [
    'case',
    ['==', ['get', 'property_not_selected'], 'xxxxx'],
    5,
    5,
  ],
}

const OPACITY_STYLE_DEFAULTS = {
  simpleStyle: 1,
  styleBasedOnZoom: ['interpolate', ['linear'], ['zoom'], 1, 0.5],
  styleBasedOnData: [
    'case',
    ['==', ['get', 'property_not_selected'], 'xxxxx'],
    0.1,
    0.1,
  ],
}

const COLOR_STYLE_DEFAULTS = {
  simpleStyle: '#ffffff',
  styleBasedOnZoom: ['interpolate', ['linear'], ['zoom'], 1, '#ffffff'],
  styleBasedOnData: [
    'case',
    ['==', ['get', 'property_not_selected'], 'xxxxx'],
    '#ffffff',
    '#ffffff',
  ],
}

export const getLayerPaintProps = props => {
  const { mapStyle, config } = props
  const style = mapStyle.toJS()

  const properties = config.layer.layersArray.map(layerObj => {
    let layerPaintProps = {}
    let outLinePaintProps = null

    const layer = style.layers.filter(
      layerObject => layerObject.id === layerObj.layer.id
    )
    const { type } = layer[0]
    layerPaintProps = layer[0].paint
    if (type === 'fill') {
      if (!layerPaintProps['fill-color']) {
        if (type !== 'raster') {
          layerPaintProps['fill-color'] = COLOR_STYLE_DEFAULTS.simpleStyle
        }
      }
      if (
        layerPaintProps['fill-opacity'] === undefined ||
        layerPaintProps['fill-opacity'] === null
      ) {
        layerPaintProps['fill-opacity'] = OPACITY_STYLE_DEFAULTS.simpleStyle
      }
    }
    if (type === 'line') {
      if (
        layerPaintProps['line-width'] === undefined ||
        layerPaintProps['line-width'] === null
      ) {
        layerPaintProps['line-width'] = WIDTH_STYLE_DEFAULTS.simpleStyle
      }
      if (!layerPaintProps['line-color']) {
        layerPaintProps['line-color'] = COLOR_STYLE_DEFAULTS.simpleStyle
      }
      if (
        layerPaintProps['line-opacity'] === undefined ||
        layerPaintProps['line-opacity'] === null
      ) {
        layerPaintProps['line-opacity'] = OPACITY_STYLE_DEFAULTS.simpleStyle
      }
    }
    if (type === 'circle') {
      if (
        layerPaintProps['circle-radius'] === undefined ||
        layerPaintProps['circle-radius'] === null
      ) {
        layerPaintProps['circle-radius'] = WIDTH_STYLE_DEFAULTS.simpleStyle
      }
      if (!layerPaintProps['circle-color']) {
        layerPaintProps['circle-color'] = COLOR_STYLE_DEFAULTS.simpleStyle
      }
      if (
        layerPaintProps['circle-opacity'] === undefined ||
        layerPaintProps['circle-opacity'] === null
      ) {
        layerPaintProps['circle-opacity'] = OPACITY_STYLE_DEFAULTS.simpleStyle
      }
    }
    if (type === 'raster') {
      if (
        layerPaintProps['raster-opacity'] === undefined ||
        layerPaintProps['raster-opacity'] === null
      ) {
        layerPaintProps['raster-opacity'] = OPACITY_STYLE_DEFAULTS.simpleStyle
      }
    }
    if (layer[0].type === 'fill') {
      const outlineLayer = style.layers.filter(
        layerLoop => layerLoop.id === layerObj.outline.id
      )
      outLinePaintProps = outlineLayer[0].paint
      // On creation outline paint props are an empty object
      // so check for existing line and color and add defaults when needed

      // Check For Line Width Prop
      if (
        outLinePaintProps['line-width'] === undefined ||
        outLinePaintProps['line-width'] === null
      )
        outLinePaintProps['line-width'] = 1

      // Check For Line Color Prop
      if (!outLinePaintProps['line-color'])
        outLinePaintProps['line-color'] = '#000000'

      // Check for Line Opacity Prop
      if (
        outLinePaintProps['line-opacity'] === undefined ||
        outLinePaintProps['line-opacity'] === null
      )
        outLinePaintProps['line-opacity'] = 1
    }

    const layerId = layerObj.layer.id
    const layerLabel = layerObj.layer.metadata.label || layerObj.layer.id
    const layerType = layerObj.layer.type
    const returnObj = {
      layerId,
      layerLabel,
      layerType,
      layerPaintProps,
      outLinePaintProps,
    }
    return returnObj
  })
  return properties
}

export const getDefaultLevelPaintObj = (layerPaintProps, outLinePaintProps) => {
  const propObjects = clone(layerPaintProps)
  const defObj = { paint: {}, outlinePaint: {} }
  defObj.case = 'default'

  for (const [key, value] of Object.entries(propObjects)) {
    if (Array.isArray(value)) {
      if (value[0] === 'case') {
        defObj.paint[key] = value.pop()
      } else if (value[0] === 'interpolate') {
        defObj.paint[key] = value.pop()
      }
    } else {
      defObj.paint[key] = value
    }
  }
  if (outLinePaintProps) {
    const outlineObjects = clone(outLinePaintProps)
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(outlineObjects)) {
      if (Array.isArray(value)) {
        if (value[0] === 'case') {
          defObj.outlinePaint[key] = value.pop()
        } else if (value[0] === 'interpolate') {
          defObj.outlinePaint[key] = value.pop()
        }
      } else {
        defObj.outlinePaint[key] = value
      }
    }
  } else {
    defObj.outlinePaint = null
  }
  return defObj
}

const LayerStyle = props => {
  const { config } = props

  const [isCommonLayer, setIsCommonLayer] = useState(
    !!(
      typeof config.layer.layersArray[0].layer.metadata.commonLayer !==
        'undefined' && config.layer.layersArray[0].layer.metadata.commonLayer
    )
  )

  const dispatch = useDispatch()
  const [apiUpdateObjects, setApiUpdateObjects] = useState(null)
  const propObjects = clone(getLayerPaintProps(props))

  useEffect(() => {
    const { setDisplayManageAttributesMenu } = props

    setDisplayManageAttributesMenu(false)
  }, [])

  const isValid = paint => {
    if (!paint)
      return dispatch(
        Alert({ error: 'Invalid paint object returned from layer' })
      )

    let valid
    // eslint-disable-next-line
    for (const [key, val] of Object.entries(paint)) {
      if (key && key !== undefined && key !== '') {
        if (Array.isArray(val)) {
          if (
            val.flat().indexOf('undefined') !== -1 &&
            val.flat().indexOf(null) !== -1 &&
            val.flat().indexOf('') !== -1
          ) {
            valid = false
          } else {
            valid = true
          }
        } else if ((val && val !== 'undefined' && val !== '') || val === 0) {
          valid = true
        }
      }
    }
    if (valid) return true
    return dispatch(Alert({ error: 'There is an error in your payload!' }))
  }

  const buildFetchParams = paramsArray => {
    const apiUpdateObjects = []
    paramsArray.forEach(params => {
      if (isValid(params.paint)) {
        if (params.layerID.includes('_outline')) {
          let targetLayer = params.layerID.split('_')
          const key = targetLayer.pop()
          targetLayer = targetLayer.join('_')

          const url = apis.apiDatabase.uri + 'layer/update/sublayer'
          const method = 'POST'
          const body = {
            layerID: targetLayer,
            paint: params.paint,
            key,
            type: params.type,
          }
          apiUpdateObjects.push({ url, method, body })
        } else {
          const url = apis.apiDatabase.uri + 'layer/update/layer'
          const method = 'POST'
          const body = {
            layerID: params.layerID,
            paint: params.paint,
            type: params.type,
          }
          apiUpdateObjects.push({ url, method, body })
        }
      }
    })
    setApiUpdateObjects(apiUpdateObjects)
  }

  const updateLayer = (
    layerId,
    targetPaintProperty,
    targetValue,
    paintProps = null,
    outlinePaintProps = null
  ) => {
    const { mapStyle } = props
    const style = mapStyle.toJS()
    let layerObject = getLayerStyleLayerObject(style, layerId)
    const paramsArray = []
    if (layerObject)
      layerObject.forEach(layer => {
        const layerType = layer.type
        let propertyType
        if (paintProps) {
          propertyType = Object.keys(paintProps)[0].split('-').shift()

          if (layerType === propertyType) {
            layer.paint = paintProps
            paramsArray.push({
              layerID: layer.id,
              paint: layer.paint,
              type: layer.type,
            })
          }
        } else {
          propertyType = targetPaintProperty.split('-').shift()

          if (layerType === propertyType) {
            layer.paint[targetPaintProperty] = targetValue
            paramsArray.push({
              layerID: layer.id,
              paint: layer.paint,
              type: layer.type,
            })
          }
        }
        if (outlinePaintProps) {
          propertyType = Object.keys(outlinePaintProps)[0].split('-').shift()
          if (layerType === propertyType) {
            layer.paint = outlinePaintProps
            paramsArray.push({
              layerID: layer.id,
              paint: layer.paint,
              type: layer.type,
            })
          }
        }
      })
    buildFetchParams(paramsArray)
    dispatch(updateMapStyle(fromJS(style)))

    return null
  }

  const getPaintPropValue = (targetLayer, targetProperty) => {
    const { mapStyle } = props
    const style = mapStyle.toJS()
    const property = style.layers
      .filter(layer => layer.id === targetLayer)
      .map(layer => layer.paint[targetProperty])
    return property
  }

  const getStyleType = value => {
    let styleType = 'simpleStyle'

    if (Array.isArray(value)) {
      const expressionFlat = value.flat().join(',')
      if (expressionFlat.indexOf('interpolate,linear,zoom') !== -1) {
        styleType = 'styleBasedOnZoom'
      }
      if (expressionFlat.indexOf('case,==,get') !== -1) {
        styleType = 'styleBasedOnData'
      }
    }
    return styleType
  }

  const apiUpdateFinished = () => {
    setApiUpdateObjects(null)
  }

  const chainedUpdate = (layerId, paintProps, outlineProps = null) => {
    const { mapStyle } = props
    const style = mapStyle.toJS()
    const paintObject = getLayerStyleLayerObject(style, layerId)
    paintObject.paint = paintProps

    if (outlineProps) {
      const outlinePaintObject = getLayerStyleLayerObject(
        style,
        layerId + '_outline'
      )
      outlinePaintObject.paint = outlineProps
    }
    dispatch(updateMapStyle(fromJS(style)))

    const params = []
    const paintParams = {
      url: apis.apiDatabase.uri + 'layer/update/layer',
      method: 'POST',
      body: {
        layerID: layerId,
        paint: paintProps,
      },
    }
    params.push(paintParams)

    if (outlineProps) {
      const outlineParams = {
        url: apis.apiDatabase.uri + 'layer/update/sublayer',
        method: 'POST',
        body: {
          layerID: layerId,
          key: 'outline',
          paint: outlineProps,
        },
      }
      params.push(outlineParams)
    }
    return setApiUpdateObjects(params)
  }

  const getDataBasedUi = () => {
    const ui = []
    let filteredPropObjects = [...propObjects]
    if (propObjects[0].layerId.substr(0, 2) === 'a_') {
      filteredPropObjects = filteredPropObjects.filter(
        obj => obj.layerId.substr(0, 2) === 'a_'
      )
    }
    filteredPropObjects.forEach((propObj, index) => {
      const { layerId, layerPaintProps, layerType, outLinePaintProps } = propObj
      const defaultObj = clone(
        getDefaultLevelPaintObj(layerPaintProps, outLinePaintProps)
      )
      ui.push(
        <DataLevels
          key={'DataLevels_' + index}
          layerId={layerId}
          layerType={layerType}
          layerPaintProps={layerPaintProps}
          outLinePaintProps={outLinePaintProps}
          defaultObj={defaultObj}
          updateLayer={updateLayer}
          chainedUpdate={chainedUpdate}
          //onClose={props.onClose}
        />
      )
    })
    return (
      <div>
        <div>{ui}</div>
      </div>
    )
  }

  const { tocLayer } = props
  useEffect(() => {
    const values = []
    propObjects.forEach(propObj => {
      const { layerId, layerPaintProps } = propObj

      Object.keys(layerPaintProps).map(key => {
        const v = getPaintPropValue(layerId, key)
        const styleType = getStyleType(v)
        values.push({ value: v, type: styleType })
        return null
      })
    })

    const valueArr = []
    values.map(valueObj => {
      Object.values(valueObj).forEach(value => valueArr.push(value))
      return null
    })
  }, [])

  return (
    <>
      {apiUpdateObjects && (
        <AsyncFetch
          fetchObjects={apiUpdateObjects}
          fetchFinished={apiUpdateFinished}
        />
      )}
      {getDataBasedUi()}
      {!tocLayer.enableStyling && (
        <div className={scss['custom-styles-not-supported']}>
          <div className={scss['custom-styles-not-supported-icon-background']}>
            <Icon icon='exclamation' />
          </div>
          This layer does not support
          <br />
          custom styles.
        </div>
      )}
    </>
  )
}

export default LayerStyle

/*
    NOTE: Below is written by Estus, keeping this commented out for now
    incase there is anything useful here


    //return buildFetchParams(layerId, layerObject[0].paint, layerObject[0].type)
   
    if (!Array.isArray(layerObject)) layerObject = [layerObject]
    if (id === 'outline') {
      if (!layerId.includes('_outline')) {
        layerId += '_outline'
      }
    }
    
    
    if (
      paintProps ||
      (!paintProps && isValidProps(targetProperty, targetValue))
    ) {
      let paintTracker
      const updatedTargetValue = checkForDefaultProps(targetValue)
      
      layerObject.forEach(layer => {
        if (!layer.id.includes('_symbol')) {
          let outlines = false
          const targetLayerIdArray = layerId.split('_')
          let targetLayerIdNumber = targetLayerIdArray.pop()
        
          if (isNaN(targetLayerIdNumber)) {
            outlines = true
            targetLayerIdNumber = targetLayerIdArray.pop()
          }

          const loopLayerIdArray = layer.id.split('_')
          let loopLayerIdNumber = loopLayerIdArray.pop()
          
          if (isNaN(loopLayerIdNumber))
            loopLayerIdNumber = loopLayerIdArray.pop()

          if (loopLayerIdNumber === targetLayerIdNumber) {
            if (
              (outlines && layer.id.includes('outline')) ||
              (!outlines && !layer.id.includes('outline'))
            ) {
              
              if(targetProperty.includes(layer.tpye)){
                layer.paint[targetProperty] = updatedTargetValue
              }
              if (paintProps) layer.paint = paintProps
              else {
                if(layer.paint[targetProperty]){
                  layer.paint[targetProperty] = updatedTargetValue
                }
                
              }

              paintTracker = layer.paint
           // }
         // }
        }
      })
      //dispatch(updateMapStyle(fromJS(style)))
      //return buildFetchParams(layerId, paintTracker, layerObject[0].type)
    }
    */

/*MORE ESTUS 

      const handleRevertZoom = (layerId, layerPaintProps) => {
    const defaults = getDefaultLevelPaintObj(layerPaintProps)
    const paintProps = clone(layerPaintProps)
    let prop
    let targetValue
    // eslint-disable-next-line
    for (const [key, val] of Object.entries(paintProps)) {
      if (Array.isArray(val)) {
        if (val[0] === 'interpolate') {
          prop = key
          targetValue = defaults.paint[key]
        }
      }
    }
    setZoomVisible(false)
    updateLayer(layerId, prop, targetValue)
  }

    const isValidProps = (targetProperty, targetValue) => {
    if (targetProperty === 'raster-opacity') {
      if (targetValue > 1 || targetValue < 0) {
        return false
      }
      return true
    }
    if (
      targetProperty !== null &&
      targetProperty !== undefined &&
      targetValue !== null &&
      targetValue !== undefined
    )
      return true
    return false
  }


    const checkForDefaultProps = checkValue => {
    if (
      Array.isArray(checkValue) &&
      propertyDefaults &&
      propertyDefaults.length
    ) {
      let newEntry
      let newValue
      let nullValueFound

      for (let i = 1; i < checkValue.length; i += 2) {
        if (checkValue[i][2] === null) nullValueFound = true
      }
      if (nullValueFound) {
        // already has null value, check and update
        for (let i = 1; i < checkValue.length; i += 2) {
          if (
            checkValue[i][1][1] === propertyDefaults[0].key &&
            checkValue[i][2] === propertyDefaults[0].default
          ) {
            newValue = checkValue[i + 1].slice()
          }
        }
        for (let i = 1; i < checkValue.length; i += 2) {
          if (checkValue[i][2] === null) checkValue[i + 1] = newValue
        }
      } else {
        // no null value, create with loop
        for (let i = 1; i < checkValue.length - 1; i += 2) {
          if (
            checkValue[i][1][1] === propertyDefaults[0].key &&
            checkValue[i][2] === propertyDefaults[0].default
          ) {
            newEntry = checkValue[i].slice()
            newValue = checkValue[i + 1].slice()
          }
        }
        if (newEntry) {
          newEntry[2] = null
          checkValue.splice(checkValue.length - 1, 0, newEntry)
          checkValue.splice(checkValue.length - 1, 0, newValue)
        }
      }
    }
    return checkValue
  }


              {zoomVisible ? (
              <div className={scss.stylesHint}>
                Adding custom zoom settings.&nbsp;
       
                <a
                  onClick={() => {
                    handleRevertZoom(layerId, layerPaintProps)
                  }}
                  className={scss.stylesHintLink}
                >
                  Revert to optimized.
                </a>
                <ZoomLevels
                  layerId={layerId}
                  layerPaintProps={paint}
                  outLinePaintProps={outlinePaint}
                  layerType={layerType}
                  updateLayer={updateLayer}
                  revert={() => handleRevertZoom(layerPaintProps)}
                />
              </div>
            ) : (
              <div className={scss.stylesHint}>
                Styles optimized for zoom automatically.&nbsp;
         
                <a
                  onClick={() => setZoomVisible(true)}
                  className={scss.stylesHintLink}
                >
                  Set custom.
                </a>
              </div>
            )}

            */

// ==================================
// * Standard UI
// ==================================

/*const getStandardUi = () => {
    const ui = []
    console.log("propObjects ", propObjects)
    propObjects.forEach(propObj => {
      const {
        layerId,
        layerLabel,
        layerPaintProps,
        layerType,
        outLinePaintProps,
      } = propObj

      const paint = clone(layerPaintProps)
      const outlinePaint = clone(outLinePaintProps)
      ui.push(
        <>
          <div>
            <div className={scss.headerInputs}>
              {propObjects.length > 1 ? <div>{layerLabel}</div> : null}
              <StyleInput
                layerId={layerId}
                layerPaintProps={paint}
                outLinePaintProps={outlinePaint}
                type={layerType}
                updateLayer={handleUpdate}
              />
            </div>
  
          </div>
        </>
      )
    })
    return (
      <>
        <div>{ui}</div>
      </>
    )
  }
  
  
  
  
    const handleUpdate = (layerId, targetProperty, targetValue, id) => {
    if (showDataBasedUi) {
      return null
    }
    if (id === 'outline') {
      if (!layerId.includes('_outline')) {
        layerId += '_outline'
      }
    }
    updateLayer(layerId, targetProperty, targetValue, null, id)
  }


    const handleDefaultsInProperties = dataArr => {
    const arr = []
    dataArr.forEach(dataObj => {
      if (dataObj.default && dataObj.default !== '') {
        arr.push({ key: dataObj.key, default: dataObj.default })
      }
    })
    setPropertyDefaults(arr)
  }

  */
