import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Icon from '../../../components/Icon/Icon'
import appScss from '../../App/App.scss'

export default function TargetLayerSelect({
  targetLayer,
  setTargetLayer,
  clearSelectedFeatures,
}) {
  const dataConfig = useSelector(state => state.updateDataConfig)
  const [selectLayers, setSelectLayers] = useState([])

  const processTocObject = (targetLayers, tocObject) => {
    if (tocObject.toc) {
      if (tocObject.layersArray[0].layer.type === 'raster') return

      const dbIdArray = tocObject.layersArray[0].layer.id.split('_')
      const splitId = tocObject.toc.id.split('_')

      targetLayers.push({
        value: tocObject.toc.id,
        dbId: dbIdArray[dbIdArray.length - 1],
        label: tocObject.toc.label,
        type: tocObject.layersArray[0].layer.type,
        layerIdList: tocObject.layersArray.map(layer => layer.layer.id),
        layerId: splitId[splitId.length - 1],
        paint: tocObject.layersArray[0].layer.paint,
      })
    } else {
      tocObject.children.items.forEach(tocObject => {
        processTocObject(targetLayers, tocObject)
      })
    }
  }

  const handleSelectChange = e => {
    const selected = selectLayers.filter(
      layer => layer.value === e.target.value
    )

    if (!selected.length) return
    setTargetLayer(selected[0])
    clearSelectedFeatures()
  }

  useEffect(() => {
    if (!dataConfig) return
    let layers = []
    dataConfig['tocLayers'].forEach(tocObject => {
      processTocObject(layers, tocObject)
    })

    layers.unshift({
      value: 'none',
      label: 'Select Target Layer',
      type: 'none',
    })

    setSelectLayers(layers)
  }, [dataConfig])

  return (
    <div className={appScss['select-chevron']}>
      <select
        value={targetLayer ? targetLayer.value : ''}
        placeholder='Select Target Layer'
        onChange={handleSelectChange}
      >
        {selectLayers.map(option => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          )
        })}
      </select>
      <Icon icon={('fal', 'chevron-down')} />
    </div>
  )
}
