import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import Icon from '../../../../components/Icon/Icon'
import { Tooltip } from 'react-tooltip'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import {
  setToolConfig,
  setDataTable,
  mountLayerSettings,
  updateMapStyle,
  setOverPanel,
  setScrollZoom,
  setDragPan,
  setDoubleClickZoom,
} from '../../../../actions/index'
import MakeDataConfigComponent, {
  getLayerConfigUsingTocId,
} from '../../../../utilities/dataConfig'
import * as utils from '../../../../utilities/util'

import ExportToCsv from '../LayerInfo/ExportToCsv'
import LayerDelete from '../LayerInfo/LayerDelete'
import TableJoin from '../LayerInfo/TableJoin'
import CopyLayer from '../LayerInfo/CopyLayer'
import ShareModal from '../../ShareModal/ShareModal'

import scss from './LayerFooter.scss'
import appScss from '../../../App/App.scss'
import toolScss from '../../../../_tools.scss'

import exportIcon from '../../../../assets/icons/tools/Export.svg'
import importIcon from '../../../../assets/icons/tools/Import.svg'

import ShareButton from './ShareButton'

const LayerInfo = React.memo(({ closeTool, config, dataConfig }) => {
  if (!config || !config.layer) return null
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)
  const user = useSelector(state => state.user)
  const mapStyle = useSelector(state => state.mapStyle)

  const [fetchObjects, setFetchObjects] = useState(null)
  const [layerDeleteVisible, setLayerDelete] = useState(false)
  const [tableJoinVisible, setTableJoin] = useState(false)
  const [exportToCsvVisible, setExportToCsv] = useState(false)
  const [copyLayerVisible, setCopyLayer] = useState(false)
  const [shareLayerVisible, setShareLayer] = useState(false)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [loadingDrawTool, setLoadingDrawTool] = useState(false)

  const tocId = config.layer.toc.id
  const canEdit =
    config.layer.toc.type === 'mam' ? null : config.layer.toc.canEdit
  const canDelete =
    config.layer.toc.type === 'mam' ? null : config.layer.toc.canDelete
  const layerId = config.layer.layersArray[0].layer.id
  const type = config.layer.layersArray[0].layer.type
  const layerLabel = config.layer.toc.label
  const layerDescription = config.layer.toc.description
  const layerMinZoom = config.layer.layersArray[0].layer.minzoom

  const openDrawTool = () => {
    setLoadingDrawTool(true)
    toolConfig.forEach(tool => {
      if (tool.name === 'Draw') {
        tool.visible = true
        tool.targetLayer = config.layer
      }
      if (tool.name === 'LayerSettings') {
        tool.visible = false
        tool.layer = null
      }
    })

    const newToolConfig = JSON.parse(JSON.stringify(toolConfig))
    dispatch(mountLayerSettings(null))
    dispatch(setToolConfig(newToolConfig))
    dispatch(setOverPanel(false))
    dispatch(setScrollZoom(true))
    dispatch(setDragPan(true))
    dispatch(setDoubleClickZoom(true))
  }

  const openLayerDelete = layerDeleteVisible => {
    setLayerDelete(layerDeleteVisible)
    dispatch(setOverPanel(false))
    dispatch(setScrollZoom(true))
    dispatch(setDragPan(true))
    dispatch(setDoubleClickZoom(true))
  }
  const openTableJoin = tableJoinVisible => setTableJoin(tableJoinVisible)
  const openExportToCsv = exportToCsvVisible =>
    setExportToCsv(exportToCsvVisible)
  const openCopyLayer = copyLayerVisible => setCopyLayer(copyLayerVisible)
  const openShareLayer = shareLayerVisible => setShareLayer(shareLayerVisible)

  const buildFetchParams = id => {
    const layerId = config.layer.toc.id
    let method
    let url
    let body = {}
    let params = []

    const sublayer = config.layer.layersArray[0].outline

    if (id === 'archive') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'layers/user/delete'
      body = {
        mapID: user.mapID,
        layerID: layerId,
      }

      params.push({ url, method, body })
    } else if (id === 'save') {
      method = 'POST'
      url = apis['apiDatabase'].uri + 'layer/update'
      body = {
        layerID: layerId,
        label: layerLabel,
        description: layerDescription,
        minzoom: layerMinZoom,
      }
      params.push({ url, method, body })

      if (sublayer) {
        let subUrl = apis['apiDatabase'].uri + 'layer/update/sublayer'
        let subBody = {
          layerID: config.layer.layersArray[0].layer.id,
          key: 'outline',
          minzoom: layerMinZoom,
        }
        params.push({ url: subUrl, method, body: subBody })
      }
    }
    setFetchObjects(params)
  }

  const fetchFinished = results => {
    // archive response
    return results.map(result => {
      if (utils.verifyResult(result)) {
        setMountMakeDataConfig(true)
        closeTool(config.name)
      }
      setFetchObjects(null)
    })
  }

  const doArchive = () => {
    buildFetchParams('archive')
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      if (
        layerMinZoom !== 0 &&
        layerMinZoom !== 'undefined' &&
        layerMinZoom !== null
      ) {
        let style = mapStyle.toJS()
        const targetLayer = style.layers.filter(layer => layer.id === layerId)
        const targetSubLayer = style.layers.filter(
          layer => layer.id === layerId + '_outline'
        )

        if (targetLayer[0]) {
          targetLayer[0].minzoom = layerMinZoom
          targetSubLayer.length
            ? (targetSubLayer[0].minzoom = layerMinZoom)
            : null

          dispatch(updateMapStyle(fromJS(style)))
        }
      }
    },
    [layerMinZoom]
  )

  const handleOnFinish = dataConfig => {
    updateToolConfig(dataConfig)
  }

  const updateToolConfig = dataConfig => {
    // the data for the layer has changed (a new description or layer name)
    // the purpose of this function is to update app state data config with the new layer information
    // and remount the component to preserve the changes
    const updatedLayer = getLayerConfigUsingTocId(
      dataConfig,
      config.layer.toc.id
    )

    // add the newly updated layer to the toolConfig
    if (updatedLayer) {
      toolConfig.forEach(config => {
        if (config.name === 'LayerSettings') {
          config.layer = updatedLayer
        }
      })
      dispatch(setToolConfig(toolConfig))
    }
  }

  const loadDataTable = () => {
    if (type === 'image') return
    closeTool(config.name)
    dispatch(setDataTable({ tocId, layerId, layerLabel, foooterHeight: 300 }))
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent onFinish={handleOnFinish} />
      )}
      {layerDeleteVisible && (
        <LayerDelete
          onClose={() => openLayerDelete(false)}
          layerLabel={layerLabel}
          tocId={tocId}
          layerId={layerId}
        />
      )}
      {tableJoinVisible && (
        <TableJoin
          onClose={() => openTableJoin(false)}
          layerId={layerId}
          layerLabel={layerLabel}
        />
      )}
      {exportToCsvVisible && (
        <ExportToCsv
          onClose={() => openExportToCsv(false)}
          layerId={layerId}
          layerLabel={layerLabel}
        />
      )}
      {copyLayerVisible && (
        <CopyLayer
          onClose={() => openCopyLayer(false)}
          layerId={tocId}
          layerLabel={layerLabel}
        />
      )}
      {shareLayerVisible && (
        <ShareModal
          onClose={() => openShareLayer(false)}
          itemId={tocId}
          dataType={'layer'}
          dataConfig={dataConfig}
        />
      )}

      <div className={[scss.layerFooter, toolScss.toolIcons].join(' ')}>
        {/* Left Side of Layer Footer */}
        <div className={toolScss.toolIconLeftRow}>
          <div
            className={[
              toolScss.toolIconBox,
              toolScss.toolTrashIcon,
              toolScss[!canDelete ? 'inactive' : ''],
            ].join(' ')}
            onClick={canDelete ? () => openLayerDelete(true) : null}
            data-tooltip-content='Delete'
            data-tooltip-id='delete'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fas', 'trash']} size='1x' />
            <div className={appScss.reactToolTip}>
              <Tooltip id='delete' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
          <div
            className={[toolScss.toolIconBox, toolScss.toolArchiveIcon].join(
              ' '
            )}
            onClick={() => doArchive()}
            data-tooltip-content='Archive'
            data-tooltip-id='archive'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fas', 'archive']} size='1x' />
            <div className={appScss.reactToolTip}>
              <Tooltip id='archive' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
        </div>

        {/* Right Side of Layer Footer */}
        <div className={toolScss.toolIconRightRow}>
          <div
            className={[
              toolScss.toolIconBox,
              toolScss.toolDrawIcon,
              toolScss[!canEdit ? 'inactive' : ''],
            ].join(' ')}
            onClick={canEdit ? () => openDrawTool() : null}
            data-tooltip-content='Draw / Edit'
            data-tooltip-id='editFeature'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            {loadingDrawTool ? (
              <Icon icon={'spinner'} size='1x' spin />
            ) : (
              <>
                <Icon icon={['fas', 'pen-nib']} size='1x' />
                <div className={appScss.reactToolTip}>
                  <Tooltip
                    id='editFeature'
                    getContent={dataTip => `${dataTip}`}
                  />
                </div>
              </>
            )}
          </div>
          <div
            className={[toolScss.toolIconBox, toolScss.toolIconExport].join(
              ' '
            )}
            onClick={() => openExportToCsv(true)}
            data-tooltip-content='Export'
            data-tooltip-id='export'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <img
              src={exportIcon}
              alt='Export'
              className={toolScss.exportIcon}
            />
            <div className={appScss.reactToolTip}>
              <Tooltip id='export' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>

          <div
            className={[
              toolScss.toolIconBox,
              toolScss[!canEdit ? 'inactive' : ''],
            ].join(' ')}
            onClick={canEdit ? () => openTableJoin(true) : null}
            data-tooltip-content='Import'
            data-tooltip-id='import'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <img
              src={importIcon}
              alt='Import'
              className={toolScss.importIcon}
            />
            <div className={appScss.reactToolTip}>
              <Tooltip id='import' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>

          <div
            className={[toolScss.toolIconBox, toolScss.toolTableIcon].join(' ')}
            onClick={loadDataTable}
            data-tooltip-content='Table'
            data-tooltip-id='table'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fa', 'table']} size='1x' />
            <div className={appScss.reactToolTip}>
              <Tooltip id='table' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
          <div
            className={[
              toolScss.toolIconBox,
              toolScss.toolCloneIcon,
              toolScss[!canEdit ? 'inactive' : ''],
            ].join(' ')}
            onClick={canEdit ? () => openCopyLayer(true) : null}
            data-tooltip-content='Duplicate'
            data-tooltip-id='clone'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fa', 'clone']} size='1x' />
            <div className={appScss.reactToolTip}>
              <Tooltip id='clone' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
          <div
            className={[
              toolScss.toolIconBox,
              toolScss.toolCloneIcon,
              toolScss[!canEdit ? 'inactive' : ''],
            ].join(' ')}
            onClick={canEdit ? () => openShareLayer(true) : null}
            data-tooltip-content='Share'
            data-tooltip-id='share'
            data-effect='solid'
            data-arrow-color='rgba(0, 0, 0, 0.68)'
          >
            <Icon icon={['fa', 'share-alt']} size='1x' />
            <div className={appScss.reactToolTip}>
              <Tooltip id='share' getContent={dataTip => `${dataTip}`} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
})

export default LayerInfo
