import React from 'react'
import Icon from '@/components/Icon/Icon'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
} from '@/styles/tailwind-utilities'

const MeasureBtn = ({ isMeasuring, toggleMeasure, buttonRef }) => {
  return (
    <button
      ref={buttonRef}
      onClick={toggleMeasure}
      className={`${buttonBase} ${mapControlButton} ${
        isMeasuring
          ? mapControlButtonColors.active
          : mapControlButtonColors.default
      }`}
    >
      <Icon icon={['fa', 'ruler']} size='xl' />
    </button>
  )
}

export default MeasureBtn
