import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { store } from '../../index'

import * as utils from '../../utilities/util'
import AsyncFetch from '../../utilities/AsyncFetch'
import Icon from '../../components/Icon/Icon'

import { connect } from 'react-redux'
import { apis } from '../../config/apiConfig'
import { switchPage, Login as _Login, Logout as _Logout } from '../../actions'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import Skeleton from 'react-loading-skeleton'
import Checkbox from 'react-three-state-checkbox'

import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import Switch from '@material-ui/core/Switch'

import AssignAccountModal from './AccountModals/assignAccount'
import EditUserModal from './Popups/addUserModal'
import UserDeleteModal from './UserDeleteModal'
import EditAccountModal from './AccountModals/addNewAccount'
// import DeleteAccountModal from "./Popups/deleteAccountModal";
import AccountLogoModal from './Popups/organizationImage'
import UserImageModal from './Popups/profileImage'

import dropdownScss from '../CSSModules/Dropdown.scss'
import checkboxScss from '../CSSModules/Checkbox.scss'
import appScss from '../App/App.scss'
import profileScss from './Profile.scss'
import scss from './Accounts.scss'
import { useTranslation } from 'react-i18next'

export default function AccountList({
  setAssignAccountVisible,
  setUnassignAccountVisible,
  accounts,
  toggleAccountChecks,
  checkAccountFilter,
  checkUserFilter,
  getCountChecked,
  getCheckbox,
  setCheckbox,
  clickCheckbox,
  collapsed,
  handleCollapse,
  forceUpdate,
}) {
  const { t } = useTranslation()

  //Other State Variables
  const [loading, setLoading] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)

  //Fields and their States
  const [userIDState, setUserIDs] = useState([])
  const [checkedAccounts, setCheckedAccounts] = useState([])
  const [showAssignDropdown, setAssignDropdown] = useState(false)
  const [accountImageURLs, setAccountImageURLs] = useState([])

  /* ---------------------------- USER IMAGE STATES --------------------------- */
  const [users, setUsers] = useState([])
  const [userImageURLs, setUserImageURLs] = useState([])
  const [userUpdateObjects, setUserUpdateObjects] = useState(null)
  /* -------------------------------------------------------------------------- */
  const [editUserVisible, setEditUserVisible] = useState(false)
  const [userImageVisible, setUserImageVisible] = useState(false)
  const [deleteUserModalVisibleKey, setDeleteUserModalVisibleKey] =
    useState(null)
  const [editAccountVisible, setEditAccountVisible] = useState(false)
  const [accountLogoVisible, setAccountLogoVisible] = useState(false)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const adminBoxStatusIconRed = { color: '#ff0000' } // RED COLOR
  const adminBoxStatusIcon = { color: '#44df60' } // GREEN COLOR

  const showUsage = utils.isSuperAdmin(store.getState().user)
  let showTotalRow = showUsage
  let totalAccounts = 0
  let totalUsers = 0
  let totalUsage = 0
  const totalMaps = '∞'

  const toggleAssignSwitcher = accountKey => {
    if (showAssignDropdown == accountKey) {
      setAssignDropdown(false)
    } else setAssignDropdown(accountKey)
  }

  /* ------------------------------- DELETE USER ------------------------------ */
  const deleteUserModal = user => {
    setDeleteUserModalVisibleKey(user)
  }

  const handleUserDelete = (id = null) => {}

  /* -------------------------------------------------------------------------- */

  const deactivateUser = (account, user) => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/user/deactivate'
    let body = {
      userID: user.id,
      accountID: account.id,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(account.id + '_' + user.id)
  }

  const activateUser = (account, user) => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/user/activate'
    let body = {
      userID: user.id,
      accountID: account.id,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(account.id + '_' + user.id)
  }

  const fetchFinished = () => {
    setFetching(false)
    setFetchObjects(null)

    forceUpdate()
  }

  // ===================================
  // = PROCESS THE DATA INTO VARIABLES =
  // ===================================
  // const processUserIDs = () => {
  //   var userIDs = []
  //   for (const key in checkboxList) {
  //     var check = checkboxList[key]

  //     var userID = check.user.id

  //     userIDs = utils.addVal(userIDs,userID)
  //   }
  //   setUserIDs(userIDs)

  //   //processAccounts
  //   processAccounts(userIDs)
  // }

  // const processAccounts = (checkedUserIDs) => {
  //   var accountIDsByUser = {}

  //   for (const key in accounts) {
  //     var account = accounts[key]
  //     var users = account.users

  //     users.forEach(function (user) {
  //       if (checkedUserIDs.includes(user.id)) {
  //         if (accountIDsByUser[user.id] == null) accountIDsByUser[user.id] = []
  //         accountIDsByUser[user.id] = utils.addVal(accountIDsByUser[user.id],account.id)
  //       }
  //     })
  //   }
  //   accountIDsByUser = Object.values(accountIDsByUser)
  //   // console.log(accountIDsByUser)
  //   if (Array.isArray(accountIDsByUser)) {
  //     var cAccounts = accountIDsByUser.shift().filter(function(v) {
  //         return accountIDsByUser.every(function(a) {
  //             return a.indexOf(v) !== -1;
  //         });
  //     });
  //     // console.log(cAccounts);
  //     setCheckedAccounts(cAccounts)
  //   }
  // };

  // useEffect(() => {
  //   processUserIDs()
  // }, []);

  /* ---------------------- USER PROFILE IMAGE FUNCTIONS ---------------------- */
  const userUpdateFinished = results => {
    results.forEach(res => {
      if (res.success && res.data) {
        const target = users.filter(user => user.id === res.data.userID)
        target[0].isAdmin = res.data.isAdmin

        setUsers(users)
        setDropdown(false)
      }
    })

    setUserUpdateObjects(null)
  }
  /* -------------------------------------------------------------------------- */

  //Account Images
  const getAccountImages = async () => {
    return Promise.all(
      accounts.map(async account => {
        const imgURL = await utils.getImage(account.profileImg, 'account.png')

        return {
          id: account.id,
          name: account.name,
          image: imgURL,
        }
      })
    )
  }

  //User Images
  const getUserImages = async userList => {
    return Promise.all(
      userList.map(async user => {
        const imgURL = await utils.getImage(user.profileImg, 'user.png')

        return {
          id: user.id,
          name: user.displayName,
          image: imgURL,
        }
      })
    )
  }

  useEffect(() => {
    async function getAccountURLS() {
      if (Array.isArray(accounts)) {
        getAccountImages().then(accountURLs => {
          // console.warn('ACCOUNT-IMAGES',accountURLs)
          setAccountImageURLs(accountURLs)
        })

        var userList = []
        var userObjList = []
        accounts.map(account => {
          account.users.map(user => {
            if (!userList.includes(user.id)) userObjList.push(user)
            userList = utils.addVal(userList, user.id)
          })
        })

        getUserImages(userObjList).then(userURLs => {
          // console.warn('USER-IMAGES',userURLs)
          setUserImageURLs(userURLs)
        })
      }
    }
    getAccountURLS()
  }, [user])
  /* -------------------------------------------------------------------------- */

  const displayAccount = account => {
    if (!checkAccountFilter(account)) return
    totalAccounts++
    totalUsers += account.users.length
    totalUsage +=
      account.usage === null
        ? 0
        : Number.parseInt(account.usage) / 1_000_000_000

    resultsFound = true

    var checkedValue
    var indeterminateValue
    var curAccountCount = getCountChecked(account.id)
    if (curAccountCount == 0) {
      checkedValue = false
      indeterminateValue = false
    } else if (curAccountCount == account.users.length) {
      checkedValue = true
      indeterminateValue = false
    } else {
      checkedValue = false
      indeterminateValue = true
    }
    // console.log(account.id,checkedValue,indeterminateValue)

    account.users.sort(utils.objectSort('firstName'))

    var accountImgObj = utils.objectKeyVal(account.id, accountImageURLs, 'id')

    return (
      <div key={'account_' + account.id}>
        <div
          className={[
            scss.adminBoxRowHeading,
            showUsage ? scss.withUsageColumn : '',
          ].join(' ')}
        >
          {/* ALT CUSTOM CHECKBOX BELOW */}
          <label
            className={[
              checkboxScss.checkboxContainer,
              checkboxScss.altCheckboxContainer,
            ].join(' ')}
          >
            <Checkbox
              className={checkboxScss.inputIndeterminate}
              checked={checkedValue}
              indeterminate={indeterminateValue}
              onChange={e => toggleAccountChecks(e, account)}
            />
            <span
              className={[
                checkboxScss.checkboxMark,
                checkboxScss.altCheckboxMark,
              ].join(' ')}
            ></span>
          </label>

          <div className={scss.adminBoxRowItems}>
            <span className={[scss.adminBoxText, scss.adminBoxImage].join(' ')}>
              <img
                onClick={() => setAccountLogoVisible(account.id)}
                src={accountImgObj ? accountImgObj.image : ''}
                className={[scss.adminBoxAccountImage].join(' ')}
              ></img>
              <div className={scss.adminImageCover}>
                <Icon
                  className={scss.adminImageCoverEdit}
                  icon='pencil'
                />
              </div>
              {account.name}
            </span>
          </div>

          <div className={scss.adminBoxRowItems}>
            <span className={scss.adminBoxText}>
              {t('accountList.users.count', {
                count: account.users.length || '-',
                total: account.userCount || '-',
              })}
            </span>
          </div>

          <div
            className={scss.adminBoxRowItems}
            style={{ justifyContent: 'center' }}
          >
            <span className={scss.adminBoxText}>{account.mapCount || '∞'}</span>
          </div>

          {showUsage && (
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {t('accountList.users.usage', {
                  amount: (account.usage / 1_000_000_000).toFixed(2),
                })}
              </span>
            </div>
          )}

          <div
            className={scss.adminBoxRowItems}
            style={{ justifyContent: 'center' }}
          >
            <span className={scss.adminBoxText}></span>
          </div>

          <div
            className={scss.adminBoxRowItems}
            style={{ overflow: 'visible' }}
            onClick={() => toggleAssignSwitcher('account_' + account.id)}
          >
            {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
            <div
              className={[dropdownScss.dropdown, scss.adminUserOptions].join(
                ' '
              )}
            >
              <Icon icon={['far', 'ellipsis-h']} size='1x' />
              {showAssignDropdown == 'account_' + account.id && (
                <>
                  <div
                    className={dropdownScss.dropdownCover}
                    onClick={() =>
                      toggleAssignSwitcher('account_' + account.id)
                    }
                  />
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      dropdownScss.assignDropdownContent,
                    ].join(' ')}
                    style={{ right: 0 }}
                  >
                    <div
                      className={[dropdownScss.assignDropdownItem].join(' ')}
                    >
                      <div onClick={() => setEditAccountVisible(account.id)}>
                        <span>Edit Account</span>
                      </div>
                    </div>
                    <div
                      className={[dropdownScss.assignDropdownItem].join(' ')}
                    >
                      <div onClick={() => setAccountLogoVisible(account.id)}>
                        <span>Upload Logo</span>
                      </div>
                    </div>
                    {/* <div className={[dropdownScss.assignDropdownItem].join(" ")}>
                      <div onClick={null}>
                        <span>Delete Account</span>
                      </div>
                    </div> */}
                  </div>
                </>
              )}
            </div>
            {/* -------------------------------------------------------------------------- */}
          </div>

          <div
            className={scss.adminBoxRowItems}
            onClick={() => handleCollapse(account.id)}
          >
            {account.users.length != 0 && (
              <span className={scss.adminBoxText}>
                <Icon
                  icon={
                    collapsed.includes(account.id)
                      ? 'chevron-down'
                      : 'chevron-up'
                  }
                  size='1x'
                />
              </span>
            )}
          </div>
        </div>

        <Collapse
          className={scss.muiPaperShadow}
          in={!collapsed.includes(account.id)}
        >
          <Paper className={scss.muiPaperShadow} elevation={4}>
            {account.users.map(user => displayUser(account, user))}
          </Paper>
        </Collapse>

        {editAccountVisible == account.id && (
          <EditAccountModal
            doUpdate={forceUpdate}
            visible={setEditAccountVisible}
            accountID={account.id}
            accountName={account.name}
            accountNearmap={account.nearmap}
          />
        )}
        {accountLogoVisible == account.id && (
          <AccountLogoModal
            organizationImg={account.profileImg}
            visible={setEditAccountVisible}
            accountID={account.id}
            accountName={account.name}
          />
        )}
      </div>
    )
  }

  const displayUser = (a, u) => {
    if (!checkUserFilter(u)) return null
    const isSuperAdmin = utils.isSuperAdmin(u)

    if (
      !utils.isSuperAdmin(user) && // Current user is not super admin
      isSuperAdmin // User is super admin
    )
      return null

    const profileImgObj = utils.objectKeyVal(u.id, userImageURLs, 'id')

    return (
      <div key={'account_' + a.id + '_user_' + u.id}>
        {userUpdateObjects && (
          <AsyncFetch
            fetchObjects={userUpdateObjects}
            fetchFinished={userUpdateFinished}
          />
        )}

        {/* ADMIN BOX WITH TABLE SUB-ROWS */}
        <div
          className={[
            scss.adminBoxRow,
            showUsage ? scss.withUsageColumn : '',
          ].join(' ')}
        >
          {/* CUSTOM CHECKBOX BELOW */}
          <label className={checkboxScss.checkboxContainer}>
            <input
              className={checkboxScss.checkbox}
              type='checkbox'
              checked={getCheckbox(a, u)}
              onClick={() => clickCheckbox(a, u)}
            />
            <span className={checkboxScss.checkboxMark}></span>
          </label>

          <div className={scss.adminBoxRowItems}>
            <span className={[scss.adminBoxText, scss.adminBoxImage].join(' ')}>
              <img
                onClick={() => setUserImageVisible(a.id + '_' + u.id)}
                src={profileImgObj ? profileImgObj.image : ''}
                className={scss.adminBoxUserImage}
              ></img>
              <div
                className={[scss.userImageCover, scss.adminImageCover].join(
                  ' '
                )}
              >
                <Icon
                  className={scss.adminImageCoverEdit}
                  icon='pencil'
                />
              </div>
              {u.displayName}
              {isSuperAdmin && (
                <Icon
                  icon='star'
                  size='1x'
                  style={{ paddingLeft: '5px' }}
                  title={t('accountList.users.superAdmin')}
                />
              )}
            </span>
          </div>
          <div className={scss.adminBoxRowItems}>
            <span className={scss.adminBoxText}>{u.emailAddress}</span>
          </div>

          <div
            className={scss.adminBoxRowItems}
            style={{ justifyContent: 'center' }}
          >
            <span className={scss.adminBoxText}>2/5</span>
          </div>

          {showUsage && (
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {utils.dynamicDisplayUsage(u.usage)}
              </span>
            </div>
          )}

          <div
            className={scss.adminBoxRowItems}
            style={{ justifyContent: 'center' }}
          >
            <span className={scss.adminBoxStatus}>
              {/* <Icon style={u.isActive ? adminBoxStatusIcon : adminBoxStatusIconRed } icon="circle" size="1x" /> */}
              {fetching === a.id + '_' + u.id ? (
                <Icon icon='spinner' size='sm' spin />
              ) : (
                <Switch
                  checked={u.isActive ? true : false}
                  onChange={
                    u.isActive == true
                      ? () => deactivateUser(a, u)
                      : () => activateUser(a, u)
                  }
                  name='accountStatus'
                  inputProps={{ 'aria-label': 'checkbox with default color' }}
                />
              )}
            </span>
          </div>

          <div
            className={scss.adminBoxRowItems}
            onClick={() => toggleAssignSwitcher(a.id + '_' + u.id)}
          >
            {/* ----------------------------- ASSIGN DROPDOWN ---------------------------- */}
            <div
              className={[dropdownScss.dropdown, scss.adminUserOptions].join(
                ' '
              )}
            >
              <Icon icon={['far', 'ellipsis-h']} size='1x' />
              {showAssignDropdown == a.id + '_' + u.id && (
                <>
                  <div
                    className={dropdownScss.dropdownCover}
                    onClick={() => toggleAssignSwitcher(a.id + '_' + u.id)}
                  />
                  <div
                    className={[
                      dropdownScss.dropdownContent,
                      dropdownScss.assignDropdownContent,
                    ].join(' ')}
                    style={{ right: 0 }}
                  >
                    <div
                      className={[dropdownScss.assignDropdownItem].join(' ')}
                    >
                      <div
                        onClick={() => {
                          setCheckbox(a, u)
                          setEditUserVisible(a.id + '_' + u.id)
                        }}
                      >
                        <span>&nbsp;Edit User</span>
                      </div>
                    </div>
                    <div
                      className={[dropdownScss.assignDropdownItem].join(' ')}
                    >
                      <div
                        onClick={() => {
                          setCheckbox(a, u)
                          setUserImageVisible(a.id + '_' + u.id)
                        }}
                      >
                        <span>&nbsp;Change Photo</span>
                      </div>
                    </div>

                    {/* <div className={[dropdownScss.assignDropdownItem].join(" ")}>
                      <div onClick={() => {setCheckbox(a,u); setAssignAccountVisible(true)}}>
                        <span>&nbsp;Assign to account</span>
                      </div>
                    </div> */}

                    <div
                      className={[dropdownScss.assignDropdownItem].join(' ')}
                    >
                      <div
                        onClick={() => {
                          setCheckbox(a, u)
                          setUnassignAccountVisible(true)
                        }}
                      >
                        <span>&nbsp;{t('accountList.actions.unassign')}</span>
                      </div>
                    </div>

                    {/* <div className={[dropdownScss.assignDropdownItem].join(" ")}>
                      <div onClick={() => {setCheckbox(a,u); () => deleteUserModal(u)}}>
                        <span>&nbsp;Delete User</span>
                      </div>
                    </div> */}
                  </div>
                </>
              )}
            </div>
            {/* -------------------------------------------------------------------------- */}
          </div>
        </div>

        {editUserVisible == a.id + '_' + u.id && (
          <EditUserModal
            visible={setEditUserVisible}
            userID={u.id}
            firstName={u.firstName}
            lastName={u.lastName}
            title={u.title}
            emailAddress={u.emailAddress}
            userRole={u.isAdmin}
          />
        )}
        {userImageVisible == a.id + '_' + u.id && (
          <UserImageModal
            profileImg={profileImgObj.image}
            visible={setUserImageVisible}
            userID={u.id}
            userName={u.firstName}
          />
        )}

        {deleteUserModalVisibleKey && (
          <UserDeleteModal
            user={deleteUserModalVisibleKey}
            respond={handleUserDelete}
          />
        )}
      </div>
    )
  }

  accounts.sort(utils.objectSort('name'))

  var listArea
  var resultsFound = false

  const noResultsFoundDisplay = (
    <div className={scss.noResults}>
      <Icon icon={['fal', 'search']} />
      <Icon icon={'question'} />
      <span className={scss.noResultsHeading}>
        {t('accountList.noResults.title')}
      </span>
      <span className={scss.noResultsParagraph}>
        {t('accountList.noResults.description')}
      </span>
    </div>
  )

  listArea = accounts.map(account => displayAccount(account))
  showTotalRow = showUsage
  if (!resultsFound) {
    showTotalRow = false
    listArea = noResultsFoundDisplay
  }

  return (
    <>
      <div className={scss.adminBox}>
        {fetchObjects && (
          <AsyncFetch
            fetchObjects={fetchObjects}
            fetchFinished={fetchFinished}
          />
        )}
        {listArea}
      </div>
      {showTotalRow && (
        <div className={scss.adminBox}>
          <div
            className={[
              scss.adminBoxRowHeading,
              scss.totalsRow,
              scss.withUsageColumn,
            ].join(' ')}
          >
            <div className={scss.adminBoxRowItems}>
              <span className={scss.adminBoxText}>
                {t('accountList.totals.title')}
              </span>
            </div>
            <div className={scss.adminBoxRowItems}>
              <span className={scss.adminBoxText}>{totalAccounts}</span>
            </div>
            <div className={scss.adminBoxRowItems}>
              <span className={scss.adminBoxText}>{totalUsers}</span>
            </div>
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>∞</span>
            </div>
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}>
                {totalUsage.toFixed(3)} GB{' '}
              </span>
            </div>
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}></span>
            </div>
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}></span>
            </div>
            <div
              className={scss.adminBoxRowItems}
              style={{ justifyContent: 'center' }}
            >
              <span className={scss.adminBoxText}></span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
