/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { useDropzone } from 'react-dropzone'
import CircularProgress from '@material-ui/core/CircularProgress'

// Amplify
import Storage from '@aws-amplify/storage'
import { SetS3Config } from '../../../services'
import * as utils from '../../../utilities/util'

// Actions
import { Alert } from '../../../actions'

// SCSS
import scss from './DropZoneV2.scss'
import appScss from '../../App/App.scss'

// This function is called when the user taps the edit button, it opens the editor and returns the modified file when done

let uploadProgressAmount = {}
let uploadProgressTotal = {}
let uploadProgressTime = {}

export default function DropZoneV2({
  cwd,
  uuid = true,
  maxSize,
  maxFiles,
  handleUploadResponse,
  fileType,
  width,
  height,
  setTriggerRefresh,
}) {
  const [droppedFiles, setDroppedFiles] = useState([])

  const [uploadProgress, setUploadProgress] = useState({})
  const [dragEnter, setDragEnter] = useState(false)

  const dispatch = useDispatch()

  const handleSuccess = msg => dispatch(Alert({ success: msg }))
  const handleError = error => dispatch(Alert({ error }))

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      droppedFiles.forEach(
        file => file.preview && URL.revokeObjectURL(file.preview)
      )
    },
    [droppedFiles]
  )
  const getMaxSize = () => {
    return (maxSize / 1024 / 1024).toFixed(0) + ' MB'
  }
  const doUpload = () => {
    SetS3Config('my-asset-map-data', 'public')

    droppedFiles.forEach(uploadedFile => {
      Storage.put(`${cwd}/${uploadedFile.name}`, uploadedFile, {
        contentType: uploadedFile.type,
        completeCallback: event => {
          // console.log(`Successfully uploaded ${event.key}`)
        },
        progressCallback: progress => {
          // console.log(uploadedFile.type)
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
        },
        errorCallback: err => {
          // console.error('Unexpected error while uploading', err)
        },
      })
        .then(result => setTriggerRefresh(true))
        .catch(err => {
          handleError('There was an issue uploading your file: ' + err.message)
          console.error('There was an issue uploading your file: ', err)
        })
    })
  }

  // uploadProgressAmount = {
  //   ...uploadProgressAmount,
  //   [uploadedFileName]: progress.loaded,
  // }
  // uploadProgressTotal = {
  //   ...uploadProgressTotal,
  //   [uploadedFileName]: progress.total,
  // }
  // uploadProgressTime = {
  //   ...uploadProgressTime,
  //   [uploadedFileName]: progress.timeStamp,
  // },

  const onDropRejected = rejectedFiles => {
    if (rejectedFiles && rejectedFiles.length) {
      rejectedFiles.forEach(file => {
        const errorList = file.errors.map(err => {
          if (err.code === 'file-too-large')
            return 'File exceeds size limit of ' + getMaxSize()
          return err.message
        })
        handleError(`Error: \n` + errorList.join(';'))
      })
    }
  }

  const onDrop = acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length) {
      let newFiles
      if (maxFiles && maxFiles === 1) {
        newFiles = [...acceptedFiles]
      } else {
        if (maxFiles && maxFiles < acceptedFiles.length + droppedFiles.length)
          return handleError('Error: Too many files')

        newFiles = [...droppedFiles]

        const currentFileKeys = droppedFiles.map(file => file.name)
        acceptedFiles.forEach(file => {
          if (!currentFileKeys.includes(file.name)) {
            newFiles = utils.addVal(newFiles, file)
          } else
            handleError(
              'Error: A file with this name is already being uploaded. If this is a different file, please rename the file before uploading.'
            )
        })
      }

      // Save Files to State
      setDroppedFiles(newFiles)
    }
  }

  const onDragEnter = () => {
    setDragEnter(true)
  }

  const onDragLeave = () => {
    setDragEnter(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: fileType,
    minSize: 0,
    maxSize,
    maxFiles,
    onDrop,
    onDropRejected,
    onDragEnter,
    onDragLeave,
  })

  useEffect(() => {
    doUpload()
  }, [droppedFiles])

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      droppedFiles.forEach(
        file => file.preview && URL.revokeObjectURL(file.preview)
      )
    },
    [droppedFiles]
  )

  return (
    <>
      <section className={scss.dropzone}>
        <span
          style={{
            height: '300px',
            width: '300px',
            backgroundColor: dragEnter ? '#3C5266' : '#273e53',
            borderRadius: '50%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <div
            {...getRootProps({ className: 'dropzone' })}
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <input {...getInputProps()} />
            <span
              style={{
                color: 'white',
                fontSize: '18px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {dragEnter ? (
                <>
                  <span
                    style={{
                      textAlign: 'center',
                      color: 'white',
                      fontSize: '14px',
                    }}
                  >
                    Drop files to instantly upload them
                  </span>
                </>
              ) : (
                <>
                  Drop your file{maxFiles && maxFiles !== 1 ? 's' : ''} here
                  <div
                    style={{
                      fontSize: '12px',
                      display: 'inline-flex',
                      height: '100%',
                      alignItems: 'center',
                    }}
                  >
                    or use the{' '}
                    <button type='text' className={scss.miniNewButton}>
                      &quot;New&quot;
                    </button>{' '}
                    button
                  </div>
                </>
              )}
            </span>
          </div>
        </span>
      </section>
    </>
  )
}
