import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tooltip'
import Icon from '../../../components/Icon/Icon'
import AsyncFetch from '../../../utilities/AsyncFetch'
import appScss from '../../App/App.scss'
import { apis } from '../../../config/apiConfig'
import SettingsSelectModal from './SettingsSelectModal'

const SettingsSelect = () => {
  const [displaySettings, setDisplaySettings] = useState(false)

  const displaySettingsModal = () => {
    setDisplaySettings(true)
  }

  return (
    <>
      {displaySettings ? (
        <SettingsSelectModal setDisplaySettings={setDisplaySettings} />
      ) : null}
      <button
        onClick={() => displaySettingsModal()}
        className=''
        data-tooltip-content='Export settings'
        data-tooltip-id='settings-selected'
        data-effect='solid'
        data-arrow-color='rgba(0, 0, 0, 0.68)'
        data-place='top'
      >
        <Icon icon={['fa', 'cog']} size='1x' />
      </button>
      <div className={appScss.reactToolTip}>
        <Tooltip id='settings-selected' getContent={dataTip => `${dataTip}`} />
      </div>
    </>
  )
}

export default SettingsSelect
