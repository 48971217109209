import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../components/Icon/Icon'
import Switch from '@material-ui/core/Switch'
import { Tooltip } from 'react-tooltip'
import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import scss from './SettingsSelectModal.scss'
import { setBoundsSettings } from '../../../actions'
import appScss from '../../App/App.scss'

const Settings = ({ setDisplaySettings }) => {
  const dispatch = useDispatch()
  const bounds = useSelector(state => state.exportSettings.bounds)
  const [checked, setChecked] = useState(bounds)
  const handleChange = () => setChecked(!checked)

  const handleClose = () => {
    setDisplaySettings(false)
  }

  useEffect(() => {
    dispatch(setBoundsSettings({ bounds: checked }))
  }, [checked])

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog onClose={handleClose} aria-labelledby='new-layer-dialog' open>
        <DialogTitle id='new-layer-title'>
          Export Settings
          <div className={scss.wrapper} style={{ minWidth: '75px' }}>
            <div style={{ cursor: 'pointer' }} onClick={handleClose}>
              <Icon icon='times' size='sm' pull='right' />
            </div>
          </div>
        </DialogTitle>

        <DialogContent className={scss['new-layer-body']}>
          <div
            className={scss.wrapper}
            style={{ display: 'inline-flex', alignItems: 'center' }}
          >
            <div className={scss.label}>Bounds: </div>
            <Switch
              data-tooltip-id='bounds-button'
              data-tooltip-content='Clip data at map bounds'
              checked={checked}
              onChange={handleChange}
              style={{ marginTop: '2px' }}
            />
            <div className={appScss.reactToolTip}>
              <Tooltip
                id={'bounds-button'}
                getContent={dataTip => `${dataTip}`}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}

export default Settings
