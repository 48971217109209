import React from 'react'
import Icon from '../../../../components/Icon/Icon'
import Modal from 'react-responsive-modal'
import scss from './ConfirmDelete.scss'
import appScss from '../../../App/App.scss'
import modalScss from '../../../CSSModules/Modal.scss'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../../components/MaterialUi/Tool'
import { MuiButton } from '../../../../components/MaterialUi/Dialog'

import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function ConfirmDelete({
  modalOpen,
  closeModal,
  doingFunction,
  message,
  title,
  confirmFunction,
}) {
  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={closeModal}
        aria-labelledby='confirm-delete-dialog'
        open={modalOpen}
      >
        <DialogTitle id='confirm-delete-dialog'>
          {title}
          <Icon
            onClick={closeModal}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent className={modalScss.MuiDialogBoxes}>
          <div className={null}>
            <div className={null}>{message}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className={scss.modalSaveButton}>
            <button
              className={appScss.blueButton}
              onClick={doingFunction ? null : confirmFunction}
            >
              {doingFunction ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Confirm'
              )}
            </button>
          </div>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}
export default ConfirmDelete
