import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import MakeDataConfigComponent from '../../../../utilities/dataConfig'
import * as utils from '../../../../utilities/util'

import modalScss from '../../../CSSModules/Modal.scss'
import appScss from '../../../App/App.scss'
import Icon from '../../../../components/Icon/Icon'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ onClose, layerLabel, layerId }) {
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [open, setOpen] = useState(true)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)

  const buildFetchParams = duplicateData => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'layer/copy'
    let body = {
      layerID: layerId,
      duplicateData: duplicateData,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        setMountMakeDataConfig(true)
      }
    })
    handleOnClose()
    setFetching(false)
    setFetchObjects(null)
  }

  const handleCopy = duplicateData => {
    buildFetchParams(duplicateData)
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  const handleOnFinish = () => {
    setOpen(false)
    setFetching(false)
  }

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  return (
    <>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent onFinish={handleOnFinish} />
      )}
      <ThemeProvider theme={mainModalTheme}>
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='copy-layer-dialog'
          open={open}
          maxWidth='md'
        >
          <DialogTitle id='copy-layer'>
            Clone Layer
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent className={modalScss.MuiDialogBoxes}>
            <p style={{ marginBottom: '21px' }}>
              You are duplicating the layer: <i>{layerLabel}</i>
            </p>

            <b>Duplicate:</b>
            <p style={{ marginBottom: '8px' }}>
              Style and data will be copied to the new layer. Data does not sync
              <br /> when changes are made
            </p>

            <b>Duplicate & Sync Data:</b>
            <p style={{ marginBottom: '32px' }}>
              Style and data will be copied to the new layer. Changes made
              <br /> to either the original layer or copied will update each
              layer.
            </p>
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>

            <div className={modalScss.modalRow}>
              <button
                className={appScss.blueButton}
                onClick={fetching ? null : () => handleCopy(true)}
              >
                {fetching ? (
                  <Icon icon='spinner' size='1x' fixedWidth spin />
                ) : (
                  'Duplicate'
                )}
              </button>

              <button
                className={appScss.blueButton}
                onClick={fetching ? null : () => handleCopy(false)}
                style={{ marginLeft: '8px' }}
              >
                {fetching ? (
                  <Icon icon='spinner' size='1x' fixedWidth spin />
                ) : (
                  'Duplicate & Sync'
                )}
              </button>
            </div>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function DuplicateLayer(props) {
  return <SimpleDialog {...props} />
}
