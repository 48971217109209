import React from 'react'
import scss from './ElementButtons.scss'
import Icon from '../../../../../components/Icon/Icon'

function ElementButtons(props) {
  const object = props.object
  const handleDeleteElement = props.handleDeleteElement
  const copyText = props.copyText
  const handleRefreshItemLegend = props.handleRefreshItemLegend

  return (
    <>
      <div className={scss.elementButtons}>
        <button className={scss.elementButton}>
          <Icon icon={['fal', 'arrows-alt']} size='1x' />
        </button>

        {handleRefreshItemLegend && (
          <button
            onClick={handleRefreshItemLegend}
            className={scss.elementButton}
          >
            <Icon icon={['fal', 'sync']} size='1x' />
          </button>
        )}

        {copyText && (
          <button
            className={[scss.elementButton, 'text'].join(' ')}
            onClick={() => copyText(object.id)}
          >
            <Icon icon={['fal', 'copy']} size='1x' />
          </button>
        )}

        <button
          id={`remove-${object.id}`}
          onClick={() => handleDeleteElement(object.id)}
          className={scss.elementButton}
        >
          <Icon icon={['fal', 'trash-alt']} size='1x' />
        </button>
      </div>
    </>
  )
}
export default ElementButtons
