/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { Storage } from 'aws-amplify'

import Icon from '../../../../components/Icon/Icon'
import TabNav from '../TabNav/TabNav.jsx'
import DocumentBrowserV2 from '../../../DocumentBrowserV2/DocumentBrowserV2.jsx'

import { getAttributesThatBelongToTab } from '../TabTemplateUtil.js'
import scss from '../TabTemplate.scss'

// utilities
import * as utils from '../../../../utilities/util.jsx'

const DocumentAttributeTab = ({
  tabs,
  tabAttribute,
  tabsPerPage,
  currentPage,
  activeTabIndex,
  setActiveTabIndex,
  handleAllChanges,
  enlargeWindow,
  featureProperties,
}) => {
  const openFileOnAnotherWindow = async awsPath => {
    awsPath = awsPath.replace('|', '')
    const signedURL = await Storage.get(awsPath)
    window.open(signedURL)
  }

  const [documentFeature, setDocumentFeature] = useState(null)
  const [attributeForThisTab, setAttributeForThisTab] = useState(
    getAttributesThatBelongToTab(tabs[activeTabIndex], tabAttribute) || []
  )
  const [newTabs, setNewTabs] = useState(tabs)

  //  When a new layer has been created, we create a default tab to throw all the attributes in there
  const generateValues = () => {
    const newTabsValues = newTabs.filter(tab => tab.id !== 'Documents')
    if (newTabsValues && newTabsValues.length === 0) {
      setAttributeForThisTab([])
      setNewTabs([
        {
          id: 'Documents',
          title: 'Documents',
          itemsIds: [],
        },
      ])
      const allAttributesIds = tabAttribute.filter(
        item => item.type === 'document (Feature)'
      )
      setAttributeForThisTab([...allAttributesIds])
    }
  }

  useEffect(() => {
    const newTabsValues = newTabs.filter(tab => tab.id !== 'Documents')
    if (newTabsValues && newTabsValues.length === 0) {
      generateValues()
    }
  }, [])

  //  When a new layer has been created, we create a default tab to throw all the attributes in there
  useEffect(() => {
    const newTabsValues = tabs.filter(tab => tab.id !== 'Documents')
    if (newTabsValues.length > 0) {
      setAttributeForThisTab(
        getAttributesThatBelongToTab(tabs[activeTabIndex], tabAttribute)
      )
      setNewTabs(tabs)
    } else {
      setAttributeForThisTab([])
      setNewTabs([
        {
          id: 'Documents',
          title: 'Documents (Unassigned)',
          itemsIds: [],
        },
      ])
      const allAttributesIds = tabAttribute.filter(
        item => item.type === 'document (Feature)'
      )
      setAttributeForThisTab([...allAttributesIds])
    }
  }, [tabs, tabAttribute, activeTabIndex])

  const getFileName = link => {
    let fileName = 'N/A'

    fileName = link.split('/')
    fileName = fileName[fileName.length - 1]
    fileName = fileName.replace('|', '')

    return fileName
  }

  return (
    <>
      <TabNav
        tabs={newTabs}
        tabsPerPage={tabsPerPage}
        currentPage={currentPage}
        activeTabIndex={activeTabIndex}
        setActiveTabIndex={setActiveTabIndex}
      />
      <div
        style={{
          border: 'solid 1px #213445',
          borderRadius: '6px',
          boxShadow: 'inset 1px 1px 3px 1px rgba(0,0,0,0.5)',
          overflow: 'overlay',
          padding: '10px 11px',
          height: enlargeWindow ? '300px' : '200px',
          minWidth: 'fit-content',
        }}
      >
        {attributeForThisTab && attributeForThisTab.length > 0 ? (
          attributeForThisTab.map(property => (
            <div
              key={utils.makeUniqueIDNumbersOnly(3)}
              className={scss['attribute-grid-row']}
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div
                title={property.name}
                style={{
                  maxWidth: '145px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {property.name}
              </div>
              <div style={{ maxWidth: '200px' }}>
                {featureProperties[property.key] ? (
                  <button
                    type='button'
                    target='_blank'
                    style={{
                      borderStyle: 'none',
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                    }}
                    onClick={() => {
                      openFileOnAnotherWindow(featureProperties[property.key])
                    }}
                    className={scss.CustomLink}
                    title={getFileName(featureProperties[property.key])}
                  >
                    {getFileName(featureProperties[property.key])}
                  </button>
                ) : (
                  <span style={{ fontSize: 'smaller' }}>
                    Click <Icon icon='link' /> to link file
                  </span>
                )}
              </div>
              <span style={{ padding: '0 15px' }}>
                {featureProperties[property.key] ? (
                  <Icon
                    icon='unlink'
                    onClick={e => {
                      e.stopPropagation()
                      handleAllChanges(property.key, '', '')
                    }}
                    className={scss.CustomUnlink}
                    title='Unlink file'
                  />
                ) : (
                  <Icon
                    icon='link'
                    onClick={e => {
                      e.stopPropagation()
                      setDocumentFeature(property)
                    }}
                    className={scss.CustomLink}
                    title='Link file'
                  />
                )}
              </span>
            </div>
          ))
        ) : (
          <div style={{ lineHeight: '25px' }}>
            There are no Document Attributes in your tab. <br />
          </div>
        )}
      </div>
      {documentFeature && (
        <DocumentBrowserV2
          onClose={() => setDocumentFeature(null)}
          typeDocumentKey={documentFeature.key}
          handleDocumentChange={handleAllChanges}
          linkMode
        />
      )}
    </>
  )
}

export default DocumentAttributeTab
