import React from 'react'
import { useDispatch } from 'react-redux'
import { updateMapStyle } from '@/actions/index'
import { fromJS } from 'immutable'
import { SELECTED_FEATURES } from '@/data/Layers/Auxiliary/SelectedFeatures'
import * as mapUtils from '@/utilities/mapStyle'
import Icon from '@/components/Icon/Icon'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
} from '@/styles/tailwind-utilities'
import { faTrashCanUndo } from '@fortawesome/pro-light-svg-icons'

const ClearSelection = React.memo(
  ({ mapStyle, setClickedFeature, closePopup }) => {
    const dispatch = useDispatch()

    const emptySelectSources = style => {
      SELECTED_FEATURES.sourcesArray.forEach(measureSource => {
        let source = mapUtils.getSource(style, measureSource.id)
        source = mapUtils.emptySource(source)
        style.sources[measureSource.id] = source
      })
      return style
    }

    const clearSelection = () => {
      // set clicekd feature in map state to null
      setClickedFeature(null)
      // close popup
      closePopup()
      // get all tocLayers and set visibility to none
      let style = mapStyle.toJS()
      let clickedFeatureLayer = style.layers.filter(
        layer => layer.id === 'clicked_feature'
      )
      clickedFeatureLayer[0].source = 'clicked_feature'
      if (clickedFeatureLayer[0]['source-layer']) {
        delete clickedFeatureLayer[0]['source-layer']
      }
      style = emptySelectSources(style)
      // TODO :: Clear Visible Popup
      dispatch(updateMapStyle(fromJS(style)))
    }

    return (
      <button
        onClick={clearSelection}
        className={`${buttonBase} ${mapControlButton} ${mapControlButtonColors.default} rounded-b-xl rounded-t-none border-t border-primary-hover -mt-[1px]`}
      >
        <Icon icon={faTrashCanUndo} size='xl' />
      </button>
    )
  }
)

export default ClearSelection
