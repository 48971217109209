import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateMapStyle } from '../../../actions/index'
import Icon from '../../../components/Icon/Icon'
import { fromJS } from 'immutable'
import * as geoUtils from '../../../utilities/geospatial'
import scss from './NearMap.scss'
import BlockPicker from '../../../components/BlockPicker/BlockPicker'

export default function NearMapCoverage({
  mapRef,
  enableMap,
  disableMap,
  searchVisible,
  toggleNearCoverage,
}) {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const user = useSelector(state => state.user)
  let style = mapStyle.toJS()
  const map = mapRef.getMap()
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedCoverage, setSelectedCoverage] = useState(null)
  const [availableDates, setAvailableDates] = useState(null)
  const nearMapBaseUrl =
    'https://api.nearmap.com/tiles/v3/Vert/{z}/{x}/{y}.jpg?'
  const getCoverage = () => {
    const bounds = map.getBounds()
    const bbox = [
      bounds._sw.lng,
      bounds._sw.lat,
      bounds._ne.lng,
      bounds._ne.lat,
    ]

    const poly = geoUtils.getBBoxPoly(bbox)
    const coords = poly.geometry.coordinates[0]
    const NEARMAP_KEY = user.accounts[0].nearmap
    const coverageUrl = `https://api.nearmap.com/coverage/v2/poly/${coords[0][0]},${coords[0][1]},${coords[1][0]},${coords[1][1]},${coords[2][0]},${coords[2][1]},${coords[3][0]},${coords[3][1]},${coords[0][0]},${coords[0][1]}?apikey=${NEARMAP_KEY}`

    fetch(coverageUrl)
      .then(res => {
        return res.json()
      })
      .then(res => {
        if (res.surveys) {
          processSurveys(res.surveys)
        } else {
          processSurveys(res.surveys)
          setSelectedBlock(null)
        }
      })
  }

  const clearSelected = () => {
    setSelectedBlock(null)
    setSelectedCoverage(null)
    const url = updateNearMapUrl(
      nearMapBaseUrl,
      'apikey',
      user.accounts[0].nearmap
    )
    updateStyle(url)
  }

  const processSurveys = surveys => {
    const dates = surveys.map(survey => {
      return {
        value: survey.captureDate,
        label: survey.captureDate,
      }
    })
    setAvailableDates(dates)
  }

  const handleBlockClick = clickedBlock => {
    let url = updateNearMapUrl(nearMapBaseUrl, 'until', clickedBlock.value)
    url = updateNearMapUrl(url, 'since', clickedBlock.value)
    url = updateNearMapUrl(url, 'apikey', user.accounts[0].nearmap)
    updateStyle(url)
    setSelectedBlock(clickedBlock)
    setSelectedCoverage(clickedBlock)
  }

  const updateNearMapUrl = (url, param, value) => {
    const newUrl = `${url}&${param}=${value}`
    return newUrl
  }

  const updateStyle = url => {
    let nearMapSource = style.sources['aerial_nearmap']
    nearMapSource.tiles[0] = url
    dispatch(updateMapStyle(fromJS(style)))
  }

  const close = () => {
    enableMap()
    toggleNearCoverage()
  }

  const controlGroupStyle = {
    top: searchVisible ? '75px' : '26px',
  }

  useEffect(function () {}, [])

  return (
    <div>
      <div
        style={controlGroupStyle}
        className={scss.mapTopControlGroup}
        onMouseEnter={disableMap}
        onMouseLeave={enableMap}
      >
        <div className={scss.coverageContainer}>
          <div className={scss.header}>
            <div>
              <button onClick={close} title='Close'>
                <Icon icon={['fal', 'times']} size='1x' />
              </button>
              <button
                onClick={() => getCoverage()}
                title='Get Available Imagery'
              >
                Get
              </button>
              <button onClick={() => clearSelected()} title='Clear'>
                Clear
              </button>
            </div>

            <div className={scss.title}>
              <Icon icon={['fa', 'plane']} size='1x' />
              <span>History</span>
              {selectedCoverage && (
                <div>: {selectedCoverage.label} Selected</div>
              )}
            </div>
          </div>

          <div>
            <div className={scss.blockContainer}>
              {availableDates ? (
                availableDates.length ? (
                  <div>
                    <BlockPicker
                      blocks={availableDates}
                      onClick={handleBlockClick}
                      selected={selectedBlock}
                    />
                  </div>
                ) : (
                  <div>Near Map Not available for this area</div>
                )
              ) : (
                <div>Click the Get Button to show available coverage</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}