import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import {
  setToolConfig,
  setDataTable,
  mountLayerSettings,
} from '../../../../actions/index'
import { getLayerConfigUsingTocId } from '../../../../utilities/dataConfig'
import * as utils from '../../../../utilities/util'

import Icon from '../../../../components/Icon/Icon'
import { Tooltip } from 'react-tooltip'

import ParentGroupSelect from '../../ParentGroupSelect/ParentGroupSelect'
// import ShareMapView from "./ShareMapView";
import MapViewDelete from './MapViewDelete'
import ShareModal from '../../ShareModal/ShareModal'

import scss from './MapViewInfo.scss'
import appScss from '../../../App/App.scss'
import toolScss from '../../../../_tools.scss'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../../components/MaterialUi/Tool'
import MakeDataConfigComponent from '../../../../utilities/dataConfig'

import { ReactComponent as SharedLayerIcon } from '../../../../assets/icons/tools/SharedLayerGroup.svg'

import { ReactComponent as ArchiveIcon } from '../../../../assets/icons/tools/Archive.svg'
import { ReactComponent as SaveIcon } from '../../../../assets/icons/tools/Save.svg'
import { ReactComponent as ShareIcon } from '../../../../assets/icons/tools/Share.svg'

import logoDark from '../../../../assets/logo/logo-dark.svg'
import organizationLayerGroup from '../../../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../../../assets/icons/userLayerGroup.svg'

import mainPanelTheme from '../../../../utilities/componentConstants/mainPanelTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import ParentMapViewGroupSelect from '../ParentMapViewGroupSelect'

export default function MapViewInfo({ closeTool, config, mountKey }) {
  if (!config || !config.targetMapView) return null
  const toolConfig = useSelector(state => state.toolConfig)
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const currentView = config.targetMapView
  const currentViewID = currentView.id
  var owner = currentView.owner
  var role = currentView.role
  var defaultRole = 'Viewer'

  const sharedType = currentView.type
  const canView = currentView.canView || user.isAdmin
  const canEdit = currentView.canEdit || user.isAdmin
  const canDelete = currentView.canDelete || user.isAdmin

  //   const layerId = config.layer.layersArray[0].layer.id;
  //   const type = config.layer.layersArray[0].layer.type;

  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mapViewDeleteVisible, setMapViewDelete] = useState(false)
  const [mapViewLabel, setMapViewLabel] = useState(currentView.label)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [mapViewDescription, setMapViewDescription] = useState(
    currentView.description
  )
  const [shareMapViewVisible, setShareMapView] = useState(false)
  const [enableSave, setEnableSave] = useState(false)

  const onLabelChange = e => {
    setMapViewLabel(e.target.value)
    setEnableSave(true)
  }
  const onDescriptionChange = e => {
    setMapViewDescription(e.target.value)
    setEnableSave(true)
  }

  const openMapViewDelete = mapViewDeleteVisible => {
    setMapViewDelete(mapViewDeleteVisible)
  }

  const openShareMapView = shareMapViewVisible =>
    setShareMapView(shareMapViewVisible)

  const doCancel = () => {
    setMapViewLabel(currentView.label)
    setMapViewDescription(currentView.description)
  }

  const buildFetchParams = id => {
    const mapViewID = currentViewID
    let method
    let url
    let body = {}

    if (mapViewID.substr(0, 6) === 'group_') {
      if (id === 'delete') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/group/delete'
        body = {
          groupID: mapViewID,
        }
      } else if (id === 'save') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/group/update'
        body = {
          groupID: mapViewID,
          label: mapViewLabel,
          description: mapViewDescription,
        }
      }
    } else {
      if (id === 'delete') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/delete'
        body = {
          mapViewID: mapViewID,
        }
      } else if (id === 'save') {
        method = 'POST'
        url = apis['apiDatabase'].uri + 'mapview/update'
        body = {
          mapViewID: mapViewID,
          label: mapViewLabel,
          description: mapViewDescription,
        }
      }
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = () => {
    // archive response
    setMountMakeDataConfig(true)
    closeTool(config.name)

    config.updateMapViews()
    setFetchObjects(null)
  }

  const doSave = () => {
    buildFetchParams('save')
  }

  const doArchive = () => {
    buildFetchParams('archive')
  }

  const doDelete = () => {
    buildFetchParams('delete')
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  const handleOnFinish = dataConfig => {
    setFetching(false)
    updateToolConfig(dataConfig)
  }

  const updateToolConfig = dataConfig => {
    // the data for the layer has changed (a new description or layer name)
    // the purpose of this function is to update app state data config with the new layer information
    // and remount the component to preserve the changes
    const updatedLayer = getLayerConfigUsingTocId(dataConfig, currentView)

    // add the newly updated layer to the toolConfig
    if (updatedLayer) {
      toolConfig.forEach(config => {
        if (config.name === 'MapViewSettings') {
          config.layer = updatedLayer
        }
      })
      dispatch(setToolConfig(toolConfig))
      // force the component to remount by updating the mount key
      mountKey++
      dispatch(mountLayerSettings(mountKey))
    }
  }

  //   if (sharedType === "user") {
  //     layerShare = userLayerGroup;
  //     layerStyle = { width: "30px", marginLeft: "-10px", marginBottom: "-4px" };
  //   } else if (sharedType === "shared") {
  //     layerShare = sharedLayerGroup;
  //   } else if (sharedType === "org") {
  //     layerShare = organizationLayerGroup;
  //   } else if (sharedType === "mam") {
  //     owner = "MyAssetMap";
  //     layerShare = logoDark;
  //     layerStyle = { width: "20px", marginBottom: "5px" };
  //   } else {
  //     layerShare = logoDark;
  //     layerStyle = { width: "20px", marginBottom: "5px" };
  //   }

  // let role = "Viewer";
  // if (canView) role = "Viewer";
  // if (canEdit) role = "Editor";

  //   let layerIdForDisplay = layerId.split("_");
  //   layerIdForDisplay = layerIdForDisplay[layerIdForDisplay.length - 1];

  return (
    <ThemeProvider theme={mainPanelTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <div
        className={[
          scss.container,
          scss['panel-column'],
          scss['panel-justify-space-between'],
          scss['padding-zero'],
          scss['full-height'],
        ].join(' ')}
      >
        <div className={[scss['panel-column'], scss['panel-share']].join(' ')}>
          <span className={scss['panel-colon-label']}>
            Shared by: <span className={scss['panel-colon-text']}>{owner}</span>
          </span>
          <span className={scss['panel-colon-label']}>
            Your Permissions:{' '}
            <span className={scss['panel-colon-text']}>
              {utils.getRole(role, defaultRole)}
            </span>
          </span>
        </div>

        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={handleOnFinish} />
        )}
        {mapViewDeleteVisible && (
          <MapViewDelete
            fetching={fetching}
            setFetching={setFetching}
            doDelete={doDelete}
            onClose={() => openMapViewDelete(false)}
            mapViewLabel={mapViewLabel}
            mapViewID={currentViewID}
          />
        )}
        {shareMapViewVisible && (
          <ShareModal
            onClose={() => openShareMapView(false)}
            itemId={currentViewID}
            dataType={
              currentViewID.substr(0, 6) === 'group_'
                ? 'mapViewGroup'
                : 'mapView'
            }
          />
        )}
        {!canEdit && (
          <div
            className={[scss['panel-column'], scss['panel-description']].join(
              ' '
            )}
          >
            <span className={scss['panel-colon-label']}>
              Name:{' '}
              <span className={scss['panel-colon-text']}>{mapViewLabel}</span>
            </span>
            <span className={scss['panel-colon-label']}>
              Description:{' '}
              <span className={scss['panel-colon-text']}>
                {mapViewDescription}
              </span>
            </span>
          </div>
        )}
        <div>
          {canEdit && (
            <div
              className={[
                scss['panel-column'],
                scss['panel-edit-description'],
              ].join(' ')}
            >
              <label
                htmlFor='map-view-settings-layer-name'
                className={appScss['input-notched']}
              >
                <span>Map View Name</span>
                <input
                  id='map-view-settings-layer-name'
                  onChange={onLabelChange}
                  value={mapViewLabel}
                  placeholder='Input text'
                />
              </label>

              <label
                htmlFor='map-view-settings-layer-description'
                className={appScss['input-notched']}
              >
                <span>Description</span>
                <textarea
                  id='map-view-settings-layer-description'
                  value={mapViewDescription}
                  onChange={onDescriptionChange}
                  placeholder='Input text'
                />
              </label>
            </div>
          )}

          <div className={scss['panel-group-select']}>
            <ParentMapViewGroupSelect config={config} closeTool={closeTool} />
          </div>
        </div>

        <button
          className={[
            appScss.fullWidthButton,
            scss.layerInfoSave,
            scss[!canEdit ? 'layerInfoSaveInactive' : ''],
          ].join(' ')}
          onClick={canEdit ? () => doSave() : null}
        >
          {fetching ? (
            <Icon icon={'spinner'} size='1x' spin />
          ) : (
            'Save'
          )}
        </button>

        {/* <div className={toolScss.toolIcons}>

            <div className={toolScss.toolIconLeftRow}>
              <div
                className={[toolScss.toolIconBox, toolScss.toolTrashIcon].join(" ")}
                onClick={() => openMapViewDelete(true)}
                data-tooltip-content="Delete"
                data-tooltip-id="delete"
                data-effect="solid"
                data-arrow-color="rgba(0, 0, 0, 0.68)"
              >
                <Icon icon={["fas", "trash"]} size="1x" />
                <div className={appScss.reactToolTip}>
                    <Tooltip id="delete" getContent={(dataTip) => `${dataTip}`} />
                </div>
              </div>
            </div>

            <div className={toolScss.toolIconRightRow}>
              <div
                className={toolScss.toolIconBox}
                onClick={() => doSave()}
                data-tooltip-content="Save"
                data-tooltip-id="save"
                data-effect="solid"
                data-arrow-color="rgba(0, 0, 0, 0.68)"
              >
                <SaveIcon className={toolScss.saveIcon} />
                <div className={appScss.reactToolTip}>
                  <Tooltip id="save" getContent={(dataTip) => `${dataTip}`} />
                </div>
              </div>
              
              <div
                className={[toolScss.toolIconBox, toolScss.toolCloneIcon, toolScss[!canEdit ? 'inactive' : '']].join(" ")}
                onClick={canEdit ? () => openShareMapView(true) : null}
                data-tooltip-content="Share"
                data-tooltip-id="share"
                data-effect="solid"
                data-arrow-color="rgba(0, 0, 0, 0.68)"
              >
                <Icon icon={["fa", "share-alt"]} size="1x" />
                <div className={appScss.reactToolTip}>
                  <Tooltip id="share" getContent={(dataTip) => `${dataTip}`} />
                </div>
              </div>
            </div>
        </div> */}
      </div>
    </ThemeProvider>
  )
}
