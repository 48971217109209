/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, { useState, useEffect, useMemo } from 'react'
import { useTable, useSortBy, useRowSelect, useExpanded } from 'react-table'
import Icon from '../../../../components/Icon/Icon'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

import * as DocumentBrowserUtil from '../../../DocumentBrowserV2/DocumentBrowserV2Util'
import { apiFetch } from '../../../../utilities/api'
import { apis } from '../../../../config/apiConfig'
import AttributeRow from './AttributeRow/AttributeRow'
import EditAttribute from './AttributeRow/EditAttribute/EditAttribute.jsx'
import DeleteAttributePopup from './AttributeRow/DeleteAttributePopup/DeleteAttributePopup'
import AddAttribute from './AddAttribute/AddAttribute'

import LayerSettingsV2 from '../LayerSettingsV2.scss'
import scss from './ManageAttributes.scss'

export const GenerateAddAttributeButton = ({
  type = 'row',
  onClick = null,
}) => {
  const buttonClass = [scss.addDocumentButton]
  const buttonContents = [<Icon icon='plus' />]

  switch (type) {
    case 'row':
      buttonClass.push(scss.addDocumentButtonRowParent)
      buttonContents.push(<span>Create new attribute</span>)
      break
    case 'circle':
      buttonClass.push(scss.addDocumentButtonCircle)
      break
    default:
      break
  }

  return (
    <>
      <button
        className={buttonClass.join(' ')}
        type='button'
        title='Add Features Attributes'
        onClick={onClick}
      >
        {buttonContents}
      </button>
    </>
  )
}

export const AttributeSkeletonLoader = ({
  height = 20,
  count = 3,
  margin = '8px',
}) => {
  return (
    <tr className={scss.loadingSkeleton}>
      <td style={{ border: 'none' }}>
        <SkeletonTheme color='#314d68' highlightColor='#adc6d1'>
          <Skeleton
            height={height}
            count={count}
            style={{ marginBottom: margin }}
          />
        </SkeletonTheme>
      </td>
      <td style={{ border: 'none' }}>
        <SkeletonTheme color='#314d68' highlightColor='#adc6d1'>
          <Skeleton
            height={height}
            count={count}
            style={{ marginBottom: margin }}
          />
        </SkeletonTheme>
      </td>
    </tr>
  )
}

const ManageAttributes = ({ config }) => {
  const [layerProperties, setLayerProperties] = useState(null)
  const [currentFilter, setCurrentFilter] = useState('')
  const [displayEditAttributeTab, setDisplayEditAttributeTab] = useState(false)

  const [displayAddAttribute, setDisplayAddAttribute] = useState(false)
  const [displayManageAttributes, setDisplayManageAttributes] = useState(true)

  const [loading, setLoading] = useState(false)
  const [numHeaderClicks, setNumHeaderClicks] = useState(1)
  const [data, setData] = useState([])
  const [displayDeleteAttributePopup, setDisplayDeleteAttributePopup] =
    useState(false)

  const [useBigTimeGrouping, setUseBigTimeGrouping] = useState(false)
  const [targetProperty, setTargetProperty] = useState(null)

  const getProperties = () => {
    setLoading(true)

    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/properties/get'
    const bodyParams = {
      layerID: layerId,
    }
    apiFetch(url, method, bodyParams, result => {
      setLoading(false)
      if (result.success) {
        setLayerProperties(result.data)
      } else {
        setLayerProperties([])
      }
    })
  }

  const Table = ({
    columns,
    data,
    handleHeaderClick,
    renderRowSubComponent,
    useBigTimeGrouping,
  }) => {
    const getIconBasedOnSortType = (column, useBigTimeTypeGroupingIcon) => {
      // This grouping has no icon for the time being
      if (useBigTimeTypeGroupingIcon) return ' '

      if (currentFilter !== column.id) return ''

      return column.isSorted ? (
        column.isSortedDesc ? (
          <Icon
            className='fa-lg sort-icon'
            icon='caret-down'
            style={{
              color: 'white',
              marginLeft: '5px',
            }}
          />
        ) : (
          <Icon
            className='fa-lg sort-icon'
            icon='caret-up'
            style={{
              color: 'white',
              marginLeft: '5px',
            }}
          />
        )
      ) : (
        ''
      )
    }

    const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
      useTable(
        {
          columns,
          data,
        },
        useExpanded
      )

    const tableContents =
      rows && rows.length > 0 ? (
        <>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <AttributeRow
                key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                row={row}
                setDisplayEditAttributeTab={setDisplayEditAttributeTab}
                setTargetProperty={setTargetProperty}
                setDisplayDeleteAttributePopup={setDisplayDeleteAttributePopup}
                setDisplayManageAttributes={setDisplayManageAttributes}
              />
            )
          })}
        </>
      ) : (
        <tr style={{ display: 'flex', height: '300px' }}>
          <td style={{ border: 'none', width: '10%' }}></td>
          <td
            style={{
              border: 'none',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              width: '100%',
              textAlign: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
            }}
          >
            <GenerateAddAttributeButton
              type='circle'
              onClick={() => {
                setDisplayAddAttribute(true)
                setDisplayManageAttributes(false)
              }}
            />
            No feature attributes were found.
            <br />
            Would you like to create one?
          </td>
        </tr>
      )

    return (
      <>
        <table className={LayerSettingsV2.attributeTable} {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  <th
                    key={DocumentBrowserUtil.makeUniqueIDNumbersOnly(999)}
                    onClick={() => {
                      handleHeaderClick(column.id)
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span>{getIconBasedOnSortType(column, null)}</span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!loading ? tableContents : <AttributeSkeletonLoader />}
          </tbody>
        </table>
        {rows && rows.length > 0 ? (
          <GenerateAddAttributeButton
            onClick={() => {
              setDisplayAddAttribute(true)
              setDisplayManageAttributes(false)
            }}
          />
        ) : null}
      </>
    )
  }

  useEffect(() => {
    getProperties()
  }, [])

  useEffect(() => {
    if (layerProperties) {
      let processedLayerProperties = layerProperties

      processedLayerProperties = processedLayerProperties
        .map(row => {
          if (['document (Layer)', 'document (Feature)'].includes(row.type))
            return null

          // key is a reserved word for JS objects
          row.attrKey = row.key

          // delete row.id
          delete row.key
          // delete row.Layer
          // delete row.value
          return row
        })
        .filter(row => row !== null)

      setData(processedLayerProperties)
    }
  }, [layerProperties])

  const [columns, setColumns] = useState([
    {
      Header: 'Name',
      accessor: 'name',
      isSorted: false,
      isSortedDesc: false,
    },
    {
      Header: 'Type',
      accessor: 'type',
      isSorted: false,
      isSortedDesc: false,
    },
  ])

  /**
   * Three modes of organizing table data con be achieved with different number of clicks:
   * 1 click - sorts in ascending order
   * 2 click - sorts in descending order
   * 3 click - Only applicable to documents, groups by level
   * @param {} filterType = Name or type
   */
  const handleHeaderClick = filterType => {
    setCurrentFilter(filterType)

    let columnsValues
    let processedLayerProperties
    if (numHeaderClicks === 0) {
      columnsValues = columns.map(column => {
        column.isSorted = false
        column.isSortedDesc = false
        return column
      })

      getProperties()
      setColumns(columnsValues)
      setUseBigTimeGrouping(false)
    } else if (numHeaderClicks === 1) {
      columnsValues = columns.map(column => {
        column.isSorted = true
        column.isSortedDesc = false
        return column
      })
      processedLayerProperties = data
      processedLayerProperties = processedLayerProperties.sort((a, b) => {
        const textA = a[filterType].toUpperCase()
        const textB = b[filterType].toUpperCase()
        return textA > textB ? -1 : textA < textB ? 1 : 0
      })
      setData(processedLayerProperties)
      setColumns(columnsValues)
    } else if (numHeaderClicks === 2) {
      columnsValues = columns.map(column => {
        column.isSorted = true
        column.isSortedDesc = true
        return column
      })
      processedLayerProperties = data
      processedLayerProperties = processedLayerProperties.sort((a, b) => {
        const textA = a[filterType].toUpperCase()
        const textB = b[filterType].toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
      })
      setData(processedLayerProperties)
      setColumns(columnsValues)
      setUseBigTimeGrouping(false)
    }

    setNumHeaderClicks(numHeaderClicks + 1)
    if (numHeaderClicks >= 3) setNumHeaderClicks(0)
  }

  return (
    <>
      {displayAddAttribute ? (
        <AddAttribute
          config={config}
          getProperties={getProperties}
          cancelEdit={() => {
            setDisplayManageAttributes(true)
            setDisplayAddAttribute(false)
          }}
          targetProperty={targetProperty}
          allProperties={layerProperties}
        />
      ) : null}
      {displayDeleteAttributePopup ? (
        <DeleteAttributePopup
          config={config}
          targetProperty={targetProperty}
          setDisplayDeleteAttributePopup={setDisplayDeleteAttributePopup}
          getProperties={getProperties}
        />
      ) : null}
      {displayEditAttributeTab ? (
        <EditAttribute
          config={config}
          getProperties={getProperties}
          cancelEdit={() => {
            setDisplayManageAttributes(true)
            setDisplayEditAttributeTab(false)
          }}
          targetProperty={targetProperty}
          finishUpdate={() => {
            setDisplayManageAttributes(true)
            setDisplayEditAttributeTab(false)
          }}
        />
      ) : null}
      {displayManageAttributes ? (
        <>
          <Table
            columns={columns}
            data={data}
            handleHeaderClick={handleHeaderClick}
            useBigTimeGrouping={useBigTimeGrouping}
          />
        </>
      ) : null}
    </>
  )
}

export default React.memo(ManageAttributes)
