import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import AsyncFetch from '../../../../../utilities/AsyncFetch'
import { apis } from '../../../../../config/apiConfig'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import MakeDataConfigComponent from '../../../../../utilities/dataConfig'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../../../components/Icon/Icon'
import { makeStyles } from '@material-ui/core/styles'
import appScss from '../../../../App/App.scss'
import scss from '../HeaderButton.scss'
import { Alert } from '../../../../../actions'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      width: 350,
    },
  },
}))

const NewGroup = () => {
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const canSave = () => groupName && groupName.trim() !== ''

  const [enableSave, setEnableSave] = useState(() => canSave())

  const [newGroupOpen, setNewGroupOpen] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [groupName, setGroupName] = useState('')
  const [groupDescription, setGroupDescription] = useState('')

  const onNameChange = e => setGroupName(e.target.value)

  const onDescriptionChange = e => setGroupDescription(e.target.value)

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'group/add'
    let body = {
      label: groupName,
      description: groupDescription,
      mapID: user.mapID,
    }

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }

  const handleClick = () => {
    if (groupName.trim() === '') {
      return dispatch(
        Alert({ info: 'Required Field: Group Name is a required field.' })
      )
    }

    buildFetchParams()
  }

  const fetchFinished = results => {
    setFetchObjects(null)
    setFetching(false)
    setMountMakeDataConfig(true)

    setGroupName('')
    setGroupDescription('')
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(() => {
    setEnableSave(() => canSave())
  }, [groupName])

  const handleDataConfigFinish = () => {
    setNewGroupOpen(false)
    setFetching(false)
  }

  const handleCancel = () => {
    setNewGroupOpen(false)
  }

  const handleOnClose = () => {
    setNewGroupOpen(false)
  }

  return (
    <>
      {newGroupOpen && (
        <ThemeProvider theme={mainModalTheme}>
          {mountMakeDataConfig && (
            <MakeDataConfigComponent onFinish={handleDataConfigFinish} />
          )}
          <div className={classes.root}>
            <Dialog
              className={classes.root}
              onClose={handleOnClose}
              open={open}
            >
              <DialogTitle>
                New Group
                <Icon
                  onClick={handleOnClose}
                  icon='times'
                  size='sm'
                  pull='right'
                />
              </DialogTitle>
              <DialogContent>
                <label
                  htmlFor='new-group-name'
                  className={appScss['input-notched']}
                >
                  <span>Group Name</span>
                  <input
                    id='new-group-name'
                    onChange={onNameChange}
                    onSubmit={fetching ? null : handleClick}
                    value={groupName}
                    placeholder='Group Name'
                    style={{ textAlign: 'left' }}
                  />
                </label>

                <label
                  htmlFor='new-group-description'
                  className={appScss['input-notched']}
                  style={{ marginTop: '16px' }}
                >
                  <span>Group Description</span>
                  <textarea
                    id='new-group-description'
                    onChange={onDescriptionChange}
                    onSubmit={fetching ? null : handleClick}
                    value={groupDescription}
                    placeholder='Group Description'
                    style={{ textAlign: 'left' }}
                  />
                </label>
              </DialogContent>
              <DialogActions>
                <button
                  className={appScss.altBlueButton}
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                {fetchObjects && (
                  <AsyncFetch
                    fetchObjects={fetchObjects}
                    fetchFinished={fetchFinished}
                  />
                )}
                <button
                  className={
                    enableSave
                      ? appScss.blueButton
                      : [appScss.blueButton, appScss.disabled].join(' ')
                  }
                  onClick={fetching ? null : handleClick}
                >
                  {fetching ? (
                    <Icon icon='spinner' size='1x' fixedWidth spin />
                  ) : (
                    'Save'
                  )}
                </button>
              </DialogActions>
            </Dialog>
          </div>
        </ThemeProvider>
      )}
      {user.mapID && user.permissions.layerAdd ? (
        <button
          onClick={() => setNewGroupOpen(!newGroupOpen)}
          className={scss['toc-header-button']}
        >
          New Group
          <Icon className={scss['plus-icon']} icon='plus' />
        </button>
      ) : null}
    </>
  )
}

export default NewGroup
