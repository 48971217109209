/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import Icon from '../../../../components/Icon/Icon'
import scss from './AddTabButtonV2.scss'

export default function AddTabButton({ addTab }) {
  return (
    <div className={scss.propertyTabWrapper}>
      <span>Add Tab</span>
      <button type='button' className={scss.addTabBtn} onClick={addTab}>
        <Icon icon='plus' size='1x' />
      </button>
    </div>
  )
}
