/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
import { DialogTitle } from '@material-ui/core'
import React, { useState, useRef } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { ThemeProvider } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import { useSelector } from 'react-redux'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import scss from './NewFolderPopup.scss'
import modalScss from '../../../CSSModules/Modal.scss'
import * as NewFolderPopupUtil from './NewFolderPopupUtil.js'

const NewFolderPopup = ({
  cwd,
  setIsNewFolderPopupVisible,
  setIsNewCreateMenuVisible,
  setTriggerRefresh,
  setGenerateNewFileTree,
}) => {
  const [folderName, setFolderName] = useState('Untitled folder')

  const handleCreate = () => {
    setIsNewFolderPopupVisible(false)
    setIsNewCreateMenuVisible(false)

    if (cwd[-1] !== '/') cwd += '/'

    NewFolderPopupUtil.createFolder(cwd, folderName)
    setTriggerRefresh(true)
    setGenerateNewFileTree(true)
  }
  const handleFocus = e => {
    e.currentTarget.select()
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog
        onClose={() => {
          setIsNewFolderPopupVisible(false)
        }}
        open
        aria-labelledby='document-browser-dialog'
        maxWidth='lg'
      >
        <DialogTitle
          id='document-browser-title'
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
            borderStyle: 'none',
            background: '#314d68',
          }}
          className={scss.NewFolderPopup}
        >
          New Folder
        </DialogTitle>
        <div
          style={{
            height: '75px',
            background: 'rgb(49, 77, 104)',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'flex-start',
            alignItems: 'center',
            color: '#314d68',
          }}
        >
          x
          <form style={{ width: '80%' }} onSubmit={handleCreate}>
            <input
              style={{ background: 'white !important' }}
              className={scss.InputBox}
              type='text'
              value={folderName}
              onChange={e => setFolderName(e.target.value)}
              onFocus={e => handleFocus(e)}
              autoFocus
            />
          </form>
        </div>
        <DialogActions
          style={{
            borderStyle: 'none',
            background: '#314d68',
          }}
        >
          <button
            className={scss.CancelButton}
            onClick={() => {
              setIsNewFolderPopupVisible(false)
            }}
          >
            CANCEL
          </button>
          <button
            className={scss.CreateButton}
            onClick={() => {
              handleCreate()
            }}
          >
            CREATE
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default NewFolderPopup
