import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@/components/Icon/Icon'
import { setViewPort, initiateTracking } from '@/actions'
import { FlyToInterpolator } from 'react-map-gl'
import { faLocation } from '@fortawesome/pro-solid-svg-icons'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
} from '@/styles/tailwind-utilities'

const UserLocation = () => {
  const dispatch = useDispatch()
  const viewport = useSelector(state => state.viewport)
  const isTrackingLocation = useSelector(
    state => state.geolocation.isTrackingLocation
  )
  const currentLocation = useSelector(
    state => state.geolocation.currentLocation
  )
  return (
    <button
      className={`${buttonBase} ${mapControlButton} ${mapControlButtonColors.default} rounded-b-xl rounded-t-none border-t-[1px] border-primary-hover -mt-[1px]`}
      onClick={() => {
        if (!isTrackingLocation) {
          dispatch(initiateTracking())
        } else {
          if (currentLocation === null) return
          const { latitude, longitude } = currentLocation
          if (latitude === null || longitude === null) return
          let newViewport = {
            ...viewport,
            latitude,
            longitude,
            zoom: 15,
            transitionDuration: 500,
            transitionInterpolator: new FlyToInterpolator(),
          }
          dispatch(setViewPort(newViewport))
        }
      }}
    >
      <Icon icon={faLocation} size='xl' />
    </button>
  )
}

export default UserLocation
