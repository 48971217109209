import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MakeDataConfigComponent from '../../../../../utilities/dataConfig'
import AsyncFetch from '../../../../../utilities/AsyncFetch'
import { apis } from '../../../../../config/apiConfig'
import Icon from '../../../../../components/Icon/Icon'
import appScss from '../../../../App/App.scss'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'
import { Alert } from '../../../../../actions'

function VectorLayer({ layerName, layerDescription, layerType, closeTool }) {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const canSave = () =>
    layerType && layerType.value !== '' && layerName && layerName.trim() !== ''

  const [enableSave, setEnableSave] = useState(() => canSave())
  const [fetching, setFetching] = useState(false)
  const [urlObject, setUrlObject] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)

  const getPaintProps = layerType => {
    const props = {}
    if (layerType.value === 'line') {
      props['line-opacity'] = 0.5
      props['line-width'] = 5
      props['line-color'] = '#1a2937'
    }
    if (layerType.value === 'circle') {
      props['circle-opacity'] = 0.5
      props['circle-radius'] = 5
      props['circle-color'] = '#1a2937'
    }
    if (layerType.value === 'fill') {
      props['fill-opacity'] = 0.5
      props['fill-color'] = '#1a2937'
    }
    return props
  }

  useEffect(() => {
    setEnableSave(() => canSave())
  }, [layerType, layerName])

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )
  const saveNewLayer = () => {
    if (!layerType || (layerType && layerType.value === '')) {
      return dispatch(
        Alert({ info: 'Required Field: Layer Type is a required field.' })
      )
    }
    if (layerName.trim() === '') {
      return dispatch(
        Alert({ info: 'Required Field: Layer Name is a required field.' })
      )
    }
    const sourceType = 'user'
    let geom = null
    if (layerType.value === 'line') geom = 'line'
    if (layerType.value === 'fill') geom = 'polygon'
    if (layerType.value === 'circle') geom = 'point'

    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/add'
    const paintProps = getPaintProps(layerType)
    const body = {
      mapID: user.mapID,
      label: layerName,
      description: layerDescription,
      sourceType: sourceType,
      type: geom,
      mapID: user.mapID,
      paint: paintProps,
      minzoom: 8,
    }

    const urlObject = [{ url, body, method }]
    setUrlObject(urlObject)
    setFetching(true)
  }

  const handleOnFinish = () => closeTool()

  const fetchFinished = () => {
    setMountMakeDataConfig(true)
  }

  const handleCancel = () => {
    closeTool()
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      setUrlObject(null)
    },
    [urlObject]
  )

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {urlObject && (
          <AsyncFetch fetchObjects={urlObject} fetchFinished={fetchFinished} />
        )}
        {mountMakeDataConfig && (
          <MakeDataConfigComponent onFinish={handleOnFinish} />
        )}
        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>

          <button
            className={
              enableSave
                ? appScss.blueButton
                : [appScss.blueButton, appScss.disabled].join(' ')
            }
            onClick={fetching ? null : saveNewLayer}
          >
            {fetching ? (
              <Icon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Save'
            )}
          </button>
        </DialogActions>
      </ThemeProvider>
    </>
  )
}

export default VectorLayer
