import React, { useState, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { NavigationControl } from 'react-map-gl'
import {
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  setOverPanel,
  updateMapStyle,
  Alert,
} from '@/actions/index'

import ReactTooltip from '@/components/Tooltip/ReactTooltip'
import Home from '@/containers/MapControls/Home/Home'
import UserLocation from '@/containers/MapControls/UserLocation/UserLocation'
import FeatureSelect from '@/containers/MapControls/FeatureSelect/FeatureSelect'
import FeatureSelectBtn from '@/containers/MapControls/FeatureSelect/FeatureSelectBtn'
import DrawBtn from '@/containers/MapControls/Draw/DrawBtn'
import MeasureBtn from '@/containers/MapControls/MeasureControl/MeasureBtn'
import Measure from '@/containers/MapControls/MeasureControl/Measure'
import SearchBtn from '@/containers/MapControls/Search/SearchBtn'
import Search from '@/containers/MapControls/Search/Search'
import ClearMap from '@/containers/MapControls/ClearMap/ClearMap'
import ClearSelection from '@/containers/MapControls/ClearSelection/ClearSelection'
import FeedbackButton from '@/containers/MapControls/Feedback/FeedbackButton'
import FeedbackDialog from '@/containers/MapControls/Feedback/FeedbackDialog'

import { fromJS } from 'immutable'
import {
  mapButtonDouble,
  mapNavigationControls,
} from '@/styles/tailwind-utilities'

const RightSideControls = React.memo(
  ({
    onViewportChange,
    setClickedFeature,
    closePopup,
    selectedFeatures,
    setSelectedFeatures,
  }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const viewport = useSelector(state => state.viewport)
    const mapRef = useSelector(state => state.mapRef)
    const mapStyle = useSelector(state => state.mapStyle)
    const dataConfig = useSelector(state => state.updateDataConfig)

    const [searchVisible, setSearchVisible] = useState(false)
    const [measureVisible, setMeasureVisible] = useState(false)
    const [featureSelectVisible, setFeatureSelectVisible] = useState(false)
    const [feedbackVisible, setFeedbackVisible] = useState(false)

    const measureButtonRef = useRef(null)
    const searchButtonRef = useRef(null)

    const initialPositions = { left: 0, width: 30, height: 30 }
    const [zoomInPosition, setZoomInPosition] = useState({
      ...initialPositions,
      top: 0,
    })
    const [zoomOutPosition, setZoomOutPosition] = useState({
      ...initialPositions,
      top: 30,
    })
    const [compassPosition, setCompassPosition] = useState({
      ...initialPositions,
      top: 60,
    })

    const navControlRef = useRef(null)

    const disableMap = () => {
      dispatch(setOverPanel(true))
      dispatch(setScrollZoom(false))
      dispatch(setDragPan(false))
      dispatch(setDoubleClickZoom(false))
    }

    const enableMap = () => {
      dispatch(setOverPanel(false))
      dispatch(setScrollZoom(true))
      dispatch(setDragPan(true))
      dispatch(setDoubleClickZoom(true))
    }

    const toggleFeedbackVisible = () => {
      setFeedbackVisible(state => !state)
    }

    const selectFeaturesOnScreen = targetLayer => {
      if (!targetLayer) return

      const map = mapRef.getMap()
      const features = map.queryRenderedFeatures({
        layers: targetLayer.layerIdList,
      })

      if (!features.length)
        dispatch(
          Alert({
            info: 'No features were found on the selected layer on the current viewport.',
          })
        )

      setSelectedFeatures(features)
    }

    const clearSelectedFeatures = () => {
      let style = mapStyle.toJS()
      style.sources.selected_features.data.features = []
      dispatch(updateMapStyle(fromJS(style)))
      setSelectedFeatures([])
    }

    // useLayoutEffect to determine the tooltip positions for the zoom in and zoom out buttons, since they come from an external library NavigationControl
    useLayoutEffect(() => {
      const updateButtonPositions = () => {
        const zoomInBtn = document.querySelector('.mapboxgl-ctrl-zoom-in')
        const zoomOutBtn = document.querySelector('.mapboxgl-ctrl-zoom-out')
        const compassBtn = document.querySelector('.mapboxgl-ctrl-compass')

        if (zoomInBtn) {
          const rect = zoomInBtn.getBoundingClientRect()
          const parentRect = navControlRef.current.getBoundingClientRect()
          setZoomInPosition({
            top: rect.top - parentRect.top,
            left: rect.left - parentRect.left,
            width: rect.width,
            height: rect.height,
          })
        }

        if (zoomOutBtn) {
          const rect = zoomOutBtn.getBoundingClientRect()
          const parentRect = navControlRef.current.getBoundingClientRect()
          setZoomOutPosition({
            top: rect.top - parentRect.top,
            left: rect.left - parentRect.left,
            width: rect.width,
            height: rect.height,
          })
        }

        if (compassBtn) {
          const rect = compassBtn.getBoundingClientRect()
          const parentRect = navControlRef.current.getBoundingClientRect()
          setCompassPosition({
            top: rect.top - parentRect.top,
            left: rect.left - parentRect.left,
            width: rect.width,
            height: rect.height,
          })
        }
      }

      // Initial measurement
      setTimeout(updateButtonPositions, 100)

      // Update on resize
      window.addEventListener('resize', updateButtonPositions)
      return () => window.removeEventListener('resize', updateButtonPositions)
    }, [])

    return (
      <>
        <div
          style={{ height: viewport.height - 22 }}
          className='absolute top-5 right-7 overflow-x-hidden overflow-y-auto z-[5000]'
          onMouseEnter={disableMap}
          onMouseLeave={enableMap}
        >
          <div className='flex flex-col gap-6 mr-3'>
            <div
              className='group'
              data-tooltip-id='right-controls'
              data-tooltip-content='Search'
              ref={searchButtonRef}
            >
              <SearchBtn
                toggleSearch={() => setSearchVisible(state => !state)}
                isSearching={searchVisible}
              />
            </div>

            <div className={mapButtonDouble}>
              <div
                className='group'
                data-tooltip-id='right-controls'
                data-tooltip-content='Go To Home'
              >
                <Home
                  mapID={user.mapID}
                  mapStyle={mapStyle}
                  dataConfig={dataConfig}
                  enableMap={enableMap}
                />
              </div>
              <div
                className='group'
                data-tooltip-id='right-controls'
                data-tooltip-content='Current Location'
              >
                <UserLocation />
              </div>
            </div>

            <div className='relative ml-[1px] h-[140px]'>
              <div ref={navControlRef} className={mapNavigationControls}>
                <NavigationControl
                  onViewportChange={onViewportChange}
                  showCompass={true}
                  showBearing={true}
                  visualizePitch={false}
                />
                <div
                  className='absolute cursor-pointer'
                  style={{
                    top: `${zoomInPosition.top}px`,
                    left: `${zoomInPosition.left}px`,
                    width: `${zoomInPosition.width}px`,
                    height: `${zoomInPosition.height}px`,
                    pointerEvents: 'all',
                    opacity: 0,
                  }}
                  data-tooltip-id='right-controls'
                  data-tooltip-content='Zoom In'
                  onClick={() => {
                    const zoomInBtn = document.querySelector(
                      '.mapboxgl-ctrl-zoom-in'
                    )
                    if (zoomInBtn) zoomInBtn.click()
                  }}
                />
                <div
                  className='absolute cursor-pointer'
                  style={{
                    top: `${zoomOutPosition.top}px`,
                    left: `${zoomOutPosition.left}px`,
                    width: `${zoomOutPosition.width}px`,
                    height: `${zoomOutPosition.height}px`,
                    pointerEvents: 'all',
                    opacity: 0,
                  }}
                  data-tooltip-id='right-controls'
                  data-tooltip-content='Zoom Out'
                  onClick={() => {
                    const zoomOutBtn = document.querySelector(
                      '.mapboxgl-ctrl-zoom-out'
                    )
                    if (zoomOutBtn) zoomOutBtn.click()
                  }}
                />
                <div
                  className='absolute cursor-pointer'
                  style={{
                    top: `${compassPosition.top}px`,
                    left: `${compassPosition.left}px`,
                    width: `${compassPosition.width}px`,
                    height: `${compassPosition.height}px`,
                    pointerEvents: 'all',
                    opacity: 0,
                  }}
                  data-tooltip-id='right-controls'
                  data-tooltip-content='Reset North'
                  onClick={() => {
                    const compassBtn = document.querySelector(
                      '.mapboxgl-ctrl-compass'
                    )
                    if (compassBtn) compassBtn.click()
                  }}
                />
              </div>
            </div>

            <div className={mapButtonDouble}>
              <div
                className='group'
                data-tooltip-id='right-controls'
                data-tooltip-content='Clear Map'
              >
                <ClearMap
                  dataConfig={dataConfig}
                  setClickedFeature={setClickedFeature}
                  closePopup={closePopup}
                />
              </div>
              <div
                className='group'
                data-tooltip-id='right-controls'
                data-tooltip-content='Clear Selection'
              >
                <ClearSelection
                  mapStyle={mapStyle}
                  dataConfig={dataConfig}
                  setClickedFeature={setClickedFeature}
                  closePopup={closePopup}
                />
              </div>
            </div>

            <div className='flex flex-col gap-4'>
              <div
                className='group'
                onMouseEnter={disableMap}
                onMouseLeave={enableMap}
                data-tooltip-id='right-controls'
                data-tooltip-content='Feature Select'
                data-tooltip-hidden={featureSelectVisible}
              >
                <FeatureSelectBtn
                  toggleFeatureSelect={() =>
                    setFeatureSelectVisible(state => !state)
                  }
                  isSelecting={featureSelectVisible}
                />
              </div>

              <div
                className='group'
                onMouseEnter={disableMap}
                onMouseLeave={enableMap}
                data-tooltip-id='right-controls'
                data-tooltip-content='Draw'
              >
                <DrawBtn />
              </div>

              <div
                className='group'
                ref={measureButtonRef}
                onMouseEnter={disableMap}
                onMouseLeave={enableMap}
                data-tooltip-id='right-controls'
                data-tooltip-content='Measure'
                data-tooltip-hidden={measureVisible}
              >
                <MeasureBtn
                  isMeasuring={measureVisible}
                  toggleMeasure={() => {
                    setMeasureVisible(state => !state)
                    if (!measureVisible) {
                      setFeatureSelectVisible(false)
                      setSearchVisible(false)
                    }
                  }}
                  buttonRef={measureButtonRef}
                />
              </div>
            </div>

            <div
              className='group'
              onMouseEnter={disableMap}
              onMouseLeave={enableMap}
              style={{ border: 'none' }}
              data-tooltip-id='right-controls'
              data-tooltip-content='Feedback'
            >
              <FeedbackButton
                toggleFeedbackVisible={toggleFeedbackVisible}
                feedbackVisible={feedbackVisible}
              />
            </div>
          </div>
        </div>

        <ReactTooltip id='right-controls' />

        {feedbackVisible && (
          <FeedbackDialog
            feedbackVisible={feedbackVisible}
            toggleFeedbackVisible={toggleFeedbackVisible}
          />
        )}
        {searchVisible && (
          <Search
            disableMap={disableMap}
            enableMap={enableMap}
            isVisible={searchVisible}
            onClose={() => setSearchVisible(false)}
            referenceElement={searchButtonRef.current}
          />
        )}
        {measureVisible && (
          <Measure
            disableMap={disableMap}
            enableMap={enableMap}
            isVisible={measureVisible}
            onClose={() => setMeasureVisible(false)}
            referenceElement={measureButtonRef.current}
          />
        )}
        {featureSelectVisible && (
          <FeatureSelect
            mapStyle={mapStyle}
            dataConfig={dataConfig}
            selectedFeatures={selectedFeatures}
            selectFeaturesOnScreen={selectFeaturesOnScreen}
            clearSelectedFeatures={clearSelectedFeatures}
            enableMap={enableMap}
            disableMap={disableMap}
          />
        )}
      </>
    )
  }
)

export default RightSideControls
