import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MapGL, { NavigationControl } from 'react-map-gl'

import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'

import scss from '../Maps.scss'
import modalScss from '../../CSSModules/Modal.scss'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { refreshDetails } from '../../../utilities/user'

import { apis } from '../../../config/apiConfig'
import {
  MuiFormControlTextMultiline,
  MuiFormControlText,
  MuiButtonGroup,
} from '../../../components/MaterialUi/Tool'

import { MuiButton } from '../../../components/MaterialUi/Dialog'
import Icon from '../../../components/Icon/Icon'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

const mapEditMuiTheme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        overflowY: 'hidden',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '8px 10px',
      },
    },
    MuiButton: {
      label: {
        color: '#fff !important',
      },
    },
    MuiButtonBase: {
      root: {
        // Name of the rule
        height: '33px',
        color: '#fff !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        borderRadius: 4,
        '&$focused': {
          //Input Box Focused CSS
          borderColor: '#41678b !important',
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      root: {
        // padding: "0 0px 0 0px !important",
      },
    },
    MuiFormLabel: {
      root: {
        // Name of the rule
        fontSize: '12px !important',
      },
    },
    MuiInputBase: {
      root: {
        // Name of the rule
        height: 'auto',
        color: 'rgba(0, 0, 0, 0.87) !important',
        fontSize: '16px !important',
        fontFamily: 'Montserrat !important',
        borderRadius: 4,
        '&$focused': {
          //Input Box Focused CSS
          borderColor: '#41678b !important',
          color: '#fff',
        },
      },
    },
  },
})

function SimpleDialog({ lat, lon, mapName, mapDesc, mapID, visible }) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)

  //Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  //Fields and their States
  // const [organizationNameState, setOrganizationName] = useState(organizationName);

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const buildFetchParams = () => {
    const method = 'POST'
    let url = apis['apiDatabase'].uri + 'account/update?accountID='
    let body = {
      accountID: user.accountID,
    }

    setFetchObjects([{ url, method, body }])
    setFetching(true)
  }

  const fetchFinished = result => {
    refreshDetails(user, newUser => {
      dispatch({ type: 'REFRESH', payload: newUser })
      setFetching(false)
      handleOnClose()
    })

    setFetchObjects(null)
  }

  const handleSave = () => {
    buildFetchParams()
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='assign-user-dialog'
        open={open}
      >
        <DialogTitle id='assign-user-dialog'>
          Edit Map
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>

        <DialogContent>
          <div className={scss.mapEdit}>
            <div className={scss.mapEditMapName}>
              {/* <label>Map Name</label><input id="mapName" name="mapName" value="" /> */}
              <MuiFormControlText
                style={{ border: '1px solid #41678b' }}
                // className={toolScss.toolBodyTab.button}
                disabled={false}
                label='Map Name'
                placeholder=''
                // onChange={onLabelChange}
                onSubmit={fetching ? null : handleSave}
                value={mapName}
              />
            </div>
            <div className={scss.mapEditMapName}>
              <MuiFormControlTextMultiline
                style={{ border: '1px solid #41678b' }}
                // className={toolScss.toolBodyTab.button}
                disabled={false}
                label='Description'
                placeholder=''
                // onChange={onLabelChange}
                onSubmit={fetching ? null : handleSave}
                defaultValue={mapDesc}
              />
            </div>
            <div className={scss.mapEditMap}>
              <MapGL
              // {...viewport}
              // mapStyle={mapStyle}
              // onViewportChange={this.onViewportChange}
              // onLoad={onMapLoad}
              // onNativeClick={this.onMapClickNative}
              // dragPan={dragPan}
              // doubleClickZoom={doubleClickZoom}
              // scrollZoom={scrollZoom}
              // ref={(map) => (this.mapRef = map)}
              // transformRequest={this.transformRequest}
              // onTransitionEnd={this.onTransitionEnd}
              // interactiveLayerIds={interactiveLayerIds}
              // attributionControl={false}
              // getCursor={this.getCursor}
              // keyboard={false}
              />
            </div>
            <div className={scss.mapEditLatLon}>
              <div className={scss.mapEditLat}>
                {/* <label>Latitude</label><input id="latitude" name="latitude" value="" /> */}
                <MuiFormControlText
                  style={{ border: '1px solid #41678b' }}
                  // className={toolScss.toolBodyTab.button}
                  disabled={false}
                  label='Latitude'
                  placeholder=''
                  // onChange={onLabelChange}
                  onSubmit={fetching ? null : handleSave}
                  value={lat}
                />
              </div>
              <div className={scss.mapEditLon}>
                {/* <label>Longitude</label><input id="longitude" name="longitude" value="" /> */}
                <MuiFormControlText
                  style={{ border: '1px solid #41678b' }}
                  // className={toolScss.toolBodyTab.button}
                  disabled={false}
                  label='Longitude'
                  placeholder=''
                  // onChange={onLabelChange}
                  onSubmit={fetching ? null : handleSave}
                  value={lon}
                />
              </div>
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <button className={modalScss.modalAltButton} onClick={handleOnClose}>
            Cancel
          </button>

          <button
            className={modalScss.modalMainButton}
            onClick={fetching ? null : handleSave}
          >
            {fetching ? <CircularProgress size={10} /> : 'Activate'}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function MapEditModal(props) {
  return <SimpleDialog {...props} />
}
