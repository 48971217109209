import React, { FC } from 'react'
import { Tooltip, PlacesType } from 'react-tooltip'

type ReactTooltipProps = {
  id?: string
  className?: string
  place?: PlacesType
  offset?: number
  delayShow?: number
  delayHide?: number
}

const ReactTooltip: FC<ReactTooltipProps> = ({
  id = '',
  className = 'z-[9999] !text-base !text-white !px-4 !py-2 !text-[1.1rem] !bg-primary border border-primary-border',
  place = 'left',
  offset = 12,
  delayShow = 200,
  delayHide = 0,
}) => {
  return (
    <Tooltip
      id={id}
      className={className}
      place={place}
      offset={offset}
      delayShow={delayShow}
      delayHide={delayHide}
    />
  )
}

export default ReactTooltip
