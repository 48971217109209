import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromJS } from 'immutable'
import Icon from '../../../components/Icon/Icon'
import nearmapLogo from './nearmap_logo.svg'
import googleLogo from './google_logo.svg'
import { updateMapStyle } from '../../../actions'
import { getVisibleImageryLayers } from '../../../utilities/mapStyle'

import scss from './Imagery.scss'
import mapScss from '../../Map/Map.scss'

const ImageryPicker = ({
  dataConfig,
  toggleNearCoverage,
  toggleImageryPicker,
}) => {
  const dispatch = useDispatch()
  const mapStyle = useSelector(state => state.mapStyle)
  const [visibleImageryLayers, setVisibleImageryLayers] = useState([])
  const [nearMapAvailable, setNearMapAvailable] = useState(false)
  const toggleMapLayer = idArray => {
    let ids = [].concat(...idArray)
    let newMapStyle = mapStyle.toJS()
    let imageryState = null
    newMapStyle.layers.forEach(layer => {
      ids.forEach(id => {
        if (id === 'aerial_nearmap') {
          // turn off google
          if (layer.id === 'aerial_google_tiles') {
            layer.layout.visibility = 'none'
          }
        }
        if (id === 'aerial_google_tiles') {
          // turn off near map
          if (layer.id === 'aerial_nearmap') {
            layer.layout.visibility = 'none'
          }
        }
        if (layer.id === id) {
          if (layer.layout.visibility === 'none') {
            let visible = [...visibleImageryLayers, id]
            setVisibleImageryLayers(visible)
            layer.layout.visibility = 'visible'
            imageryState = 'imagery'
          } else {
            let visible = visibleImageryLayers.filter(
              visibleId => visibleId !== id
            )
            setVisibleImageryLayers(visible)
            layer.layout.visibility = 'none'
            imageryState = 'noImagery'
          }
        }
      })
    })
    newMapStyle = changeImageryDependantStyles(imageryState, newMapStyle)
    dispatch(updateMapStyle(fromJS(newMapStyle)))
    toggleImageryPicker()
  }

  const changeImageryDependantStyles = (imageryState, style) => {
    // change a layers style based on imagery
    // this function will check each layers metadata object for a imageryDependentStyle
    // and update the layers style accordingly
    let newLayersArray = style.layers.map(layer => {
      if (layer.metadata && layer.metadata.imageryDependentStyle) {
        layer.paint['line-color'] =
          layer.metadata.imageryDependentStyle['line-color'][imageryState]
      }
      return layer
    })
    style.layers = newLayersArray
    return style
  }

  const makeImageryLayerButton = configObj => {
    const layerIds = configObj.layersArray.map(layerObj => {
      return Object.values(layerObj)
        .filter(layer => layer && layer.id)
        .map(layer => layer.id)
    })

    let activeButton = null

    let arr = visibleImageryLayers.filter(
      visibleImagery =>
        JSON.stringify(visibleImagery) === JSON.stringify(configObj.toc.id)
    )
    if (arr.length) activeButton = true
    const faIcon = !activeButton ? 'eye-slash' : 'eye'

    return (
      <div
        key={layerIds}
        className={[
          scss.imageryLayerContainer,
          // activeButton == true ? scss.selectedStyle : "",
        ].join(' ')}
        onClick={() => toggleMapLayer(layerIds)}
      >
        <div className={scss.imageryLayerContainerLeft}>
          <Icon icon={configObj.toc.icon} />
          {/* <span>{configObj.toc.label}</span> */}
          {/* Conditionally render the SVG logos */}
          {configObj.toc.id === 'aerial_nearmap' && (
            <img
              src={nearmapLogo}
              alt='Nearmap Logo'
              className={`${scss.logo} ${scss.nearmapLogo}`} // Add nearmapLogo class
            />
          )}
          {configObj.toc.id === 'aerial_google_tiles' && (
            <img
              src={googleLogo}
              alt='Google Logo'
              className={`${scss.logo} ${scss.googleLogo}`} // Add googleLogo class
            />
          )}
        </div>
        <button className={scss.imageryVisibleButton}>
          <Icon icon={faIcon} />
        </button>
      </div>
    )
  }

  const checkForNearMap = () => {
    dataConfig['imageryLayers'].map(configObj => {
      const layerIds = configObj.layersArray.map(layerObj => {
        return Object.values(layerObj)
          .filter(layer => layer && layer.id)
          .map(layer => layer.id)
      })
      if (layerIds[0].join('') === 'aerial_nearmap') {
        setNearMapAvailable(true)
      }
    })
  }

  const imageryLayers = dataConfig['imageryLayers'].map(configObj =>
    makeImageryLayerButton(configObj)
  )

  useEffect(() => {
    let style = mapStyle.toJS()
    let visibleLayers = getVisibleImageryLayers(
      style,
      dataConfig['imageryLayers']
    )
    setVisibleImageryLayers(visibleLayers)
  }, [mapStyle])

  useEffect(() => {
    checkForNearMap()
  }, [])

  return (
    <div className={scss.imageryContainer}>
      {imageryLayers}
      {nearMapAvailable && (
        <button
          className={[mapScss.historyButton, scss.imageryHistoryButton].join(
            ' '
          )}
          type='button'
          onClick={toggleNearCoverage}
          title='History'
        >
          <Icon icon='history' />
        </button>
      )}
    </div>
  )
}

export default ImageryPicker
