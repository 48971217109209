import React from 'react'
import Icon from '@/components/Icon/Icon'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
} from '@/styles/tailwind-utilities'

const FeatureSelectBtn = ({ toggleFeatureSelect, isSelecting }) => {
  return (
    <button
      onClick={toggleFeatureSelect}
      className={`${buttonBase} ${mapControlButton} ${
        isSelecting
          ? mapControlButtonColors.active
          : mapControlButtonColors.default
      }`}
    >
      <Icon icon={['fa', 'mouse-pointer']} size='xl' />
    </button>
  )
}

export default FeatureSelectBtn
