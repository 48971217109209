import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDataTable, setFooterHeight } from '../../actions'
import Icon from '../../components/Icon/Icon'
import ApplyFilterToMap from './ApplyFilterToMap'
import scss from './DataTable.scss'
import Switch from '@material-ui/core/Switch'

const TableControls = ({
  fetching,
  gridApi,
  currentFilter,
  onClose,
  rowCount,
}) => {
  const dispatch = useDispatch()
  const dataTable = useSelector(state => state.dataTable)
  const [doApplyFilter, setDoApplyFilter] = useState(
    currentFilter ? true : false
  )
  const [filterChecked, setFilterChecked] = useState(false)
  const [closingTable, setClosingTable] = useState(false)
  const { layerLabel } = dataTable

  const closeTable = () => {
    setClosingTable(true)
  }

  const applyFilter = () => {
    setDoApplyFilter(!doApplyFilter)
    setFilterChecked(!filterChecked)
  }

  const exportToExcel = () => {
    gridApi.exportDataAsExcel()
  }

  useEffect(() => {
    if (currentFilter) {
      setDoApplyFilter(true)
      setFilterChecked(true)
    }
  }, [])

  useEffect(() => {
    if (closingTable) {
      setTimeout(() => {
        dispatch(setDataTable(null))
        onClose()
      }, 1000)
    }
  }, [closingTable])

  return (
    <>
      {gridApi ? (
        <>
          <ApplyFilterToMap
            doApplyFilter={doApplyFilter}
            currentFilter={currentFilter}
            gridApi={gridApi}
          >
            <div className={scss.headerContainer}>
              <div className={scss.tableLabel}>
                <div>
                  {layerLabel}
                  {fetching ? (
                    <span>
                      {rowCount ? (
                        rowCount + ' result' + (rowCount == 1 ? '' : 's')
                      ) : (
                        <Icon icon='spinner' size='1x' spin />
                      )}
                    </span>
                  ) : (
                    <span>
                      {rowCount
                        ? rowCount + ' result' + (rowCount == 1 ? '' : 's')
                        : null}
                    </span>
                  )}
                </div>
              </div>
              <div className={scss.buttonWrapper}>
                <button onClick={exportToExcel}>
                  <Icon icon='download' size='sm' /> Export
                </button>
                <button style={{ color: '#1b2e40' }} onClick={applyFilter}>
                  <span className={scss.buttonLabel}>Map Filters</span>
                  <Switch
                    checked={filterChecked}
                    onChange={applyFilter}
                    name='filterChecked'
                    inputProps={{ 'aria-label': 'checkbox with default color' }}
                  />
                </button>
                <button style={{ color: '#1b2e40' }} onClick={closeTable}>
                  <Icon
                    icon={closingTable ? 'spinner' : 'times'}
                    size='lg'
                    spin={closingTable ? true : false}
                  />
                </button>
              </div>
            </div>
          </ApplyFilterToMap>
        </>
      ) : (
        <div className={scss.headerContainer}>
          <div className={scss.tableLabel}>
            <div>
              {layerLabel}
              {fetching ? (
                <span>
                  <Icon icon='spinner' size='1x' spin />
                </span>
              ) : null}
            </div>
          </div>
          <div className={scss.buttonWrapper}>
            <button onClick={closeTable}>
              <Icon
                icon={closingTable ? 'spinner' : 'times'}
                size='lg'
                spin={closingTable ? true : false}
              />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default TableControls
