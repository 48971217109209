import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../components/Icon/Icon'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'
import appScss from '../../App/App.scss'
import scss from './FeedbackButton.scss'
import { apiFetch } from '../../../utilities/api'
import { apis } from '../../../config/apiConfig'
import { Alert } from '../../../actions'

const FeedbackDialog = ({ feedbackVisible, toggleFeedbackVisible }) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const [feedbackSubject, setFeedbackSubject] = useState('')
  const [feedbackBody, setFeedbackBody] = useState('')
  const [enableSave, setEnableSave] = useState(false)
  const [fetching, setFetching] = useState(false)

  useEffect(() => {
    setEnableSave(feedbackSubject !== '' && feedbackBody !== '')
  }, [feedbackSubject, feedbackBody])

  const handleSaveAsync = async feedbackData => {
    try {
      const url = apis.apiDatabase.uri + 'feedback'
      const method = 'POST'
      const body = feedbackData

      setFetching(true)
      await apiFetch(url, method, body, response => {
        if (response.success) {
          setFetching(false)
          dispatch(
            Alert({
              success: response.message,
            })
          )
        } else {
          setFetching(false)
          dispatch(
            Alert({
              error: response.message,
            })
          )
        }
      })
    } catch (error) {
      console.error('Error submitting feedback: ', error)
    }
  }

  const handleClose = () => {
    setFeedbackSubject('')
    setFeedbackBody('')
    toggleFeedbackVisible()
  }

  const handleSave = () => {
    if (feedbackBody.trim() === '') {
      return dispatch(
        Alert({
          info: 'Required Field: Feedback Description is a required field',
        })
      )
    }
    if (feedbackSubject.trim() === '') {
      return dispatch(
        Alert({
          info: 'Required Field: Feedback Subject is a required field',
        })
      )
    }
    const feedbackData = {
      userEmail: user.profile.emailAddress,
      userID: user.UUID,
      accountID: user.accountID,
      timestamp: new Date().toISOString(),
      subject: feedbackSubject,
      description: feedbackBody,
    }

    handleSaveAsync(feedbackData)

    handleClose()
    return null
  }

  const onSubjectChange = e => {
    setFeedbackSubject(e.target.value)
  }

  const onBodyChange = e => {
    setFeedbackBody(e.target.value)
  }

  return (
    <>
      {
        <ThemeProvider theme={mainModalTheme}>
          <Dialog
            open={feedbackVisible}
            onClose={handleClose}
            aria-labelledby='feedback-dialog'
            maxWidth='xl'
            style={{ minWidth: '800px' }}
          >
            <DialogTitle id='feedback-dialog'>
              Feedback
              <Icon
                onClick={handleClose}
                icon='times'
                pull='right'
                style={{ cursor: 'pointer' }}
              />
            </DialogTitle>

            <DialogContent className={scss['feedback-body']}>
              <label
                htmlFor='feedback-subject'
                className={appScss['input-notched']}
              >
                <span>Subject</span>
                <input
                  id='feedback-subject'
                  onChange={onSubjectChange}
                  value={feedbackSubject}
                  placeholder='Comments, suggestions, bug fixes, etc'
                  style={{ textAlign: 'left' }}
                />
              </label>

              <label
                htmlFor='feedback-body'
                className={appScss['input-notched']}
                style={{ marginTop: '16px' }}
              >
                <span>Description</span>
                <textarea
                  id='feedback-body'
                  onChange={onBodyChange}
                  value={feedbackBody}
                  placeholder="Please leave your feedback here. We'll get back to you as soon as possible! "
                  style={{ textAlign: 'left' }}
                />
              </label>
            </DialogContent>
            <DialogActions>
              <button className={appScss.altBlueButton} onClick={handleClose}>
                Cancel
              </button>
              <button
                className={
                  enableSave
                    ? appScss.blueButton
                    : [appScss.blueButton, appScss.disabled].join(' ')
                }
                onClick={() => {
                  if (!fetching) {
                    handleSave()
                  }
                }}
              >
                {fetching ? (
                  <Icon icon='spinner' size='1x' fixedWidth spin />
                ) : (
                  'Submit'
                )}
              </button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      }
    </>
  )
}

export default FeedbackDialog
