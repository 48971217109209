import React from 'react'
import Icon from '../../../components/Icon/Icon'
import {
  buttonBase,
  mapControlButton,
  mapControlButtonColors,
} from '@/styles/tailwind-utilities'
import { faSearchLocation } from '@fortawesome/pro-solid-svg-icons'

const SearchBtn = ({ toggleSearch, isSearching }) => {
  return (
    <button
      className={`${buttonBase} ${mapControlButton} ${
        isSearching
          ? mapControlButtonColors.active
          : mapControlButtonColors.default
      } rounded-xl`}
      onClick={toggleSearch}
    >
      <Icon icon={faSearchLocation} size='xl' />
    </button>
  )
}

export default SearchBtn
