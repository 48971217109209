import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import MakeDataConfigComponent from '../../../../utilities/dataConfig'
import { MuiButton } from '../../../../components/MaterialUi/Dialog'
import CloseTool from '../../CloseTool'

import modalScss from '../../../CSSModules/Modal.scss'
import appScss from '../../../App/App.scss'
import Icon from '../../../../components/Icon/Icon'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({
  fetching,
  setFetching,
  doDelete,
  onClose,
  mapViewLabel,
  mapViewID,
}) {
  const [open, setOpen] = useState(true)
  const [closeTool, setCloseTool] = useState(false)

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {closeTool && <CloseTool toolName='LayerSettings' />}
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='delete-map-view-dialog'
          open={open}
          maxWidth='sm'
        >
          <DialogTitle id='delete-map-view-title'>
            Delete Map View
            <Icon onClick={handleOnClose} icon='times' size='sm' />
          </DialogTitle>

          <DialogContent>
            <p>
              You are deleting{' '}
              <i>
                <b>{mapViewLabel}</b>
              </i>{' '}
              map view. This action cannot be undone. Proceed?
            </p>
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
            <button
              className={appScss.blueButton}
              onClick={fetching ? null : doDelete}
            >
              {fetching ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Delete'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function MapViewDelete(props) {
  return <SimpleDialog {...props} />
}
