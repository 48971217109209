import React from 'react'
import Icon from '../../../components/Icon/Icon'
import scss from './ImageryPickerBtn.scss'

function ImageryBtn({ toggleImagery, imageryPickerVisible }) {
  return (
    <button
      className={[
        scss.imageryBtn,
        imageryPickerVisible ? scss.imageryBtnActive : '',
      ].join(' ')}
      onClick={toggleImagery}
      data-tooltip-content='Imagery'
      data-tooltip-id='imagery'
      data-effect='solid'
      data-arrow-color='rgba(0, 0, 0, 0.68)'
      data-place='left'
    >
      <span>
        <Icon icon={['fal', 'globe']} size='1x' />
        Imagery
      </span>
      <Icon
        icon={['fa', imageryPickerVisible ? 'chevron-up' : 'chevron-down']}
        size='1x'
      />
    </button>
  )
}

export default ImageryBtn
