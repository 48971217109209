/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JSZipUtils from 'jszip-utils'
import JSZip from 'jszip'
import saveAs from 'save-as'
import Icon from '../../../../components/Icon/Icon'
import scss from './BulkMenu.scss'
import * as DocumentBrowserUtil from '../../DocumentBrowserV2Util'
import { Alert } from '../../../../actions'

const BulkMenu = ({ rows, setTriggerRefresh, cwd }) => {
  const [totalSelectedRows, setTotalSelectedRows] = useState(0)
  const dispatch = useDispatch()
  const [totalFolderNumb, setTotalFolderNumb] = useState(0)

  useEffect(() => {
    setTotalSelectedRows(rows.length)
    setTotalFolderNumb(
      rows.filter(row => row.values.name.includes('.') === false).length
    )
  })

  const handleBulkErase = () => {
    const totalItems = rows.length
    try {
      rows = rows
        .map(row => row.original.awsPath)
        .map(aws => DocumentBrowserUtil.eraseFile(aws))
      setTriggerRefresh(true)
      dispatch(Alert({ success: 'Deleted ' + totalItems + ' items' }))
    } catch (error) {
      dispatch(Alert({ error: 'Unable to delete items.' }))
    }
  }
  const handleBulkDownload = () => {
    // Create endpoint to handle this
    const signedLinks = rows
      .map(row => row.original.awsPath)
      .map(aws => DocumentBrowserUtil.downloadFile(aws))

    Promise.all(signedLinks).then(urls => {
      const zip = new JSZip()
      let count = 0
      cwd = cwd.split('/')
      const zipFilename = cwd[cwd.length - 1] + '.zip'
      dispatch(Alert({ success: 'Downloading ' + urls.length + ' items' }))
      urls.forEach(async url => {
        let urlArr = url.split('/')
        urlArr = urlArr[urlArr.length - 1].split('?X-Amz-Algorithm=')
        const filename = urlArr[0]
        try {
          const file = await JSZipUtils.getBinaryContent(url)
          zip.file(filename, file, { binary: true })
          count += 1
          if (count === urls.length) {
            zip.generateAsync({ type: 'blob' }).then(content => {
              saveAs(content, zipFilename)
            })
          }
        } catch (err) {
          dispatch(Alert({ error: 'Unable to download items' }))
        }
      })
    })
  }
  return (
    <div className={scss.bulkMenu}>
      <div className={scss.bulkTitle}> {totalSelectedRows} items selected</div>
      <button
        type='button'
        className={scss.ButtonStyle}
        onClick={() => {
          handleBulkErase()
        }}
      >
        <Icon
          icon='trash-alt'
          style={{ color: 'white', marginRight: '10px' }}
        />
        Delete
      </button>
      {totalFolderNumb > 0 ? null : (
        <button
          type='button'
          className={scss.ButtonStyle}
          onClick={() => {
            handleBulkDownload()
          }}
        >
          <Icon
            icon='download'
            style={{ color: 'white', marginRight: '10px' }}
          />
          Download
        </button>
      )}
    </div>
  )
}

export default BulkMenu
