import React, { memo, useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ThemeProvider } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Icon from '../../../components/Icon/Icon'
import { areEqual, FixedSizeList as List } from 'react-window'
import scss from '../ProfileStyling/ProfileModal.scss'
import modalScss from '../../CSSModules/Modal.scss'
import appScss from '../../App/App.scss'

import * as utils from '../../../utilities/util'
import { getStripePlanPrice } from '../../../utilities/util'

import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

export const Row = memo(({ index, style, data }) => {
  const { itemData } = data
  const { products, toggleProductActive } = itemData

  return (
    <div key={index} style={style}>
      <label className={scss.addonsOptionLabel} htmlFor={'planOption' + index}>
        <div
          className={[
            scss.addonsOption,
            scss.planRow,
            scss.planJustify,
            scss.planCenter,
          ].join(' ')}
          htmlFor={'planOption' + index}
        >
          <div className={[scss.planRow, scss.planCenter].join(' ')}>
            <input
              name='planOption'
              id={'planOption' + index}
              type='checkbox'
              checked={products[index].active}
              onChange={() => toggleProductActive(products[index])}
            ></input>
            <div className={scss.planColumn}>
              <span className={scss.addonsHeading}>{products[index].name}</span>
              <span
                className={[scss.addonsSubHeading, scss.addonsTextLeft].join(
                  ' '
                )}
              >
                {products[index].category}
              </span>
            </div>
          </div>
          {Object.keys(products[index].price).length ? (
            <div className={scss.planColumn}>
              <span className={scss.addonsHeading}>
                $ {products[index].price.amount}
              </span>
              <span className={scss.addonsSubHeading}>
                per {products[index].price.recurring}
              </span>
            </div>
          ) : null}
        </div>
      </label>
    </div>
  )
}, areEqual)

function SimpleDialog({
  profilePlan,
  addons,
  visible,
  customer,
  subscription,
  updateProfile,
}) {
  const [open, setOpen] = useState(true)
  const [fetchObjects, setFetchObjects] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [products, setProducts] = useState(null)

  const [updateObjects, setUpdateObjects] = useState(null)
  const [updateFetching, setUpdateFetching] = useState(false)

  // Save Button Enable
  const [enableSave, setEnableSave] = useState(false)

  // Fields and their States
  const [addonsState, setAddons] = useState(addons)
  const [modificationArray, setModificationArray] = useState([])
  const [currentAddonTotal, setCurrentAddonTotal] = useState(null)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()

  const fetchFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        // build available products object
        const productsArray = []
        res.data.forEach(category => {
          let priceObject = {}
          category.prices.forEach(price => {
            if (price.recurring.interval === profilePlan.interval) {
              priceObject = {
                amount: price.unit_amount / 100,
                recurring: price.recurring.interval,
                stripePriceID: price.id,
              }
            }
          })

          subscription.items.data.forEach(item => {
            category.prices.forEach(price => {
              if (item.plan.product === price.product) {
                category.stripeSubItemID = item.id
              }
            })
          })

          category.items.forEach(item => {
            const itemObj = {
              id: item.id,
              name: item.name,
              category: category.name,
              stripeSubID: category.stripeSubItemID,
              price: priceObject,
              active: false,
            }
            productsArray.push(itemObj)
          })
        })

        // find active and inactive items, then set to state
        if (addonsState.length) {
          addonsState.forEach(obj => {
            if (obj.items.length) {
              obj.items.forEach(item => {
                productsArray.forEach(product => {
                  if (product.name === item.name) {
                    product.active = true
                  }
                })
              })
            }
          })
        }
        setProducts(productsArray)
      }
    })

    setFetchObjects(null)
    setFetching(false)
  }

  const handleCancel = () => {
    setOpen(false)
    visible(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    visible(false)
  }

  useEffect(() => {
    const url = apis.apiDatabase.uri + 'addons/available'
    const method = 'POST'
    const body = {}

    setFetching(true)
    setFetchObjects([{ url, method, body }])
  }, [])

  const checkModArray = targetProduct => {
    let response = false
    modificationArray.forEach(item => {
      if (item === targetProduct) response = true
    })
    return response
  }

  const getSumPrices = () => {
    let total = 0
    modificationArray.forEach(item => {
      if (item.method === 'add') {
        total += item.price.amount
      } else if (item.method === 'subtract') {
        total -= item.price.amount
      }
    })
    return parseFloat(total.toFixed(2))
  }

  const processProduct = targetProduct => {
    // check if exists in the active products
    let exists = false
    addons.forEach(item => {
      item.items.forEach(obj => {
        if (obj.name === targetProduct.name) exists = true
      })
    })

    if (exists) {
      // if it does, subtracting total
      targetProduct.method = 'subtract'
    } else {
      // if not, adding total
      targetProduct.method = 'add'
    }
    return targetProduct
  }

  const getSumMods = modArray => {
    const addArray = []
    const subArray = []

    modArray.forEach(item => {
      if (item.method === 'add') addArray.push(item)
      if (item.method === 'subtract') subArray.push(item)
    })

    return addArray.length - subArray.length
  }

  const toggleProductActive = targetProduct => {
    let idx
    products.forEach((product, i) => {
      if (product.name === targetProduct.name) idx = i
    })

    const newProducts = products.slice()
    newProducts[idx].active = !newProducts[idx].active

    setProducts(newProducts)
    const processedItem = processProduct(targetProduct)

    const existsInModArray = checkModArray(targetProduct)
    if (!existsInModArray) {
      setModificationArray([...modificationArray, processedItem])
    } else {
      const newModArray = modificationArray.slice()
      modificationArray.forEach((item, i) => {
        if (item.name === targetProduct.name) {
          newModArray.splice(i, 1)
        }
      })
      setModificationArray(newModArray)
    }
  }

  const handleSave = () => {
    const url = apis.apiDatabase.uri + 'addons/account/update'
    const method = 'POST'
    const body = {
      accountID: user.accountID,
      addonList: modificationArray,
      subscriptionID: subscription.id,
    }

    setUpdateFetching(true)
    setUpdateObjects([{ url, method, body }])
  }

  const updateFinished = results => {
    results.forEach(res => {
      if (utils.verifyResult(res)) {
        updateProfile(res.data)
        handleOnClose()
      }
    })

    setUpdateObjects(null)
    setUpdateFetching(false)
  }

  const handlePlanPricing = () => {
    if (profilePlan.tiers) {
      return getStripePlanPrice(profilePlan, profilePlan.quantity)
    }
    const amount = parseFloat(
      ((profilePlan.amount / 100) * profilePlan.quantity).toFixed(2)
    )
    return amount
  }

  const getCurrentAddonTotal = () => {
    const arr = []
    // get prices for active addons
    if (products) {
      products.forEach(product => {
        if (product.active && product.price.amount)
          arr.push(product.price.amount)
      })

      // get sum of prices
      const sum = arr.reduce((a, b) => {
        return a + b
      }, 0)
      return parseFloat(sum.toFixed(2))
    }
    return 0
  }

  const itemData = useMemo(() => {
    return {
      products,
      toggleProductActive,
    }
  }, [products, toggleProductActive])

  useEffect(() => {
    if (products) {
      const cT = getCurrentAddonTotal()
      setCurrentAddonTotal(cT)
    }
  }, [products])

  const sumOfMods = getSumMods(modificationArray)
  const currentPlanTotal = handlePlanPricing()

  return (
    <ThemeProvider theme={mainModalTheme}>
      {fetchObjects && (
        <AsyncFetch fetchObjects={fetchObjects} fetchFinished={fetchFinished} />
      )}
      {updateObjects && (
        <AsyncFetch
          fetchObjects={updateObjects}
          fetchFinished={updateFinished}
        />
      )}
      <Dialog
        onClose={handleOnClose}
        aria-labelledby='profile-addons-dialog'
        open={open}
        className={modalScss.lightModal}
        maxWidth='lg'
      >
        <DialogTitle id='profile-addons'>
          Additional Datasets for Purchase
          <Icon
            onClick={handleOnClose}
            icon='times'
            size='sm'
            pull='right'
          />
        </DialogTitle>
        <DialogContent className={modalScss.MuiDialogBoxes}>
          <div className={scss.addonsContainer}>
            {products && !fetching ? (
              <List
                height={450}
                width={850}
                itemCount={products.length}
                itemSize={105}
                itemData={{ itemData }}
              >
                {Row}
              </List>
            ) : null}
            {/* ADDON SIDEBAR */}
            <div
              className={[
                scss.addonsSidebar,
                scss.planColumn,
                scss.planJustify,
              ].join(' ')}
            >
              {/* TOP ROWS WITH THE LINE ITEMS */}
              <div className={scss.addonsSidebarTop}>
                {modificationArray.map(item => (
                  <div
                    key={`mod-${item.name}`}
                    className={[
                      scss.addonItem,
                      scss.planRow,
                      scss.planJustify,
                    ].join(' ')}
                  >
                    <div className={scss.planColumn}>
                      <span className={scss.planHeading}>{item.name}</span>
                      <span className={scss.planSubHeading}>
                        {item.description}
                      </span>
                    </div>
                    <div className={scss.planColumn}>
                      <span className={scss.planHeading}>
                        $
                        {item.method === 'subtract'
                          ? `(- ${item.price.amount})`
                          : item.price.amount}
                      </span>
                      <span className={scss.planSubHeading}>
                        per {item.price.recurring}
                      </span>
                    </div>
                  </div>
                ))}
              </div>

              {/* BOTTOM ROWS WITH THE TOTALS */}
              <div>
                <div
                  className={[
                    scss.planJustify,
                    scss.planRow,
                    scss.planBodyOne,
                  ].join(' ')}
                >
                  <span className={scss.planHeading}>Current Plan Total</span>
                  <span className={scss.planHeading}>${currentPlanTotal}</span>
                </div>
                <div
                  className={[
                    scss.planJustify,
                    scss.planRow,
                    scss.planBodyOne,
                  ].join(' ')}
                >
                  <span className={scss.planHeading}>Current Addon Total</span>
                  <span className={scss.planHeading}>${currentAddonTotal}</span>
                </div>
                <div
                  className={[
                    scss.planJustify,
                    scss.planRow,
                    scss.planBodyTwo,
                  ].join(' ')}
                >
                  <span className={scss.planHeading}>
                    {Math.abs(sumOfMods)}{' '}
                    {sumOfMods >= 0 ? 'Additional' : 'Less'}{' '}
                    {sumOfMods > 1 || sumOfMods < -1 ? 'Add-ons' : 'Add-on'}
                  </span>
                </div>
                <div
                  className={[
                    scss.planJustify,
                    scss.planRow,
                    scss.planBodyThree,
                  ].join(' ')}
                >
                  <span className={scss.planHeading}>New Total Per Month</span>
                  <span className={scss.planHeading}>
                    ${(currentPlanTotal + currentAddonTotal).toFixed(2)}
                  </span>
                </div>
              </div>
              {/* END OF BOTTOM ROWS */}
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <button
            type='button'
            className={appScss.altBlueButton}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className={appScss.blueButton}
            // disabled
            onClick={updateFetching ? null : handleSave}
          >
            {updateFetching ? (
              <Icon icon='spinner' size='1x' fixedWidth spin />
            ) : (
              'Update Subscription'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default function ProfileAddons(props) {
  return <SimpleDialog {...props} />
}
