/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import 'react-checkbox-tree/lib/react-checkbox-tree.css'
import CheckboxTree from 'react-checkbox-tree'
import * as DocumentUtil from '../DocumentBrowserV2Util'
import Icon from '../../../components/Icon/Icon'

const FileTree = ({
  nodes,
  checked,
  expanded,
  setChecked,
  setExpanded,
  setCwd,
  accountID,
  setShowSearchResults,
}) => {
  const getCWD = node => {
    const { value } = node
    setShowSearchResults(false)
    setCwd(
      'client_data/' +
        accountID +
        '/' +
        DocumentUtil.findPathToClickedNode(nodes, value)
    )
  }

  return (
    <div
      style={{
        maxHeight: '600px',
      }}
    >
      <CheckboxTree
        nodes={nodes}
        checked={checked}
        expanded={expanded}
        onClick={node => {
          getCWD(node)
        }}
        onCheck={checkedVal => setChecked([...checkedVal])}
        onExpand={expandedVal => setExpanded([...expandedVal])}
        icons={{
          parentClose: (
            <Icon
              className='rct-icon rct-icon-parent-close'
              icon='folder'
              style={{
                color: 'rgb(92, 141, 163)',
              }}
            />
          ),
          parentOpen: (
            <Icon
              className='rct-icon rct-icon-parent-close'
              icon='folder'
              style={{
                color: 'rgb(92, 141, 163)',
              }}
            />
          ),
          expandClose: (
            <Icon
              className='rct-icon rct-icon-expand-close fa-lg'
              icon='caret-right'
              style={{
                color: 'white',
              }}
            />
          ),
          expandOpen: (
            <Icon
              className='rct-icon rct-icon-expand-open fa-lg'
              icon='caret-down'
              style={{
                color: 'white',
              }}
            />
          ),
        }}
        onlyLeafCheckboxes
      />
    </div>
  )
}
export default FileTree
