import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Icon from '../../../../components/Icon/Icon'

// utils
import { setToolConfig, mountMapViews } from '../../../../actions/index'

// components
import Button from '../../../../components/Button/Button'
import Tabs from '../../../../components/Tabs/Tabs'
import MapViewInfo from './MapViewInfo'
import MapViewFooter from './MapViewFooter'

// scss files
import scss from './MapViewSettings.scss'

export default function MapViewSettings({
  config,
  mapStyle,
  onCollapse,
  onClose,
}) {
  const toolConfig = useSelector(state => state.toolConfig)
  const [collapsed, setCollapsed] = useState(config.collapsed)
  const [columnTabs, setColumnTabs] = useState(false)
  const [mountKey, setMountKey] = useState(1)
  const [closeToolKey, setCloseToolKey] = useState(null)

  const dispatch = useDispatch()

  const closeTool = () => {
    toolConfig.forEach(tool => {
      if (tool.name === 'MapViewSettings') {
        tool.visible = false
        tool.group = null
      }
    })

    dispatch(setToolConfig(toolConfig))
    setMountKey(mountKey + 1)
    dispatch(mountMapViews(mountKey))
    onClose()
  }

  const getCollapsedUI = () => {
    let ui = (
      <div
        className={[
          scss.toolQuickActionButtonWrapper,
          scss.toolQuickActionButtonWrapper,
        ].join(' ')}
      >
        <Button
          onClick={() => setToolCollapsed(false)}
          leadingIcon='expand-alt'
          leadingIconSize='lg'
          buttonType='toolHeaderButton'
          title='Expand'
        />
      </div>
    )
    return (
      <div
        className={[
          scss.toolQuickActionHeader,
          scss.toolQuickActionHeader,
        ].join(' ')}
      >
        <div className={[scss.handleContainer, 'handle'].join(' ')}>
          <Icon icon={['fas', 'map-marked-alt']} size='1x' />
        </div>
        <div className={scss.toolHeaderButtonWrapper}>{ui}</div>
      </div>
    )
  }

  const setToolCollapsed = collapsed => {
    onCollapse(config.name, collapsed)
    setCollapsed(collapsed)
  }

  const getFullUI = () => {
    let collapseIcon = 'minus'

    const ui = (
      <>
        <div className={[scss.toolBody, scss.layerSettingsTabs].join(' ')}>
          <Tabs
            className={scss.toolBodyTabGroup}
            column={columnTabs}
            customClass='leftTabs'
          >
            <div className={scss.toolBodyTab} icon='cog' label='Settings'>
              <MapViewInfo
                className={scss.toolBodyTab}
                key={mountKey + 'mapviewInfo'}
                mapStyle={mapStyle}
                config={config}
                closeTool={closeTool}
                mountKey={mountKey}
              />
            </div>
          </Tabs>
        </div>
        <MapViewFooter
          className={scss.toolBodyTab}
          key={mountKey + 'mapviewInfo'}
          mapStyle={mapStyle}
          config={config}
          closeTool={closeTool}
          mountKey={mountKey}
        />
      </>
    )

    let name = config.title ? config.title : ''
    let description = config.description ? config.description : ''

    return (
      <div className={scss.toolExpanded}>
        <div className={[scss.toolHeader, scss.toolHeader, 'handle'].join(' ')}>
          <div className={[scss.toolTitle, scss.toolTitle].join(' ')}>
            {config.targetMapView.label}
          </div>
          <div className={scss.toolHeaderButtonWrapper}>
            <Button
              onClick={() => setToolCollapsed(true)}
              leadingIcon={collapseIcon}
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Minimize'
            />
            <Button
              onClick={() => {
                closeTool()
                config.updatePanel()
              }}
              leadingIcon='times'
              leadingIconSize='lg'
              buttonType='toolHeaderButton'
              title='Close'
            />
          </div>
        </div>
        {ui}
      </div>
    )
  }

  return collapsed ? getCollapsedUI() : getFullUI()
}
