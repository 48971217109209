import React, { useState, useEffect } from 'react'
import Icon from '../Icon/Icon'
import Select from 'react-select'
import scss from './ReactSelect.scss'

/**
 * A dropdown presentational component
 * Displays a list of options and runs callback function on selection
 *
 * @param {string [] | string | integer} [defaultValue] The initial value
 * of the select field. Updating this value will force re-render
 * @param {string []} [options] The options the dropdown should display
 * @param {function} onChange The callback function with the new value
 * as a parameter
 * @param {boolean} [dark] Optional param for dark styling
 * @param {boolean} [isFixed] Optional param for fixed positioning
 * @param {boolean} [multiple] Optional param for a multi select field
 */

const ReactSelect = ({
  onChange,
  options,
  defaultValue,
  light,
  noField,
  loading,
  disabled,
  width,
  isFixed,
  multiple,
  portalPlacement,
  placeholder,
}) => {
  /* -------------------------- RESIZED REACT SELECT -------------------------- */
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#213445',
      borderColor: '#41678b',
      minHeight: '32px',
      height: 'auto',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: provided => ({
      ...provided,
      height: 'auto',
      padding: '0 6px',
    }),

    input: provided => ({
      ...provided,
      margin: '0px',
      height: '100%',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: provided => ({
      ...provided,
      height: 'auto',
    }),

    menuPortal: provided => ({
      ...provided,
      zIndex: '9999',
    }),
  }
  /* -------------------------------------------------------------------------- */

  const [newValue, setNewValue] = useState(defaultValue)

  // If the value is changed, then update newValue
  useEffect(() => {
    setNewValue(defaultValue)
  }, [defaultValue])

  const handleClick = valueList => {
    if (!Array.isArray(valueList)) valueList = [valueList]
    let newList = valueList.map(value => value && value.value)
    setNewValue(valueList)

    if (!multiple && newList.length === 1) onChange(newList[0], valueList[0])
    // Respond with e.value AND e, in case we need access to other keys within e - Jordan 12/17
    else onChange(newList, valueList)
  }

  return (
    <div
      className={[scss.reactSelect, scss['flex-row'], scss['flex-center']].join(
        ' '
      )}
      style={{ width: width || null }}
    >
      <Select
        options={options}
        value={newValue}
        isLoading={loading}
        isDisabled={disabled}
        className={`${multiple ? 'multi-select' : ''}
          ${light ? '' : 'darkSelect'}
          ${noField ? 'noSelectField' : ''}
          reactSelect`}
        classNamePrefix='reactSelect'
        styles={customStyles}
        onChange={handleClick}
        isMulti={multiple ? true : false}
        menuPortalTarget={isFixed ? document.body : null}
        menuPosition={isFixed ? 'fixed' : 'absolute'}
        menuPlacement={portalPlacement}
        placeholder={placeholder ? placeholder : 'Select'}
      />
      <Icon className={scss.selectIcon} icon='chevron-down' />
    </div>
  )
}

export default ReactSelect
