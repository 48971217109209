import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Icon from '../../../components/Icon/Icon'

// utils
import { setToolConfig } from '../../../actions/index'
import { getVisibleLayersFromDataConfig } from '../../../utilities/dataConfig'

// components
import Button from '../../../components/Button/Button'
import LineSwatch from './LegendSwatch/LineSwatch'
import FillSwatch from './LegendSwatch/FillSwatch'
import CircleSwatch from './LegendSwatch/CircleSwatch'

// scss files
import scss from './Legend.scss'

export default function Legend({
  onClose,
  onCollapse,
  mapStyle,
  config,
  dataConfig,
}) {
  const dispatch = useDispatch()
  const toolConfig = useSelector(state => state.toolConfig)
  const [collapsed, setCollapsed] = useState(config.collapsed)
  const [excludedLayers, setExcludedLayers] = useState([])
  const [hoverItem, setHoverItem] = useState('')

  const setToolCollapsed = collapsed => {
    setCollapsed(collapsed)
    onCollapse(config.name, collapsed)
  }

  const excludeLayer = id => {
    excludedLayers.push(id)
    setExcludedLayers([...excludedLayers])
  }

  const refreshLegend = () => setExcludedLayers([])

  const getLegendLayers = () => {
    const style = mapStyle.toJS()
    const tocLayers = dataConfig['tocLayers']
    const legendLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    const uniqueLegendLayers = Array.from(
      new Set(legendLayers.map(JSON.stringify)),
      JSON.parse
    )

    const legendUi = []
    uniqueLegendLayers.reverse().forEach(legendLayer => {
      if (legendLayer.layersArray[0].layer.type === 'raster') return
      if (excludedLayers.includes(legendLayer.layersArray[0].layer.id)) return
      const key = legendLayer.toc.id
      let title = null
      let zoomLabel = legendLayer.toc.label
      // if simple style we want to align the title with the swatch by using display:flex
      let paintPorps = legendLayer.layersArray[0].layer.paint
      let outlinePaintPorps
      let flexAlignTitle = true

      if (legendLayer.layersArray[0].outline)
        outlinePaintPorps = legendLayer.layersArray[0].outline.paint

      for (let [key, value] of Object.entries(paintPorps)) {
        if (Array.isArray(value)) {
          const flat = value.flat().join(',')
          if (flat.indexOf('case,==,get') !== -1) {
            // data expression
            flexAlignTitle = false
            title = <div className={scss.title}>{legendLayer.toc.label}</div>
          } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
            // zoom expression
            flexAlignTitle = true
            title = null
            zoomLabel = legendLayer.toc.label
          }
        }
      }
      if (outlinePaintPorps) {
        for (let [key, value] of Object.entries(outlinePaintPorps)) {
          if (Array.isArray(value)) {
            const flat = value.flat().join(',')
            if (flat.indexOf('case,==,get') !== -1) {
              // data expression
              flexAlignTitle = false
              title = <div className={scss.title}>{legendLayer.toc.label}</div>
            } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
              // zoom expression
              flexAlignTitle = true
              title = null
              zoomLabel = legendLayer.toc.label
            }
          }
        }
      }
      // always show title on layerPackages
      if (legendLayer.toc.layerPackage) {
        flexAlignTitle = false
      }

      let hideLayerHeader = null
      if (legendLayer.layersArray.length > 1) {
        hideLayerHeader = true
        // this title is for layers holding layers, like TX Wetlands
        title = <div className={scss.title}>{legendLayer.toc.label}</div>
      }

      const items = legendLayer.layersArray
        .filter(layerObj => !excludedLayers.includes(layerObj.layer.id))
        .filter(layerObj => !layerObj.layer.id.includes('_outline'))
        .filter(layerObj => !layerObj.layer.metadata.excludeFromLegend)
        .map(layerObj => {
          // get layer from mapStyle to build legend swatch
          const layer = style.layers.filter(
            layer => layer.id === layerObj.layer.id
          )
          const type = layer[0].type
          let outline = []
          if (type === 'fill') {
            outline = style.layers.filter(
              layer => layer.id === layerObj.layer.id + '_outline'
            )
          }

          const swatch = (
            <>
              {type === 'line' ? (
                <LineSwatch
                  layer={layer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader
                />
              ) : null}
              {type === 'fill' ? (
                <FillSwatch
                  layer={layer[0]}
                  outline={outline[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader
                />
              ) : null}
              {type === 'circle' ? (
                <CircleSwatch
                  layer={layer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader
                  zoomLabel={zoomLabel}
                />
              ) : null}
            </>
          )

          return swatch
        })

      if (items.length) {
        const btnStyle = {
          display:
            hoverItem === legendLayer.layersArray[0].layer.id ? 'grid' : 'none',
        }
        const deleteButton = (
          <>
            <div
              style={btnStyle}
              className={[
                scss.collapseBtn,
                hoverItem === legendLayer.layersArray[0].layer.id
                  ? scss.collapseBtnVisible
                  : '',
              ].join(' ')}
              onClick={() => excludeLayer(legendLayer.layersArray[0].layer.id)}
            >
              <Icon icon='times' size='1x' />
            </div>
          </>
        )
        const style = {
          display: flexAlignTitle ? 'flex' : 'block',
          flexDirection: 'column',

          marginTop:
            hoverItem === legendLayer.layersArray[0].layer.id && !title
              ? '-14px'
              : '8px',
          // alignItems: flexAlignTitle ? "center" : "none",
          // justifyContent: flexAlignTitle ? "space-between" : "none",
          // marginBottom: "7px"
        }
        legendUi.push(
          <div
            key={key}
            style={style}
            className={title ? '' : scss.deleteButtonRow}
            onMouseEnter={() =>
              setHoverItem(legendLayer.layersArray[0].layer.id)
            }
            onMouseLeave={() =>
              setHoverItem(legendLayer.layersArray[0].layer.id)
            }
          >
            {title ? (
              <div className={scss.titleWrapper}>
                {deleteButton}
                {title}
              </div>
            ) : null}

            {title ? null : deleteButton}
            {items}
          </div>
        )
      }
    })
    return <div className={scss.legendPanel}>{legendUi}</div>
  }

  const closeTool = () => {
    toolConfig.forEach(tool => {
      if (tool.name === config.name) {
        tool.visible = false
      }
    })
    // to maintain immutable redux state we need to clone toolConfig and send cloned to reducer
    let newToolConfig = JSON.parse(JSON.stringify(toolConfig))
    dispatch(setToolConfig(newToolConfig))
    // call on close method to enable all map interactions
    onClose()
  }

  const getCollapsedUI = () => {
    let ui = (
      <div className={scss.toolQuickActionButtonWrapper}>
        <Button
          onClick={() => setToolCollapsed(false)}
          leadingIcon='expand-alt'
          leadingIconSize='lg'
          buttonType='toolHeaderButton'
          title='Expand'
        />
      </div>
    )
    return (
      <div className={[scss.toolQuickActionHeader].join(' ')}>
        <div className={[scss.handleContainer, 'handle'].join(' ')}>
          <Icon icon={config.icon} size='1x' />
        </div>
        <div className={scss.toolHeaderButtonWrapper}>{ui}</div>
      </div>
    )
  }

  const getFullUI = () => {
    const legendLayers = getLegendLayers()
    const ui = (
      <div key='legend_ui' className={scss.toolBody}>
        <div className={scss.targetLayerPanel}>
          <div key={excludedLayers.length} className={scss.toolGroup}>
            {legendLayers}
          </div>
        </div>
      </div>
    )

    return (
      <div className={scss.toolExpanded}>
        <div className={[scss.toolHeader, 'handle'].join(' ')}>
          <div className={scss.toolHeaderRow}>
            <Icon icon='list-alt' size='1x' fixedWidth />
            <div className={scss.toolTitle}>{config.title}</div>
          </div>
          <div
            className={scss.toolHeaderButtonWrapper}
            style={{ maxHeight: '500px' }}
          >
            {excludedLayers.length > 0 && (
              <button
                type='button'
                title='Refresh Legend'
                className={scss.toolHeaderButton}
                onClick={refreshLegend}
              >
                <Icon icon='sync' size='1x' />
              </button>
            )}
            <button
              type='button'
              title='Collapse'
              className={scss.toolHeaderButton}
              onClick={() => setToolCollapsed(true)}
            >
              <Icon icon='minus' size='lg' />
            </button>
            <button
              type='button'
              title='Close'
              className={scss.toolHeaderButton}
              onClick={closeTool}
            >
              <Icon icon='times' size='lg' />
            </button>
          </div>
        </div>
        {ui}
      </div>
    )
  }

  useEffect(
    function () {
      setExcludedLayers(excludedLayers)
    },
    [excludedLayers]
  )

  return collapsed ? getCollapsedUI() : getFullUI()
}
