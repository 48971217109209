/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'
import { Tooltip } from 'react-tooltip'
import Icon from '../Icon/Icon'
import scss from './Tab.scss'

const Tab = React.memo(
  ({
    activeTab,
    icon = null,
    useManageAttributesIcon,
    displayManageAttributesMenu,
    setDisplayManageAttributesMenu,
    setContextMenuY,
    label,
    column,
    customClass,
    onClick,
  }) => {
    const tabClass = column === 'column' ? 'tabListItemColumn' : 'tabListItem'
    const tabClassActive = activeTab === label ? 'tabListActive' : ''
    const tabCustomClass = customClass || ''

    /**
     * This is a personalized icon that does not exist on the FontAwesomeIcon
     */
    const ManageAttributesIcon = () => {
      return (
        <button
          type='button'
          className={[scss.ManageAttributes].join(' ')}
          onClick={event => {
            setContextMenuY(event.clientY - 55)
            setDisplayManageAttributesMenu(true)
          }}
          style={{
            background: displayManageAttributesMenu ? '#1a2937' : null,
            border: 'none',
            height: 'inherit',
            width: '49px',
          }}
        >
          <div className={[scss.toolAlign].join(' ')}>
            <Icon
              icon={['far', 'list-tree']}
              size='1x'
              data-html
              data-tooltip-content='Attributes & Documents'
              data-tooltip-id='manage-attributes'
              data-effect='solid'
              data-arrow-color='rgba(0, 0, 0, 0.68)'
              data-place='bottom'
              style={{
                margin: '0 15px',
                color: displayManageAttributesMenu ? '#f1cb57' : null,
              }}
            />
            <sup
              style={{
                top: '-0.25em',
                left: '-0.75em',
                zIndex: '3',
                fontSize: 'large',
                color: displayManageAttributesMenu ? '#f1cb57' : null,
              }}
            >
              +
            </sup>
          </div>
        </button>
      )
    }

    return (
      <>
        <li
          className={[
            scss[tabClass],
            scss[tabClassActive],
            scss[tabCustomClass],
          ].join(' ')}
          onClick={() => onClick(label)}
          data-tooltip-content={label}
          data-tooltip-id={'for' + label}
          data-effect='solid'
          data-arrow-color='rgba(0, 0, 0, 0)'
          data-place='bottom'
        >
          {useManageAttributesIcon ? (
            ManageAttributesIcon()
          ) : icon ? (
            <Icon icon={icon} size='1x' />
          ) : (
            ''
          )}
          <span>{label}</span>
        </li>

        <div className={scss.reactToolTip}>
          <Tooltip
            id={'for' + label}
            className={scss.tabToolTip}
            getContent={dataTip => `${dataTip}`}
          />
        </div>
      </>
    )
  }
)

export default Tab
