/* eslint-disable guard-for-in */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../components/Icon/Icon'
import isEqual from 'react-fast-compare'
import scss from './TabViewSettings.scss'
import { PropertyTabV2 } from './PropertyTabV2/PropertyTabV2'
import { apis } from '../../../config/apiConfig'
import AsyncFetch from '../../../utilities/AsyncFetch'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import AddTabButtonV2 from './PropertyTabV2/AddTabButtonV2'
import { makeUniqueIDNumbersOnly } from '../../../utilities/util'
import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

/**
 * NOTES
 * TabViewSettings Component has to render both Documents tabs and Attribute tabs,
 * some redundant logic has to be coded to handle the different values and preserve order
 */
const TabViewSettings = ({
  onClose,
  layerProperties,
  layerId,
  mapStyle,
  config,
  layerTabs,
  isDocumentWindow,
  isAttributeWindow,
  mapviewSetTabs,
  updateDataConfig,
  featureProperties,
  featureDocumentAttributes,
  featureAttributes,
}) => {
  const [open, setOpen] = useState(true)
  const [doingSave, setDoingSave] = useState(false)
  const [items, setItems] = useState({})

  // Tabs
  const [tabs, setTabs] = useState(layerTabs)
  const [originalTabs, setOriginalTabs] = useState()
  // If Attribute tab then restTabs = Documents, if Documents tab then restTabs = Attribute
  const [restTabs, setRestTabs] = useState()

  // Tabs order
  const [tabOrder, setTabOrder] = useState(['propertiesHolder'])
  const [originalTabOrder, setOriginalTabOrder] = useState(['propertiesHolder'])

  const [urlObjects, setUrlObjects] = useState(null)
  const [mountDataConfig, setMountDataConfig] = useState(null)
  const [displayDeleteTabPopup, setDisplayDeleteTabPopup] = useState(false)
  const [currentTabIdToDelete, setCurrentTabIdToDelete] = useState(null)

  const [displayApplyChanges, setDisplayApplyChanges] = useState(false)

  useEffect(() => {
    const itemsVal = {}
    const itemsIds = []
    let tabOrderValues = []

    if (layerProperties && Array.isArray(layerProperties)) {
      layerProperties.forEach(property => {
        const id = property.id.toString()
        itemsVal[id] = { id, content: property.name }
        itemsIds.push(id)
      })

      const propertiesHolder = {
        id: 'propertiesHolder',
        title: 'Unassigned',
        itemsIds,
      }

      let tabsPropertiesHolder = {
        propertiesHolder,
      }

      if (config.tabs) {
        tabsPropertiesHolder = config.tabs
      }

      let tabsValues = addNewPropsToTabs(tabsPropertiesHolder, layerProperties)
      tabsValues = removeDeletedPropsFromTabs(
        tabsPropertiesHolder,
        layerProperties
      )

      // Convert object to object into array to use filter
      tabsValues = Object.keys(tabsValues).map(key => {
        return tabsValues[key]
      })

      if (isDocumentWindow) {
        // Save the tab values not used on this window (Attributes)
        setRestTabs(
          tabsValues.filter(
            tab => tab.category && tab.category === 'Attributes'
          )
        )
        tabsValues = tabsValues.filter(
          tab =>
            (tab.category && tab.category === 'Documents') ||
            tab.id === 'propertiesHolder'
        )
      }

      if (isAttributeWindow) {
        // Save the tab values not used on this window (Documents)
        setRestTabs(
          tabsValues.filter(tab => tab.category && tab.category === 'Documents')
        )

        tabsValues = tabsValues.filter(
          tab =>
            !tab.category ||
            (tab.category && tab.category === 'Attributes') ||
            tab.id === 'propertiesHolder'
        )
      }

      tabOrderValues = tabsValues.map(tab => tab.id)

      tabsValues = tabsValues.map(tab => {
        return { [tab.id]: tab }
      })

      // Convert array back into Object to object
      tabsValues = Object.assign({}, ...tabsValues)

      setItems(itemsVal)

      setTabOrder(tabOrderValues)
      setOriginalTabOrder(tabOrderValues)

      setTabs(tabsValues)
      setOriginalTabs(tabsValues)
    }
  }, [])

  useEffect(() => {
    if (tabs && originalTabs) {
      if (
        !isEqual(tabs, originalTabs) ||
        !isEqual(tabOrder, originalTabOrder)
      ) {
        setDisplayApplyChanges(true)
      } else {
        setDisplayApplyChanges(false)
      }
    }
  }, [tabs, tabOrder])

  const addNewPropsToTabs = (tabs, layerProperties) => {
    // adds any newly added properties to tabs placeholder
    layerProperties.forEach(property => {
      let propertyFound = false
      const id = property.id.toString()
      for (const tab in tabs) {
        const { itemsIds } = tabs[tab]
        const found = itemsIds.includes(id)
        if (found) propertyFound = true
      }

      if (!propertyFound) {
        tabs.propertiesHolder.itemsIds.push(id)
      }
    })
    return tabs
  }

  const removeDeletedPropsFromTabs = (tabs, layerProperties) => {
    // removes any deleted properties from tabs
    let allIds = []
    for (const tab in tabs) {
      allIds = [...allIds, ...tabs[tab].itemsIds]
    }

    allIds.forEach(id => {
      const found = layerProperties.filter(
        property => property.id === parseInt(id)
      )
      if (!found.length) {
        // property has been deleted, remove from tabs
        for (const tab in tabs) {
          const newIds = tabs[tab].itemsIds.filter(itemId => itemId !== id)
          tabs[tab].itemsIds = newIds
        }
      }
    })
    return tabs
  }

  const addTab = () => {
    let tabCount = Object.keys(tabs).length
    tabCount += 1
    const tabId = 'tab-' + makeUniqueIDNumbersOnly(7)
    const newTab = {
      id: tabId,
      title: 'New Tab',
      itemsIds: [],
    }

    if (isAttributeWindow) newTab.category = 'Attributes'
    if (isDocumentWindow) newTab.category = 'Documents'

    const tabValues = tabs
    const tabOrderValues = tabOrder
    tabValues[tabId] = newTab
    tabOrderValues.push(tabId)

    setTabs(tabValues)
    setTabOrder(tabOrderValues)
    setDisplayApplyChanges(true)
    setBoardUi(generateBoard())
  }

  const deleteTab = () => {
    const tabsValues = tabs
    const { itemsIds } = tabsValues[currentTabIdToDelete]
    const propertiesHolderIds = tabsValues.propertiesHolder.itemsIds
    tabsValues.propertiesHolder.itemsIds = [...propertiesHolderIds, ...itemsIds]
    delete tabsValues[currentTabIdToDelete]
    const tabOrderValues = tabOrder.filter(tab => tab !== currentTabIdToDelete)
    setTabs(tabsValues)
    setTabOrder(tabOrderValues)
  }

  const tabReorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const handleTitleChange = (tabKey, value) => {
    tabs[tabKey].title = value
    setTabs(tabs)
  }

  const getExistingMetadata = () => {
    const style = mapStyle.toJS()
    const layer = style.layers.filter(layerVal => layerVal.id === layerId)
    const metadata = layer[0].metadata || {}
    return metadata
  }

  const saveConfiguration = () => {
    const tabConfig = {}

    if (typeof tabs.propertiesHolder !== 'undefined') {
      tabConfig.propertiesHolder = tabs.propertiesHolder
    }

    tabOrder.forEach(tab => {
      if (tab in tabs) {
        tabConfig[tab] = tabs[tab]
      }
    })

    config.tabs = { ...tabConfig, ...restTabs }
    mapviewSetTabs({ ...tabConfig, ...restTabs })
    const existingMetaData = getExistingMetadata()
    existingMetaData.popup = config

    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/update/layer'
    const body = {
      layerID: layerId,
      metadata: existingMetaData,
    }

    const urlObjectsValues = [{ url, body, method }]

    setDoingSave(true)
    setUrlObjects(urlObjectsValues)
  }

  useEffect(() => {
    setUrlObjects(null)
  }, [doingSave, urlObjects])

  const fetchFinished = () => {
    setMountDataConfig(true)
  }

  useEffect(() => {
    setMountDataConfig(false)
  }, [mountDataConfig])

  const dataConfigUpdated = () => {
    setDoingSave(false)
    setOpen(false)
    updateDataConfig()
    onClose()
  }

  // Handle drag & drop
  const onDragEnd = result => {
    const { source, destination, draggableId } = result

    // Do nothing if item is dropped outside the list
    if (!destination) {
      return
    }

    // Do nothing if the item is dropped into the same place
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const tabOrderVal = tabReorder(tabOrder, source.index, destination.index)
      setTabOrder(tabOrderVal)
      return
    }

    // Find column from which the item was dragged from
    const columnStart = tabs[source.droppableId]

    // Find column in which the item was dropped
    const columnFinish = tabs[destination.droppableId]

    // Moving items in the same list
    if (columnStart === columnFinish) {
      // Get all item ids in currently active list
      const newItemsIds = Array.from(columnStart.itemsIds)

      // Remove the id of dragged item from its original position
      newItemsIds.splice(source.index, 1)

      // Insert the id of dragged item to the new position
      newItemsIds.splice(destination.index, 0, draggableId)

      // Create new, updated, object with data for tabs
      const newColumnStart = {
        ...columnStart,
        itemsIds: newItemsIds,
      }

      // Create new board state with updated data for tabs
      setTabs({ ...tabs, [newColumnStart.id]: newColumnStart })

      // Update the board state with new data
    } else {
      // Moving items from one list to another
      // Get all item ids in source list
      const newStartItemsIds = Array.from(columnStart.itemsIds)

      // Remove the id of dragged item from its original position
      newStartItemsIds.splice(source.index, 1)

      // Create new, updated, object with data for source column
      const newColumnStart = {
        ...columnStart,
        itemsIds: newStartItemsIds,
      }

      // Get all item ids in destination list
      const newFinishItemsIds = Array.from(columnFinish.itemsIds)

      // Insert the id of dragged item to the new position in destination list
      newFinishItemsIds.splice(destination.index, 0, draggableId)

      // Create new, updated, object with data for destination column
      const newColumnFinish = {
        ...columnFinish,
        itemsIds: newFinishItemsIds,
      }

      // Create new board state with updated data for both, source and destination tabs
      // Update the board state with new data
      setTabs({
        ...tabs,
        [newColumnStart.id]: newColumnStart,
        [newColumnFinish.id]: newColumnFinish,
      })
    }
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose()
  }

  const getPropertiesBasedOnTabType = () => {
    if (isDocumentWindow) {
      if (!featureDocumentAttributes) return []
      const withNestedKeys = featureDocumentAttributes.map(item =>
        String(item.id)
      )
      return withNestedKeys || []
    }

    if (isAttributeWindow) {
      if (!featureAttributes) return []
      const withNestedKeys = featureAttributes.map(item => String(item.id))
      return withNestedKeys || []
    }

    return []
  }

  const generateBoard = () => {
    return (
      <Droppable droppableId='board' type='COLUMN' direction='horizontal'>
        {provided => (
          <div
            className={scss.settingsContainer}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {tabOrder.map((key, index) => {
              // get id of the current tab

              const column = tabs[key]

              // Verifying only properties for each document window is here
              const PropertiesForThisTab = getPropertiesBasedOnTabType() || []

              // get items belonging to current tab
              const itemsValues = column.itemsIds
                .map(itemId => {
                  if (PropertiesForThisTab.includes(itemId)) {
                    return items[itemId]
                  }
                  return null
                })
                .filter(item => item !== null)

              // Render
              return (
                <PropertyTabV2
                  key={key}
                  index={index}
                  title={key}
                  items={itemsValues}
                  handleTitleChange={handleTitleChange}
                  deleteTab={deleteTab}
                  column={column}
                  useClone={null}
                  setCurrentTabIdToDelete={setCurrentTabIdToDelete}
                  setDisplayDeleteTabPopup={setDisplayDeleteTabPopup}
                  setDisplayApplyChanges={setDisplayApplyChanges}
                  isDocumentWindow={isDocumentWindow}
                  isAttributeWindow={isAttributeWindow}
                  featureProperties={featureProperties}
                  featureDocumentAttributes={featureDocumentAttributes}
                  featureAttributes={featureAttributes}
                />
              )
            })}
            {provided.placeholder}
            <AddTabButtonV2 addTab={addTab} />
          </div>
        )}
      </Droppable>
    )
  }

  const [boardUi, setBoardUi] = useState(null)

  useEffect(() => {
    if (tabs && tabOrder) {
      tabOrder.sort((x, y) => {
        return x === 'propertiesHolder' ? -1 : y === 'propertiesHolder' ? 1 : 0
      })
      setBoardUi(generateBoard())
    }
  }, [tabs, tabOrder])

  // Handle premature re-render
  if (!tabs || !Object.keys(tabs).length) return null

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {urlObjects && (
          <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
        )}
        {mountDataConfig && (
          <MakeDataConfigComponent onFinish={dataConfigUpdated} />
        )}
        <Dialog
          onClose={handleOnClose}
          aria-labelledby='popup-settings-dialog'
          open={open}
          maxWidth='md'
        >
          <DialogTitle id='popup-settings'>Editing: {config.label}</DialogTitle>
          <DialogContent>
            <p className={appScss.textItalic}>
              Drag and drop your tabs and attributes to customize your view
            </p>
            <p>&nbsp;</p>
            <DragDropContext onDragEnd={onDragEnd}>{boardUi}</DragDropContext>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: 'flex-end', minHeight: '65px' }}
          >
            {displayApplyChanges && (
              <button
                className={appScss.blueButton}
                type='button'
                onClick={saveConfiguration}
              >
                {doingSave ? <CircularProgress size={10} /> : 'Save'}
              </button>
            )}
          </DialogActions>
        </Dialog>
      </ThemeProvider>

      {displayDeleteTabPopup && (
        <ThemeProvider theme={mainModalTheme}>
          <Dialog
            onClose={() => {
              setDisplayDeleteTabPopup(false)
            }}
            open={open}
          >
            <DialogTitle>
              Delete Tab
              <Icon
                onClick={() => {}}
                icon='times'
                size='lg'
                pull='right'
              />
            </DialogTitle>

            <DialogContent className={mainModalTheme.MuiDialogBoxes}>
              <div>
                <p>Are you sure you want to delete this tab?</p>
              </div>
            </DialogContent>

            <DialogActions>
              <button
                className={appScss.altBlueButton}
                type='button'
                onClick={() => {
                  setDisplayDeleteTabPopup(false)
                }}
              >
                Cancel
              </button>
              <button
                className={appScss.blueButton}
                type='button'
                onClick={() => {
                  setDisplayDeleteTabPopup(false)
                  deleteTab()
                }}
              >
                Delete
              </button>
            </DialogActions>
          </Dialog>
        </ThemeProvider>
      )}
    </>
  )
}
export default TabViewSettings
