import React, { useState, useEffect } from 'react'
import { StyleInput } from '../StyleInput/StyleInput.jsx'
import { clone } from '../../../../../utilities/geospatial'
import scss from './DataLevels.scss'
import Icon from '../../../../../components/Icon/Icon'

const Row = ({ index, style, data }) => {
  const [mountKey, setMountKey] = useState(1) 

  const { itemData } = data
  const {
    layerId,
    layerPaintProps,
    layerType,
    handleUpdate,
    outLinePaintProps,
    listItems,
    defObj,
    handleCleanUp,
  } = itemData

  let dirty = false
  let paintProps = clone(layerPaintProps)
  let currentPaintProps = []

  const handleCleanUpClick = targetCase => {
    setMountKey(mountKey + 1)
    handleCleanUp(targetCase)
  }

  listItems.forEach(kVal => {
    let obj = { paint: {}, outlinePaint: {} }
    obj.case = kVal

    for (const [key, val] of Object.entries(paintProps)) {
      if (Array.isArray(val)) {
        for (let i = 0; i < val.length; i++) {
          if (Array.isArray(val[i]) && val[i][2] === kVal) {
            obj.paint[key] = val[i + 1]
          }
        }
      } else {
        obj.paint[key] = val
      }
    }

    if (outLinePaintProps) {
      let outlineProps = clone(outLinePaintProps)
      for (const [key, val] of Object.entries(outlineProps)) {
        if (Array.isArray(val)) {
          for (let i = 0; i < val.length; i++) {
            if (Array.isArray(val[i]) && val[i][2] === kVal) {
              obj.outlinePaint[key] = val[i + 1]
            }
          }
        } else {
          obj.outlinePaint[key] = val
        }
      }
    } else {
      obj.outlinePaint = null
    }

    for (const [key, val] of Object.entries(defObj.paint)) {
      if (obj.paint[key] == 'undefined' || obj.paint[key] == null) {
        obj.paint[key] = val
      }
    }

    if (defObj.outlinePaint) {
      for (const [key, val] of Object.entries(defObj.outlinePaint)) {
        if (
          obj.outlinePaint[key] == 'undefined' ||
          obj.outlinePaint[key] == null
        ) {
          obj.outlinePaint[key] = val
        }
      }
    }
    currentPaintProps.push(obj)
  })

  for (const [key, val] of Object.entries(defObj.paint)) {
    if (currentPaintProps[index].paint[key] !== val) dirty = true
  }

  if (defObj.outlinePaint) {
    for (const [key, val] of Object.entries(defObj.outlinePaint)) {
      if (currentPaintProps[index].outlinePaint[key] !== val) dirty = true
    }
  }

  let targetCase = currentPaintProps[index].case

  return (
    <div
      key={index + 'listItemContainer'}
      style={style}
      className={scss.dataRowContainer}
    >
      <div>
        <span key={index + 'listItem'} className={scss.dataName}>
          {currentPaintProps[index].case}
        </span>
        {dirty === true ? (
          <div className={scss.undoButton}>
            <Icon
              icon={['fas', 'undo-alt']}
              size='1x'
              pull='right'
              className={scss.undoButtonIcon}
              onClick={() => handleCleanUpClick(targetCase)}
            />
          </div>
        ) : null}
      </div>
      <StyleInput
        key={mountKey}
        layerId={layerId}
        layerPaintProps={currentPaintProps[index].paint}
        outLinePaintProps={currentPaintProps[index].outlinePaint}
        type={layerType}
        level={currentPaintProps[index].case}
        updateLayer={handleUpdate}
        databased
      />
    </div>
  )
}

export default Row
