import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  toggleMapLayersOn,
  toggleMapLayersOff,
  setToolConfig,
  groupClicked,
} from '../../../actions/index'
import Icon from '../../../components/Icon/Icon'
import { Droppable } from 'react-beautiful-dnd'
import Paper from '@material-ui/core/Paper'
import Collapse from '@material-ui/core/Collapse'
import logoSimplified from '../../../assets/logo/logo-simplified.svg'
import organizationLayerGroup from '../../../assets/icons/organizationLayerGroup.svg'
import sharedLayerGroup from '../../../assets/icons/sharedLayerGroup.svg'
import userLayerGroup from '../../../assets/icons/userLayerGroup.svg'
import { clone } from '../../../utilities/geospatial'
import scss from './Group.scss'
import appScss from '../../App/App.scss'

const Group = React.memo(({ group, groupLayerIds, groupLayers }) => {
  const dispatch = useDispatch()
  const visibleLayers = useSelector(state => state.visibleLayers)
  const toolConfig = useSelector(state => state.toolConfig)
  const tocFilter = useSelector(state => state.tocFilter)
  const [showGroup, setShowGroup] = useState(true)
  const [open, setOpen] = useState(false)
  const [activeClass, setActiveClass] = useState('')
  const [activeIcon, setActiveIcon] = useState('eye-slash')
  const [layerShare, setLayerShare] = useState('')
  const [layerStyle, setLayerStyle] = useState({ width: '30px' })
  const [settingsActive, setSettingsActive] = useState('')
  const [groupVisibleLayers, setGroupVisibleLayers] = useState([])

  const handleToggleGroupSettings = e => {
    e.stopPropagation()
    toggleGroupSettings(group)
  }

  const toggleGroup = () => {
    if (!open) {
      let openGroups = JSON.parse(localStorage.getItem('openGroups')) ?? []
      if (!openGroups.includes(group.id)) {
        openGroups.push(group.id)
        localStorage.setItem('openGroups', JSON.stringify(openGroups))
      }
    } else {
      let openGroups = JSON.parse(localStorage.getItem('openGroups')) ?? []
      if (openGroups.includes(group.id)) {
        openGroups = openGroups.filter(groupId => groupId !== group.id)
        localStorage.setItem('openGroups', JSON.stringify(openGroups))
      }
    }
    setOpen(!open)
  }
  const toggleGroupLayers = e => {
    e.stopPropagation()
    if (groupVisibleLayers.length) {
      // toggle all off
      dispatch(toggleMapLayersOff(groupLayerIds))
      setGroupVisibleLayers([])
    } else {
      // toggle all on
      dispatch(toggleMapLayersOn(groupLayerIds))
      setGroupVisibleLayers(groupLayerIds)
    }
    dispatch(groupClicked())
  }

  const toggleGroupSettings = group => {
    let config = clone(toolConfig)
    config.forEach(tool => {
      if (tool.layer) tool.layer = null
      if (tool.name === 'GroupSettings') {
        if (!tool.group) {
          // turn groupSettings on
          tool.visible = true
          tool.group = group
          return
        }
        if (tool.group && tool.group.groupId === group.groupId) {
          // turn group settings off
          tool.visible = false
          tool.group = null
          return
        }
        if (tool.group && tool.group.groupId !== group.groupId) {
          // switch group settings target group
          tool.group = group
          return
        }
      }
    })
    dispatch(setToolConfig(config))
  }

  useEffect(() => {
    let sharedType = group.type

    if (sharedType === 'user') {
      setLayerShare(userLayerGroup)
      setLayerStyle({ width: '25px', marginLeft: '4px' })
    } else if (sharedType === 'shared') {
      setLayerShare(sharedLayerGroup)
      setLayerStyle({ width: '20px', marginLeft: '4px' })
    } else if (sharedType === 'org') {
      setLayerShare(organizationLayerGroup)
      setLayerStyle({ width: '23px', marginLeft: '4px' })
    } else if (sharedType === 'mam') {
      setLayerShare(logoSimplified)
      setLayerStyle({ width: '14px', marginLeft: '4px' })
    } else {
      setLayerShare(logoSimplified)
      setLayerStyle({ width: '14px', marginLeft: '4px' })
    }

    let openGroups = JSON.parse(localStorage.getItem('openGroups')) ?? []
    if (openGroups.includes(group.id)) {
      setOpen(true)
    }
  }, [])

  useEffect(() => {
    if (groupVisibleLayers.length) {
      setActiveClass('active')
      setActiveIcon('eye')
    } else {
      setActiveIcon('')
      setActiveIcon('eye-slash')
    }
  }, [groupVisibleLayers])

  useEffect(() => {
    if (toolConfig) {
      toolConfig.forEach(tool => {
        if (tool.name === 'GroupSettings') {
          if (tool.group && tool.group.groupId === group.groupId) {
            setSettingsActive('active')
          } else {
            setSettingsActive('')
          }
        }
      })
    }
  }, [toolConfig])

  const processGroup = group => {
    let childMatch = false
    group.children.items.forEach(item => {
      const re = new RegExp(tocFilter, 'gi')
      if (item.toc) {
        const res = item.toc.label.match(re)
        if (res) {
          // Layer Name does not match, do not render in TOC
          childMatch = true
        }
      }
      if (item.children) {
        childMatch = processGroup(item)
      }
    })
    return childMatch
  }

  useEffect(() => {
    if (tocFilter === '') {
      setShowGroup(true)
      return
    }
    const re = new RegExp(tocFilter, 'gi')
    if (group.label.match(re)) {
      setShowGroup(true)
      return
    }

    const childMatch = processGroup(group)

    if (!childMatch) {
      // no children in group match filter regex, dont render group
      setShowGroup(false)
    } else {
      setShowGroup(true)
    }
  }, [tocFilter])

  useEffect(() => {
    let isVisible = false

    visibleLayers.forEach(visibleLayerObj => {
      if (visibleLayerObj && visibleLayerObj.layersArray)
        visibleLayerObj.layersArray.forEach(visibleLayer => {
          if (groupLayerIds.includes(visibleLayer.layer.id)) {
            isVisible = true
          }
        })
    })
    setActiveClass(isVisible ? 'active' : '')
    setActiveIcon(isVisible ? 'eye' : 'eye-slash')
  }, [visibleLayers])

  const dataPanelIcon = open ? 'chevron-up' : 'chevron-down'

  return (
    <>
      {showGroup ? (
        <div className={scss['layer-group']} id={group.groupId}>
          <div
            id='layerGroup'
            onClick={toggleGroup}
            className={[
              scss['layer-group-row'],
              scss[activeClass == 'active' ? 'layer-group-active' : ''],
            ].join(' ')}
          >
            <button
              className={[
                scss['layer-group-button'],
                scss['layer-group-button-visible'],
                scss[activeClass],
              ].join(' ')}
              onClick={toggleGroupLayers}
            >
              <Icon
                className={scss.toggleIcon}
                icon={activeIcon}
                size='1x'
              />
            </button>

            <span className={[scss['layer-group-label']].join(' ')}>
              <img src={layerShare} style={layerStyle}></img>
              <span
                className={[
                  appScss.ellipsis,
                  scss['layer-group-label-text'],
                ].join(' ')}
              >
                {group.label}
              </span>
            </span>

            <button
              className={[
                scss['layer-group-button'],
                scss['layer-group-button-settings'],
                scss[settingsActive],
              ].join(' ')}
              onClick={handleToggleGroupSettings}
              style={{ margin: '0.2px 0px' }}
            >
              <Icon icon='cog' size='1x' />
            </button>

            <button
              className={[
                scss['layer-group-button'],
                scss['layer-group-button-collapse'],
                scss[settingsActive],
              ].join(' ')}
              onClick={toggleGroup}
            >
              <Icon icon={dataPanelIcon} size='1x' />
            </button>
          </div>
          <Collapse className={scss['group-collapse']} in={open}>
            <Paper
              className={[scss['group-collapse-paper']].join(' ')}
              elevation={4}
            >
              <Droppable droppableId={group.groupId} type={group.groupId}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    placeholder={provided.placeholder}
                    style={{
                      backgroundColor: snapshot.isDraggingOver
                        ? 'none'
                        : 'none',
                    }}
                    {...provided.droppableProps}
                  >
                    {groupLayers}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </Paper>
          </Collapse>
        </div>
      ) : null}
    </>
  )
})

export default Group
