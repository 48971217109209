/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'

import CurrencyInput from 'react-currency-input-field'
import isEqual from 'react-fast-compare'
import Icon from '../../../../../../components/Icon/Icon'
import { apiFetch } from '../../../../../../utilities/api'
import { apis } from '../../../../../../config/apiConfig'
// utilities
import { processDateFormat } from '../../../../../Profile/ProfileDate'

import { Alert } from '../../../../../../actions'
// scss files
import scss from '../AttributeRow.scss'
import appScss from '../../../../../App/App.scss'
import 'react-datepicker/dist/react-datepicker.css'

const fieldTypes = [
  { label: 'Text', value: 'text' },
  { label: 'Long Text', value: 'longtext' },
  { label: 'Number', value: 'number' },
  { label: 'Date', value: 'date' },
  { label: 'Dropdown', value: 'dropdown' },
  { label: 'Currency', value: 'currency' },
]

const EditAttribute = ({
  config,
  targetProperty,
  cancelEdit,
  getProperties,
  finishUpdate,
}) => {
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const [error, setError] = useState(null)

  const [propertyName, setPropertyName] = useState(targetProperty.name)
  const [propertyType, setPropertyType] = useState(targetProperty.type)
  const [propertyDefault, setPropertyDefault] = useState(targetProperty.default)

  const [originalPropertyName] = useState(targetProperty.name)
  const [originalPropertyType] = useState(targetProperty.type)
  const [originalPropertyDefault] = useState(targetProperty.default)
  const [originalDropdownValue, setOriginalDropdownValue] = useState(null)

  const [defaultTitleForEdit, setDefaultTitleForEdit] = useState('Default')
  const [dropdownValue, setDropdownValue] = useState(null)

  const [dateValue, setDateValue] = useState(propertyDefault || '')
  const [displaySaveButton, setDisplaySaveButton] = useState(false)

  useEffect(() => {
    if (propertyType === 'date' && propertyDefault) {
      try {
        let parsedDate = new Date(propertyDefault)
        if (parsedDate instanceof Date) {
          parsedDate = parsedDate.toISOString().substring(0, 10)
          setDateValue(parsedDate)
        }
      } catch (e) {
        setDateValue('')
        console.error(e)
      }
    }
  }, [])

  useEffect(() => {
    if (
      !isEqual(propertyType, originalPropertyType) ||
      !isEqual(propertyName, originalPropertyName) ||
      !isEqual(propertyDefault, originalPropertyDefault) ||
      !isEqual(dropdownValue, originalDropdownValue)
    ) {
      setDisplaySaveButton(true)
    }
  })

  useEffect(() => {
    if (targetProperty) {
      let textAreaVal = dropdownValue || ''
      const { value } = targetProperty

      if (Array.isArray(value)) {
        textAreaVal = targetProperty.value.join('\n')
      }

      // Setup Original Dropdown Value if needed
      setDropdownValue(textAreaVal)
      if (originalDropdownValue === null) setOriginalDropdownValue(textAreaVal)
    }
  }, [targetProperty])

  const getDropDownOptionList = () => {
    return (
      <div className={scss.propertyDropdownList}>
        <div>Option List (One per line)</div>
        <textarea
          name='value'
          className={scss.dropDownTextArea}
          value={dropdownValue}
          onChange={e => {
            setDropdownValue(e.target.value)
          }}
          placeholder='Enter Dropdown Items (one per line)...'
        ></textarea>
      </div>
    )
  }

  const updateProperty = () => {
    if (propertyName === '') {
      setError('name')
      return
    }
    const layerId = config.layer.layersArray[0].layer.id
    const method = 'POST'
    // let url = apis.apiDatabase.uri + 'layer/properties/add'
    // if (targetProperty && targetProperty.key)
    const url = apis.apiDatabase.uri + 'layer/properties/update'

    let value = ''

    if (targetProperty && targetProperty.value) {
      if (propertyType === 'dropdown') {
        if (!Array.isArray(targetProperty.value)) {
          if (value === '') {
            value = []
          } else {
            // Split text area into new line and remove invalid attributes
            value = targetProperty.value.split('\n').filter(val => val !== '')
          }
        }
      } else if (Array.isArray(targetProperty.value)) {
        value = [value]
      }
    }

    if (dropdownValue !== null) {
      value = dropdownValue.split('\n').filter(val => val !== '')
    }

    const bodyParams = {
      key: targetProperty.attrKey,
      layerID: layerId,
      name: propertyName,
      type: propertyType,
      default: propertyDefault,
      value: Array.isArray(value) ? value : [value],
    }

    apiFetch(url, method, bodyParams, result => {
      if (result && result.data && result.success === false) {
        result.data = JSON.parse(result.data)
        dispatch(
          Alert({
            error:
              'Reserved words cannot be used to create attribute: ' +
              result.data,
          })
        )
      }
      getProperties()
      finishUpdate()
    })
  }

  const getDefaultEditor = () => {
    if (propertyType === 'date') {
      const userDateFormat = processDateFormat(user.profile.dateFormat)
      let keyVal = ''
      if (targetProperty) {
        keyVal = targetProperty.key
      }

      return (
        <DatePicker
          name={keyVal}
          value={dateValue || ''}
          className={scss.CustomDatePicker}
          placeholderText='MM/DD/YYYY'
          selected=''
          onChange={date => {
            let isoDate = null
            if (date instanceof Date)
              isoDate = date.toISOString().substring(0, 10)
            if (isoDate) {
              setPropertyDefault(isoDate)
              setDateValue(isoDate)
            } else if (!date) {
              setPropertyDefault('')
              setDateValue('')
            } else {
              setPropertyDefault(date)
              setDateValue(date)
            }
          }}
          dateFormat={userDateFormat}
          isClearable
        />
      )
    }
    if (propertyType === 'currency') {
      // Let's pre-process and check for NaN
      let numVal = propertyDefault
      // $ has to be removed because it breaks the defaultValue property
      if (propertyDefault.includes('$')) numVal = numVal.replace('$', '')
      if (Number.isNaN(Number(numVal))) numVal = ''

      return (
        <CurrencyInput
          placeholder='$0.00'
          name='default'
          defaultValue={numVal}
          prefix='$'
          onValueChange={value => {
            setPropertyDefault(value ? '$' + value : '')
          }}
          style={{ width: '100%', textAlign: 'left' }}
          decimalsLimit={2}
          allowDecimals
        />
      )
    }
    if (propertyType === 'longtext') {
      return (
        <textarea
          id='property-default'
          name='property-default'
          value={propertyDefault || ''}
          onChange={e => setPropertyDefault(e.target.value)}
          autoComplete='off'
          rows={5}
          style={{ height: '55px', maxHeight: '300px' }}
        />
      )
    }
    return (
      <input
        id='property-default'
        name='property-default'
        value={propertyDefault || ''}
        onChange={e => setPropertyDefault(e.target.value)}
        autoComplete='off'
      />
    )
  }

  const nameError = error === 'name' ? 'error' : ''
  const dropDownOptions =
    propertyType === 'dropdown' ? getDropDownOptionList() : null
  const defaultEditor = getDefaultEditor()

  return (
    <div className={[scss.flexColumn, scss.layerPropertiesEdit].join(' ')}>
      <span className={scss.layerPropertiesEditText}>
        Edit Feature Attribute
      </span>
      <div
        className={[scss.flexColumn, scss.layerPropertiesEditInputs].join(' ')}
      >
        <label htmlFor='property-name' className={scss.globalLabel}>
          Name
          <input
            id='property-name'
            name='name'
            value={propertyName}
            onChange={e => {
              let propertyNameInput = e.target.value
              // The back-end creates scripts with this name so we restrict it to normal characters
              propertyNameInput = propertyNameInput.replace(/[^\w\s]/gi, '')
              setPropertyName(propertyNameInput)
            }}
            className={scss[nameError]}
            autoFocus
          />
        </label>
        <label
          htmlFor='property-type'
          className={scss.globalLabel}
          style={{ marginBottom: 0 }}
        >
          Type
          <div className={scss.globalCustomSelect}>
            <select
              id='property-type'
              value={propertyType}
              onChange={e => setPropertyType(e.target.value)}
            >
              {fieldTypes.map(property => (
                <option key={property.value} value={property.value}>
                  {property.label}
                </option>
              ))}
            </select>
            <Icon
              icon='chevron-down'
              size='1x'
              pull='right'
              className={scss.globalCustomSelectIcon}
            />
          </div>
        </label>
        {dropDownOptions}

        {/* Specific Inputs for Dates and Currency */}
        {
          <label htmlFor='property-default' className={scss.globalLabel}>
            {defaultTitleForEdit}
            {defaultEditor}
          </label>
        }

        {/* Cancel and Save Buttons */}
        <div
          className={[scss.flexRow, scss.flexCenter, scss.flexJustify].join(
            ' '
          )}
        >
          <button
            type='button'
            className={appScss.altBlueButton}
            onClick={cancelEdit}
          >
            Cancel
          </button>
          {displaySaveButton ? (
            <button
              type='button'
              className={appScss.blueButton}
              onClick={updateProperty}
            >
              Save
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  )
}

EditAttribute.propTypes = {
  config: PropTypes.any,
  targetProperty: PropTypes.any,
}

export default EditAttribute
