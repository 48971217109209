import React from 'react'
import { useDispatch } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { ThemeProvider } from '@material-ui/core/styles'
import Icon from '../../../../../components/Icon/Icon'
import {
  setDragPan,
  setDoubleClickZoom,
  setScrollZoom,
  disablePopup,
} from '../../../../../actions'
import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import Snapping from './Snapping/Snapping'
// import Measurements from './Measurements/Measurements'

const Settings = ({ setMode }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    setMode('PAUSE')
    dispatch(setDragPan(true))
    dispatch(setDoubleClickZoom(true))
    dispatch(setScrollZoom(true))
    dispatch(disablePopup(false))
  }

  return (
    <ThemeProvider theme={mainModalTheme}>
      <Dialog onClose={handleClose} aria-labelledby='new-layer-dialog' open>
        <DialogTitle id='draw-settings'>
          Draw Settings
          <div style={{ cursor: 'pointer' }} onClick={handleClose}>
            <Icon icon='times' size='sm' pull='right' />
          </div>
        </DialogTitle>

        <DialogContent id='draw-settings-content'>
          <Snapping />
          {/* <Measurements /> THIS IS WIP */}
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  )
}

export default Settings
