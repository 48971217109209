import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Icon from '../../../components/Icon/Icon'
import JSZip from 'jszip'

import DropZone from '../../../components/DropZone/DropZone'
import AsyncFetch from '../../../utilities/AsyncFetch'
import { apis } from '../../../config/apiConfig'
import MakeDataConfigComponent from '../../../utilities/dataConfig'
import ZoomToBounds from '../../../components/ZoomToBounds/ZoomToBounds'
import { getBBox } from '../../../utilities/geospatial'
import * as utils from '../../../utilities/util'

import appScss from '../../App/App.scss'

import mainModalTheme from '../../../utilities/componentConstants/mainModalTheme'

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiDialogContent-root': {
      minWidth: 350,
    },
  },
}))

function SimpleDialog({ onClose, uploadFinished, fileType }) {
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const viewport = useSelector(state => state.viewport)
  const [fetching, setFetching] = useState(false)
  const [open, setOpen] = useState(true)
  const [urlObjects, setUrlObjects] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [mountZoomToBounds, setMountZoomToBounds] = useState(null)
  const [zoomToBounds, setZoomToBounds] = useState(null)
  const activeAccount = user.accounts.filter(
    account => account.id === user.accountID
  )
  const client = activeAccount[0].id
  const uuid = utils.generateUUID()
  const dirName = `client_data/${client}/s32shp2pgsql/${uuid}`

  const handleUpload = async (data, filesArray) => {
    const method = 'POST'
    const url = apis.apiDatabase.uri + 'layer/import'
    const file = `public/${data.key}`
    const parts = file.split('/')
    const fileName = parts[parts.length - 1]
    const filePath = parts.slice(0, parts.length - 2).join('/')
    const numLayers = await getNumLayers(filesArray, fileName)
    const body = {
      filePath,
      taskID: uuid,
      fileName,
      append: false,
      numLayers,
    }
    setFetching(true)
    setUrlObjects([{ url, body, method }])
  }

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  const fetchFinished = result => {
    setMountMakeDataConfig(true)
    if (result.success) {
      const resultBounds = result[0].data.layersArray[0].layer.metadata.bounds
      const boundsPoly = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: resultBounds,
        },
      }
      doZoomToBounds(boundsPoly)
      //Click The Layer to Enable it
    }
  }

  const getNumLayers = async (filesArray, fileName) => {
    const fileExt = fileName.split('.').pop()
    const [fileObject] = filesArray
    const isShp = fileExt === 'zip'
    const isKmz = fileExt === 'kmz'

    if (!isShp && !isKmz) return 1

    const zip = await JSZip.loadAsync(fileObject)
    let { files } = zip
    files = Object.keys(files)
      .filter(file => !file.startsWith('__MACOSX'))
      .filter(file => file.endsWith(isShp ? '.shp' : '.kml'))
    return files.length
  }

  const doZoomToBounds = feature => {
    const bounds = getBBox(feature)
    const formattedBounds = [
      [bounds[0], bounds[1]],
      [bounds[2], bounds[3]],
    ]
    setZoomToBounds(formattedBounds)
    setMountZoomToBounds(true)
    uploadFinished()
  }

  const configFinished = () => {
    setOpen(false)
    onClose(false)
  }

  useEffect(() => {
    setUrlObjects(null)
  }, [urlObjects])

  useEffect(() => {
    setMountZoomToBounds(null)
  }, [setZoomToBounds])

  useEffect(() => {
    setMountMakeDataConfig(false)
  }, [mountMakeDataConfig])

  return (
    <>
      {urlObjects && (
        <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
      )}
      {mountMakeDataConfig && (
        <MakeDataConfigComponent onFinish={configFinished} />
      )}
      {mountZoomToBounds && (
        <ZoomToBounds viewport={viewport} bounds={zoomToBounds} />
      )}
      <ThemeProvider theme={mainModalTheme}>
        <Dialog
          className={classes.root}
          onClose={handleOnClose}
          aria-labelledby='data-upload-dialog'
          open={open}
        >
          <DialogTitle id='data-upload-title'>
            Upload file
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>
          <DialogContent>
            <div
              className={appScss.dropzoneBox}
              style={
                fetching
                  ? {
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '25%',
                    }
                  : null
              }
            >
              {fetching ? (
                <CircularProgress size={10} />
              ) : (
                <DropZone
                  uuid={false}
                  key='dropzone'
                  maxSize={1073741824}
                  dirName={dirName}
                  handleUploadResponse={handleUpload}
                  fileType={fileType}
                  successButtonLabel='Upload'
                />
              )}
            </div>
          </DialogContent>

          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function DataUpload(props) {
  return <SimpleDialog {...props} />
}
