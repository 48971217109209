/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react'
import scss from './InputStyle.scss'
import Icon from '../../../../../components/Icon/Icon'

const Input = ({
  key,
  inputType,
  featureProperties,
  setFeatureProperties,
  CustomInputValue,
  handleAllChanges,
  propertyKey,
  readOnly,
}) => {
  const [originalValue] = useState(CustomInputValue)
  const [value, setValue] = useState(CustomInputValue)
  const [link, setLink] = useState('')

  // Alternative syntax using RegExp constructor
  // const regex = new RegExp('(http|ftp|https):\\\/\\\/([\\w_-]+(?:(?:\\.[\\w_-]+)+))([\\w.,@?^=%&:\\\/~+#-]*[\\w@?^=%&\\\/~+#-])', 'gm')

  useEffect(() => {
    if (!value) return
    if (typeof value !== 'string') return

    const regex =
      /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])/gm

    const found = value.match(regex)

    if (found === null) return
    setLink(found[0])
  }, [value])

  const inputField = (
    <input
      readOnly={readOnly}
      className={readOnly ? scss.readOnly : scss.defaultInput}
      type={inputType}
      name={key}
      onChange={e => {
        e.preventDefault()
        setValue(e.target.value)
      }}
      placeholder={value || ''}
      value={value || ''}
      title={value || ''}
      onBlur={() => {
        if (originalValue !== value) {
          featureProperties[propertyKey] = value
          setFeatureProperties(featureProperties)
          handleAllChanges()
        }
      }}
    />
  )

  return (
    <div className={scss.flexRowInput}>
      {inputField}
      {link && (
        <a
          rel='noreferrer'
          target='_blank'
          href={link}
          style={{ color: 'whitesmoke' }}
        >
          <Icon icon='external-link' size='xs' fixedWidth />
        </a>
      )}
    </div>
  )
}

export default Input
