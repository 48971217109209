import React, { useState } from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import Icon from '../../../../components/Icon/Icon'
import scss from './PropertyTabV2.scss'
import modalScss from '../../../CSSModules/Modal.scss'
import { PropertyListV2 } from './TabItemV2/PropertyListV2/PropertyListV2'

// styld component TODO:  Not Sure if i want to keep this.

export const Handle = styled.div`
  text-align: right;
  width: auto;
  height: auto;
`

// Create and export the BoardColumn component
export const PropertyTabV2 = props => {
  const [titleValue, setTitleValue] = useState(props.column.title || 'N/A')
  const [originalTitleValue] = useState(props.column.title || 'N/A')

  let propertyHolderTitle = 'Unassigned'

  if (props.isDocumentWindow) propertyHolderTitle = 'Documents (Unassigned)'
  if (props.isAttributeWindow) propertyHolderTitle = 'Attributes (Unassigned)'

  const title =
    props.column.id === 'propertiesHolder' ? (
      <div className={scss.inputContainer}>
        <div className={scss.addOnGroup}>
          <div className={modalScss.modalInput} style={{ textAlign: 'center' }}>
            {propertyHolderTitle}
          </div>
        </div>
      </div>
    ) : (
      <div className={scss.inputContainer}>
        <div className={scss.addOnGroup}>
          <input
            className={[modalScss.modalInput, scss.tabTitle].join(' ')}
            type='text'
            name={props.column.id}
            value={titleValue}
            onChange={e => {
              setTitleValue(e.target.value)
            }}
            onBlur={() => {
              if (originalTitleValue !== titleValue) {
                props.handleTitleChange(props.column.id, titleValue)
                props.setDisplayApplyChanges(true)
              }
            }}
          ></input>
          <Icon
            onClick={() => {
              props.setCurrentTabIdToDelete(props.column.id)
              props.setDisplayDeleteTabPopup(true)
            }}
            icon={['fal', 'trash-alt']}
            size='1x'
            className={scss.removeTab}
            title='Remove tab'
          />
        </div>
      </div>
    )

  return (
    <>
      {props.column.id === 'propertiesHolder' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '2%',
          }}
        >
          {title}
          <PropertyListV2
            className={scss.propertyTabText}
            index={props.index}
            listId={props.column.id}
            listType={'PROPERTY'}
            useClone={props.useClone}
            items={props.items}
            style={{
              height: '135px !important',
            }}
          />
        </div>
      ) : (
        <Draggable
          draggableId={props.column.title}
          index={props.title === 'propertiesHolder' ? 0 : props.index}
          isDragDisabled={props.title === 'propertiesHolder'}
        >
          {(provided, snapshot) => (
            <div
              className={[
                scss.PropertyTabWrapper,
                snapshot.isDragging ? scss.beingHeld : '',
              ].join(' ')}
              ref={provided.innerRef}
              {...provided.draggableProps}
            >
              {/* Title of the column */}
              {title}
              <PropertyListV2
                className={scss.propertyTabText}
                index={props.index}
                listId={props.column.id}
                listType='PROPERTY'
                useClone={props.useClone}
                items={props.items}
              />

              <div
                style={
                  props.title !== 'propertiesHolder'
                    ? {}
                    : { display: 'inline' }
                }
              >
                <Handle {...provided.dragHandleProps}>
                  <Icon
                    icon={['fal', 'arrows-h']}
                    size='1x'
                    className={scss.dragTabButton}
                  />
                </Handle>
              </div>
            </div>
          )}
        </Draggable>
      )}
    </>
  )
}
