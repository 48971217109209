import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import LineSwatch from '../../../Legend/LegendSwatch/LineSwatch'
import FillSwatch from '../../../Legend/LegendSwatch/FillSwatch'
import CircleSwatch from '../../../Legend/LegendSwatch/CircleSwatch'
import Icon from '../../../../../components/Icon/Icon'

const LegendElement = ({
  object,
  handleElementClick,
  excludedLayers,
  legendElement,
  onDeleteLayer,
}) => {
  const mapStyle = useSelector(state => state.mapStyle)
  const dataConfig = useSelector(state => state.updateDataConfig)
  const [hoverItem, setHoverItem] = useState('')

  const getLegendLayers = () => {
    const style = mapStyle.toJS()
    const { tocLayers } = dataConfig
    const legendLayers = getVisibleLayersFromDataConfig(tocLayers, style)
    const uniqueLegendLayers = Array.from(
      new Set(legendLayers.map(JSON.stringify)),
      JSON.parse
    )

    const legendUi = []
    uniqueLegendLayers.reverse().forEach(legendLayer => {
      if (legendLayer.layersArray[0].layer.type === 'raster') return
      if (excludedLayers.includes(legendLayer.layersArray[0].layer.id)) return
      const key = legendLayer.toc.id
      let title = null
      let zoomLabel = legendLayer.toc.label
      const paintProps = legendLayer.layersArray[0].layer.paint
      let outlinePaintProps
      let flexAlignTitle = true

      for (const [propKey, propValue] of Object.entries(paintProps)) {
        if (Array.isArray(propValue)) {
          const flat = propValue.flat().join(',')
          if (flat.indexOf('case,==,get') !== -1) {
            flexAlignTitle = false
            title = <div className={scss.title}>{legendLayer.toc.label}</div>
          } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
            flexAlignTitle = true
            title = null
            zoomLabel = legendLayer.toc.label
          }
        }
      }

      if (legendLayer.layersArray[0].outline) {
        outlinePaintProps = legendLayer.layersArray[0].outline.paint
      }

      if (outlinePaintProps) {
        for (const [propKey, propValue] of Object.entries(outlinePaintProps)) {
          if (Array.isArray(propValue)) {
            const flat = propValue.flat().join(',')
            if (flat.indexOf('case,==,get') !== -1) {
              flexAlignTitle = false
              title = <div className={scss.title}>{legendLayer.toc.label}</div>
            } else if (flat.indexOf('interpolate,linear,zoom') !== -1) {
              flexAlignTitle = true
              title = null
              zoomLabel = legendLayer.toc.label
            }
          }
        }
      }

      if (legendLayer.toc.layerPackage) flexAlignTitle = false

      let hideLayerHeader = null
      if (legendLayer.layersArray.length > 1) {
        hideLayerHeader = true
        title = <div className={scss.title}>{legendLayer.toc.label}</div>
      }
      if (legendLayer.toc.hideLegnedHeader) {
        hideLayerHeader = true
      }

      const items = legendLayer.layersArray
        .filter(layerObj => !excludedLayers.includes(layerObj.layer.id))
        .filter(layerObj => !layerObj.layer.id.includes('_outline'))
        .filter(layerObj => !layerObj.layer.metadata.excludeFromLegend)
        .map(layerObj => {
          const [styleLayer] = style.layers.filter(
            l => l.id === layerObj.layer.id
          )
          const { type } = styleLayer
          let outlineLayer = []
          if (type === 'fill') {
            outlineLayer = style.layers.filter(
              l => l.id === `${layerObj.layer.id}_outline`
            )
          }

          const swatch = (
            <div key={styleLayer.id} className={scss.legendItemWrapper}>
              {type === 'line' && (
                <LineSwatch
                  layer={styleLayer}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader={hideLayerHeader}
                />
              )}
              {type === 'fill' && (
                <FillSwatch
                  layer={styleLayer}
                  outline={outlineLayer[0]}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader={hideLayerHeader}
                />
              )}
              {type === 'circle' && (
                <CircleSwatch
                  layer={styleLayer}
                  singleItemTitles={legendLayer.toc.layerPackage}
                  hideLayerHeader={hideLayerHeader}
                  zoomLabel={zoomLabel}
                />
              )}
            </div>
          )

          return swatch
        })

      if (items.length) {
        const btnStyle = {
          display:
            hoverItem === legendLayer.layersArray[0].layer.id ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'center',
          width: '24px',
          height: '24px',
          margin: '0 4px',
        }

        const deleteButton = (
          <div
            style={btnStyle}
            className={scss.collapseBtn}
            onClick={() => onDeleteLayer(legendLayer.layersArray[0].layer.id)}
          >
            <Icon icon='times' size='1x' />
          </div>
        )

        legendUi.push(
          <div
            key={key}
            className={scss.legendItem}
            onMouseEnter={() =>
              setHoverItem(legendLayer.layersArray[0].layer.id)
            }
            onMouseLeave={() => setHoverItem('')}
          >
            {title && (
              <div className={scss.titleWrapper}>
                {deleteButton}
                {title}
              </div>
            )}
            {!title && deleteButton}
            <div className={scss.itemsWrapper}>{items}</div>
          </div>
        )
      }
    })
    return <div className={scss.legendPanel}>{legendUi}</div>
  }

  const legendStyle = {
    borderColor: !object.borderTransparent ? object.borderColor : 'transparent',
    borderWidth: `${object.borderWidth}px`,
    borderStyle: 'solid',
    backgroundColor: !object.bgTransparent ? object.bgColor : 'transparent',
    opacity: object.opacity ? object.opacity / 100 : 1,
    color: object.textColor,
    height: '100%',
    padding: `${object.padding}px`,
    overflow: 'hidden',
  }

  return (
    <div
      style={legendStyle}
      className={
        printScss[legendElement === object.id ? 'activeEditWindow' : '']
      }
      onClick={handleElementClick}
    >
      <div className={scss.toolBody}>
        <div className={scss.targetLayerPanel}>
          <div className={scss.toolGroup}>{getLegendLayers()}</div>
        </div>
      </div>
    </div>
  )
}

export default LegendElement
