import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import scss from './ImageLayer.scss'
import DropZone from '../../../../../components/DropZone/DropZone'
import { clone } from '../../../../../utilities/geospatial'
import {
  validLatitude,
  validLongitude,
} from '../../../../../utilities/validation'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MakeDataConfigComponent from '../../../../../utilities/dataConfig'
import AsyncFetch from '../../../../../utilities/AsyncFetch'
import { apis } from '../../../../../config/apiConfig'
import Icon from '../../../../../components/Icon/Icon'

import appScss from '../../../../App/App.scss'

import mainModalTheme from '../../../../../utilities/componentConstants/mainModalTheme'
import { ThemeProvider } from '@material-ui/core/styles'

function ImageLayer({ layerName, layerType, closeTool }) {
  const user = useSelector(state => state.user)
  const [fetching, setFetching] = useState(false)
  const [urlObject, setUrlObject] = useState(null)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [showNameWarningDialog, setShowNameWarningDialog] = useState(false)
  const closeNameWarningDialog = () => {
    setShowNameWarningDialog(false)
  }
  const [coordinates, setCoordinates] = useState({
    topLeftLat: '',
    topLeftLng: '',
    topRightLat: '',
    topRightLng: '',
    bottomRightLat: '',
    bottomRightLng: '',
    bottomLeftLat: '',
    bottomLeftLng: '',
  })
  const [enableSave, setEnableSave] = useState(null)
  const [path, setPath] = useState(null)
  const activeAccount = user.accounts.filter(acc => acc.id === user.accountID)
  const client = activeAccount[0].id
  const dirName = `client_data/${client}/image_layers`

  const getPaintProps = () => {
    const props = {}
    props['raster-opacity'] = 0.85
    return props
  }

  const handleUpload = res => {
    setPath(res.key)
  }

  const handleOnChange = e => {
    let valid = false
    if (e.target.name.includes('Lat')) {
      valid = validLatitude(e.target.value)
    }
    if (e.target.name.includes('Lng')) {
      if (e.target.value === '-') {
        valid = true
      } else {
        valid = validLongitude(e.target.value)
      }
    }
    if (valid) {
      let coords = clone(coordinates)
      coords[e.target.name] = e.target.value
      setCoordinates(coords)
    }
  }

  const validate = () => {
    if (
      coordinates['topLeftLat'] &&
      coordinates['topLeftLng'] &&
      coordinates['topRightLat'] &&
      coordinates['topRightLng'] &&
      coordinates['bottomRightLat'] &&
      coordinates['bottomRightLng'] &&
      coordinates['bottomLeftLat'] &&
      coordinates['bottomLeftLng'] &&
      path
    ) {
      setEnableSave(true)
      return true
    } else return false
  }

  const saveLayer = () => {
    if (layerName.trim() === '') {
      setShowNameWarningDialog(true)
      return
    }
    let sourceType = 'user'
    let geom = null
    if (layerType.value === 'image') {
      geom = 'raster'
      sourceType = 'image'
    }
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/add'
    const paintProps = getPaintProps()
    const body = {
      mapID: user.mapID,
      label: layerName,
      sourceType: sourceType,
      type: geom,
      paint: paintProps,
      minzoom: 1,
      sourceURL: `https://my-asset-map-data.s3.amazonaws.com/public/${path}`,
      sourceCoordinates: [
        [
          parseFloat(coordinates['topLeftLng']),
          parseFloat(coordinates['topLeftLat']),
        ],
        [
          parseFloat(coordinates['topRightLng']),
          parseFloat(coordinates['topRightLat']),
        ],
        [
          parseFloat(coordinates['bottomRightLng']),
          parseFloat(coordinates['bottomRightLat']),
        ],
        [
          parseFloat(coordinates['bottomLeftLng']),
          parseFloat(coordinates['bottomLeftLat']),
        ],
      ],
    }
    const urlObject = [{ url, body, method }]
    setUrlObject(urlObject)
    setFetching(true)
  }

  const handleCancel = () => {
    closeTool()
  }

  const fetchFinished = () => {
    setMountMakeDataConfig(true)
  }

  const dataConfigFinished = () => {
    closeTool()
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      validate()
    },
    [coordinates]
  )

  useEffect(
    function () {
      if (validate() || ['tiff', 'tif'].includes(path?.split('.').pop())) saveLayer()
    },
    [path]
  )

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      setUrlObject(null)
    },
    [urlObject]
  )

  useEffect(() => {
    setEnableSave(layerName.trim() !== '')
  }, [layerName])


  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {urlObject && (
          <AsyncFetch fetchObjects={urlObject} fetchFinished={fetchFinished} />
        )}
        {mountMakeDataConfig && (
          <MakeDataConfigComponent
            onFinish={dataConfigFinished}
            resetStyle={true}
          />
        )}
        <DialogContent>
          <span className={scss['coordinate-label']}>
            Enter Image Corner Coordinates
          </span>
          <div className={scss['coordinate-grid']}>
            <label
              className={[
                scss['coordinate-grid-item'],
                scss['coordinate-input-label'],
              ].join(' ')}
              htmlFor='top-left-coordinate'
            >
              Top Left
              <div>
                <input
                  type='text'
                  placeholder='Latitude'
                  name='topLeftLat'
                  className={scss.coordinateInput}
                  id='top-left-coordinate'
                  value={coordinates.topLeftLat}
                  onChange={handleOnChange}                 style={{ textAlign: 'left' }}
                />
                <input
                  type='text'
                  placeholder='Longitude'
                  name='topLeftLng'
                  className={scss.coordinateInput}
                  value={coordinates.topLeftLng}
                  onChange={handleOnChange}                style={{ textAlign: 'left' }}
                />
              </div>
            </label>

            <label
              className={[
                scss['coordinate-grid-item'],
                scss['coordinate-input-label'],
              ].join(' ')}
              htmlFor='top-right-coordinate'
            >
              Top Right
              <div>
                <input
                  type='text'
                  placeholder='Latitude'
                  name='topRightLat'
                  className={scss.coordinateInput}
                  id='top-right-coordinate'
                  value={coordinates.topRightLat}
                  onChange={handleOnChange}                 style={{ textAlign: 'left' }}
                />
                <input
                  type='text'
                  placeholder='Longitude'
                  name='topRightLng'
                  className={scss.coordinateInput}
                  value={coordinates.topRightLng}
                  onChange={handleOnChange}                 style={{ textAlign: 'left' }}
                />
              </div>
            </label>

            <label
              className={[
                scss['coordinate-grid-item'],
                scss['coordinate-input-label'],
              ].join(' ')}
              htmlFor='bottom-left-coordinate'
            >
              Bottom Left
              <div>
                <input
                  type='text'
                  placeholder='Latitude'
                  name='bottomLeftLat'
                  className={scss.coordinateInput}
                  id='bottom-left-coordinate'
                  value={coordinates.bottomLeftLat}
                  onChange={handleOnChange}
                  style={{ textAlign: 'left' }}
                />
                <input
                  type='text'
                  placeholder='Longitude'
                  name='bottomLeftLng'
                  className={scss.coordinateInput}
                  value={coordinates.bottomLeftLng}
                  onChange={handleOnChange}
                  style={{ textAlign: 'left' }}
                />
              </div>
            </label>

            <label
              className={[
                scss['coordinate-grid-item'],
                scss['coordinate-input-label'],
              ].join(' ')}
              htmlFor='bottom-right-coordinate'
            >
              Bottom Right
              <div>
                <input
                  type='text'
                  placeholder='Latitude'
                  name='bottomRightLat'
                  className={scss.coordinateInput}
                  id='bottom-right-coordinate'
                  value={coordinates.bottomRightLat}
                  onChange={handleOnChange}
                  style={{ textAlign: 'left' }}
                />
                <input
                  type='text'
                  placeholder='Longitude'
                  name='bottomRightLng'
                  className={scss.coordinateInput}
                  value={coordinates.bottomRightLng}
                  onChange={handleOnChange}
                  style={{ textAlign: 'left' }}
                />
              </div>
            </label>

          </div>
          <div style = {{marginTop: '10px'}}>
            <DropZone
              dirName={dirName}
              maxSize={104857600}
              maxFiles={1}
              handleUploadResponse={handleUpload}
              fileType='.png,.tif,.tiff'
              successButtonLabel={path ? '' : 'Upload'}
              height='100px'
            />
          </div>
        </DialogContent>

        <DialogActions>
          <button className={appScss.altBlueButton} onClick={handleCancel}>
            Cancel
          </button>
          {path ? (
            <button
              className={[
                appScss[!enableSave ? 'disabled-button' : ''],
                appScss.blueButton,
              ].join(' ')}
              onClick={fetching || !enableSave ? null : saveLayer}
            >
              {fetching ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Save'
              )}
            </button>
          ) : null}
        </DialogActions>

        <Dialog open={showNameWarningDialog} onClose={closeNameWarningDialog}>
          <DialogContent>
            Please enter a layer name.
          </DialogContent>
          <DialogActions>
            <button 
            className={appScss.blueButton}
            onClick={closeNameWarningDialog}>OK</button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default ImageLayer
