import React, { useState, useEffect } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AsyncFetch from '../../../../utilities/AsyncFetch'
import { apis } from '../../../../config/apiConfig'
import MakeDataConfigComponent from '../../../../utilities/dataConfig'
import CloseTool from '../../CloseTool'

import appScss from '../../../App/App.scss'
import Icon from '../../../../components/Icon/Icon'
import mainModalTheme from '../../../../utilities/componentConstants/mainModalTheme'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'

function SimpleDialog({ onClose, layerLabel, tocId, layerId }) {
  const [fetching, setFetching] = useState(false)
  const [open, setOpen] = useState(true)
  const [closeTool, setCloseTool] = useState(false)
  const [mountMakeDataConfig, setMountMakeDataConfig] = useState(false)
  const [urlObjects, setUrlObjects] = useState(null)
  const handleDelete = () => {
    const method = 'POST'
    const url = apis['apiDatabase'].uri + 'layer/delete'
    const body = {
      layerID: tocId,
    }
    setFetching(true)
    setUrlObjects([{ url, body, method }])
  }
  const fetchFinished = () => {
    setMountMakeDataConfig(true)
  }

  const handleOnFinish = () => {
    setCloseTool(true)
  }

  const handleCancel = () => {
    setOpen(false)
    onClose(false)
  }

  const handleOnClose = () => {
    setOpen(false)
    onClose(false)
  }

  useEffect(
    function () {
      setMountMakeDataConfig(false)
    },
    [mountMakeDataConfig]
  )

  useEffect(
    function () {
      setUrlObjects(null)
    },
    [urlObjects]
  )

  return (
    <>
      <ThemeProvider theme={mainModalTheme}>
        {mountMakeDataConfig && (
          <MakeDataConfigComponent
            onFinish={handleOnFinish}
            layerDeletedId={layerId}
          />
        )}
        {closeTool && <CloseTool toolName='LayerSettings' />}
        {urlObjects && (
          <AsyncFetch fetchObjects={urlObjects} fetchFinished={fetchFinished} />
        )}
        <Dialog
          onClose={handleOnClose}
          open={open}
          aria-labelledby='delete-layer-dialog'
          maxWidth='sm'
        >
          <DialogTitle id='delete-layer-title'>
            Delete Layer
            <Icon
              onClick={handleOnClose}
              icon='times'
              size='sm'
              pull='right'
            />
          </DialogTitle>

          <DialogContent>
            <p>
              You are deleting{' '}
              <i>
                <b>{layerLabel}</b>
              </i>{' '}
              layer. This action cannot be undone. Proceed?
            </p>
          </DialogContent>
          <DialogActions>
            <button className={appScss.altBlueButton} onClick={handleCancel}>
              Cancel
            </button>
            <button
              className={appScss.blueButton}
              onClick={fetching ? null : handleDelete}
            >
              {fetching ? (
                <Icon icon='spinner' size='1x' fixedWidth spin />
              ) : (
                'Delete'
              )}
            </button>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  )
}

export default function DeleteLayer(props) {
  return <SimpleDialog {...props} />
}
