/* eslint-disable react/jsx-filename-extension */
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Storage from '@aws-amplify/storage'
import Icon from '../../../components/Icon/Icon'
import scss from './NewCreateMenu.scss'
import * as NewCreateMenuUtil from './NewCreateMenuUtil.js'
import NewFolderPopup from './NewFolderPopup/NewFolderPopup'
import { SetS3Config } from '../../../services'
import { Alert } from '../../../actions'
import * as utils from '../../../utilities/util'

const NewCreateMenu = ({
  cwd,
  setIsNewCreateMenuVisible,
  isNewCreateMenuVisible,
  setTriggerRefresh,
  maxFiles,
  setGenerateNewFileTree,
}) => {
  const wrapperRef = useRef(null)

  const [isNewFolderPopupVisible, setIsNewFolderPopupVisible] = useState(false)
  const dispatch = useDispatch()
  const handleSuccess = msg => dispatch(Alert({ success: msg }))
  const handleError = error => dispatch(Alert({ error }))
  const [droppedFiles, setDroppedFiles] = useState([])

  NewCreateMenuUtil.useOutsideAlerter(
    wrapperRef,
    setIsNewCreateMenuVisible,
    setIsNewFolderPopupVisible
  )

  const handleCreateFolder = () => {
    setIsNewFolderPopupVisible(true)
  }

  const handleFileUpload = () => {
    SetS3Config('my-asset-map-data', 'public')

    if (droppedFiles) {
      droppedFiles.forEach(uploadedFile => {
        console.log(uploadedFile)
        Storage.put(`${cwd}/${uploadedFile.name}`, uploadedFile, {
          contentType: uploadedFile.type,
        })
          .then(result => {
            setTriggerRefresh(true)
            setGenerateNewFileTree(true)
          })
          .catch(err => {
            handleError(
              'There was an issue uploading your file: ' + err.message
            )
            console.error('There was an issue uploading your file: ', err)
          })
      })
    }
  }
  useEffect(() => {
    handleFileUpload()
  }, [droppedFiles])

  const onDrop = acceptedFiles => {
    acceptedFiles = Array.from(acceptedFiles)
    if (acceptedFiles && acceptedFiles.length) {
      let newFiles
      if (maxFiles && maxFiles === 1) {
        newFiles = [...acceptedFiles]
      } else {
        if (maxFiles && maxFiles < acceptedFiles.length + droppedFiles.length)
          return handleError('Error: Too many files')
        newFiles = [...droppedFiles]

        const currentFileKeys = droppedFiles.map(file => file.name)

        console.log(acceptedFiles)
        acceptedFiles.forEach(file => {
          if (!currentFileKeys.includes(file.name)) {
            newFiles = utils.addVal(newFiles, file)
          }
        })
      }

      // Save Files to State
      setDroppedFiles(newFiles)
    }
  }
  const fileUploadRef = useRef(null)
  const folderUploadRef = useRef(null)

  const onFileUploadClick = () => {
    fileUploadRef.current.click()
  }

  const onFolderUploadClick = () => {
    folderUploadRef.current.click()
  }

  return isNewCreateMenuVisible ? (
    <>
      {/* Inputs are trigerred by the buttons below */}
      <input
        type='file'
        onChange={e => onDrop(e.target.files)}
        multiple
        ref={fileUploadRef}
        style={{ display: 'none' }}
        hidden
      />
      <input
        type='file'
        onChange={e => onDrop(e.target.files)}
        multiple
        ref={folderUploadRef}
        style={{ display: 'none' }}
        hidden
        directory=''
        webkitdirectory=''
      />
      {/* ------------------------------*/}
      <div className={scss.NewCreateMenu} ref={wrapperRef}>
        {isNewFolderPopupVisible ? (
          <NewFolderPopup
            cwd={cwd}
            setIsNewFolderPopupVisible={setIsNewFolderPopupVisible}
            setIsNewCreateMenuVisible={setIsNewCreateMenuVisible}
            setTriggerRefresh={setTriggerRefresh}
            setGenerateNewFileTree={setGenerateNewFileTree}
          />
        ) : null}
        <button
          type='button'
          className={scss.ButtonStyle}
          style={{ borderBottom: '0.5px solid #858f98' }}
          onClick={handleCreateFolder}
        >
          <Icon
            icon='folder'
            style={{ color: 'white', marginRight: '10px' }}
          />
          Folder
        </button>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            type='button'
            onClick={onFileUploadClick}
            className={scss.ButtonStyle}
          >
            <Icon
              icon='file-upload'
              style={{ color: 'white', marginRight: '10px' }}
            />
            File upload
          </button>
        </div>
        <button
          type='button'
          onClick={onFolderUploadClick}
          className={scss.ButtonStyle}
        >
          <Icon
            icon={['fa', 'folder-upload']}
            style={{ color: 'white', marginRight: '10px' }}
          />
          Folder Upload
        </button>
      </div>
    </>
  ) : null
}

export default NewCreateMenu
